/* eslint-disable prefer-const */
import React, { FC } from 'react';
import ReactGA from 'react-ga4';
import { Container, Divisor, DivisorMobile } from './styles';
import BoxDetail from './BoxDetail';
import { InvoicesIcon, OtherServicesIcon } from './BoxDetail/Icons';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import { IDados, IDadosDebts } from '~/store/modules/outsides/types';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import { history } from '~/store';

const PendingDetails: FC = () => {
  const dataHistory = history.location.state as any;

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const isLaptop = useMediaQuery({ minWidth: Breakpoints.laptop.min });

  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );

  const chargesQuantity = dataDischargeDebts?.info?.charges?.totalItems || 0;
  const invoicesQuantity = dataDischargeDebts?.info?.invoices?.totalItems || 0;
  const hasOpenDebts = chargesQuantity > 0 || invoicesQuantity > 0;

  const handleNavigation = () => {
    if (!hasOpenDebts) return;

    ReactGA.event({
      category: 'Negociação de dívidas',
      action: '[Negociação de dívidas][Tela de dívidas] Pagar',
    });

    let data: Array<any> = [];

    dataDischargeDebts?.dados?.forEach((item: IDados) => {
      const updatedDebts = item?.debts?.map((debt: IDadosDebts) => ({
        ...debt,
        active: true,
      }));

      data?.push({
        ...item,
        debts: updatedDebts,
        activeAll: true,
      });
    });

    Navigation.navigate(RouteName.DEBTNEGOTIATIONSINGLEPAYMENT, {
      cnp: dataHistory?.cnp || '',
      dados: data,
    });
  };

  return (
    <Container type="button" onClick={handleNavigation}>
      {isMobile && <DivisorMobile />}
      <BoxDetail
        icon={<InvoicesIcon />}
        label={`${dataDischargeDebts?.info?.invoices?.totalItems || 0} Faturas`}
        amount={dataDischargeDebts?.info?.invoices?.amount || 0}
      />
      {isLaptop && <Divisor />}
      {isMobile && <DivisorMobile />}
      <BoxDetail
        icon={<OtherServicesIcon />}
        label={`${
          dataDischargeDebts?.info?.charges?.totalItems || 0
        } Outros serviços`}
        amount={dataDischargeDebts?.info?.charges?.amount || 0}
      />
    </Container>
  );
};

export default PendingDetails;
