import styled from 'styled-components';
import SelectImage from '~/assets/Images/Select.png';

export const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #004b55;
`;

export const ButtonBalloon = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

export const Divisor = styled.div`
  height: 1px;
  background: #c7c9c7;
  margin: 24px 0;
`;

export const SelectStyled = styled.select`
  width: 200px;
  height: 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 22px 4px 12px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #c7c9c7;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #999999;
  text-transform: capitalize;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(${SelectImage});
  background-repeat: no-repeat;
  background-position: 95% 50%;
  margin: 16px 0;
`;

export const ContainerAutocomplete = styled.div`
  max-width: 200px;
  margin: 16px 0;
`;

export const DescriptionText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #6a7279;
  margin-top: 8px;
`;
