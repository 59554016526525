import jwtDecode from 'jwt-decode';
import { Dispatch } from 'redux';
import { RouteName } from '~/routes/Routes.name';
import { getEnrollmentsMeService } from '~/services/enrollmentsAuth';
import {
  getEnrollmentsUsersInfoService,
  getEnrollmentsUsersObfuscatedService,
  getEnrollmentsUsersValidateService,
  postEnrollmentsManagerUsersCreateRegisterService,
  postEnrollmentsUsersCreateRegisterService,
  postEnrollmentsUsersNotClientCreateRegisterService,
  postEnrollmentsUsersSendRecoveryService,
  postEnrollmentsUsersSendSmsRecoveryService,
  postEnrollmentsUsersSendSmsRegisterService,
  postEnrollmentsUsersValidateSmsService,
  putEnrollmentsUsersPasswordRecoveryService,
  putEnrollmentsUsersPasswordService,
  putEnrollmentsUsersService,
} from '~/services/enrollmentsUsers';
import { togglesMeServices } from '~/services/toggles';
import { IResponse } from '../togglesEnrollment/types';
import { RepositoriesTypesEnrollmentsTogglesMe } from '../togglesMe/types';

import Navigation from '~/utils/Navigation';
import {
  IResponseEnrollmentsMe,
  RepositoriesTypesAuth,
} from '../enrollmentsAuth/types';
import { IState } from '../types';
import {
  RepositoriesTypesUsers,
  IResponseUsersInfo,
  IRequestSendSms,
  IRequestValidateSms,
  IRequestCreateUserClient,
  IRequestValidateRegister,
  IRequestUpdateUser,
  IRequestUpdatePassword,
  IRequestRecoveryPassword,
  IRequestCreateUserNotClient,
  IResponseObfuscated,
  IRequestSendRecovery,
  IResponseSendCodeRecovery,
  IResponseValidateRegister,
} from './types';
import {
  apiHeaderResetRecaptchaToken,
  apiHeaderSetRecaptchaToken,
} from '~/utils/apiRecaptchaToken';
import { apiHeaderSetOutsideToken } from '~/utils/apiOutsideToken';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';
import { BusinessException } from '~/error/';
import { postUnloggedSession } from '~/services/outsides';

const getEnrollmentsUsersRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_METHOD_REQUEST,
    });

    const response = (await getEnrollmentsUsersInfoService()) as IResponseUsersInfo;

    return dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          state: true,
        },
      },
    });
  }
};

const getEnrollmentsUsersRequestClear = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_METHOD_CLEAR,
  });
};

const updateEnrollmentsUsersPasswordRequest =
  (data: IRequestUpdatePassword) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_REQUEST,
      });

      await putEnrollmentsUsersPasswordService(data);

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_FAILURE,
        payload: {
          status: error?.response?.data?.status,
          state: true,
          message: error?.response?.data?.type,
        },
      });
    }
  };

const updateSuccessCloseEnrollmentsPasswordUsers =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_SUCCESS_CLOSE,
    });
  };

const updateErrorCloseEnrollmentsPasswordUsers =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_FAILURE_CLOSE,
    });
  };

const updateEnrollmentsUsersRequest =
  (data: IRequestUpdateUser) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const { firstAccess } = enrollmentsAuth;

      await putEnrollmentsUsersService(data);

      if (firstAccess) {
        Navigation.navigate(RouteName.REGISTRATIONLIST);
        dispatch({
          type: RepositoriesTypesAuth.ENROLLMENTS_SESSIONS_FIRST_ACCESS_CLEAR,
        });
      }

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const updateSuccessCloseEnrollmentsUsers = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_METHOD_SUCCESS_CLOSE,
  });
};

const updateErrorCloseEnrollmentsUsers = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_METHOD_FAILURE_CLOSE,
  });
};

const getEnrollmentsUsersRecoveryValidateRequest =
  ({ cnp, recaptchaTokenV2, recaptchaTokenV3 }: IRequestValidateRegister) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_UPDATE_RECOVERY_PASSWORD_METHOD_REQUEST,
      payload: {
        data: {
          cnp,
        },
      },
    });

    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_REQUEST,
      });

      apiHeaderSetRecaptchaToken({
        recaptchaTokenV2: `${recaptchaTokenV2}`,
        recaptchaTokenV3: `${recaptchaTokenV3}`,
      });
      const responseAuth = await postUnloggedSession(
        cnp,
        'forgotPasswordAndRegistration',
      );
      apiHeaderSetOutsideToken({ token: responseAuth?.token || '' });
      apiHeaderResetRecaptchaToken();

      const response = (await getEnrollmentsUsersValidateService({
        cnp,
      })) as IResponseValidateRegister;

      validateBusinessException(response);

      if (response?.message === EnumResponseCode.USER_ALREADY_EXISTS) {
        dispatch({
          type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_REQUEST,
        });

        const response = (await getEnrollmentsUsersObfuscatedService(
          cnp,
          true,
        )) as IResponseObfuscated;

        dispatch({
          type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_SUCCESS,
          payload: {
            data: {
              ...response,
            },
          },
        });

        Navigation.navigate(RouteName.FORGOTPASSWORDPHONE, { cnp });

        apiHeaderResetRecaptchaToken();

        return dispatch({
          type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_SUCCESS,
        });
      }

      apiHeaderResetRecaptchaToken();

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_FAILURE,
        payload: {
          error: {
            state: true,
          },
        },
      });
    } catch (error: any) {
      if (error instanceof BusinessException) {
        if (error?.type === EnumResponseCode.USER_ALREADY_EXISTS) {
          dispatch({
            type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_REQUEST,
          });

          const response = (await getEnrollmentsUsersObfuscatedService(
            cnp,
          )) as IResponseObfuscated;

          dispatch({
            type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_SUCCESS,
            payload: {
              data: {
                ...response,
              },
            },
          });

          Navigation.navigate(RouteName.FORGOTPASSWORDPHONE, { cnp });

          apiHeaderResetRecaptchaToken();

          return dispatch({
            type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_SUCCESS,
          });
        }

        return dispatch({
          type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_FAILURE,
          payload: {
            error: {
              type: error.type,
              state: true,
            },
          },
        });
      }

      console.log('não é BusinessException');

      apiHeaderResetRecaptchaToken();

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_FAILURE,
        payload: {
          error: {
            type: error.response.data.type,
            state: true,
          },
        },
      });
    }
  };

const getCloseErrorEnrollmentsUsersRecoveryValidate =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_FAILURE_CLOSE,
    });
  };

const postEnrollmentsUsersSendSmsRecoveryRequest =
  ({ cnp, phone }: IRequestSendSms) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_REQUEST,
      });

      await postEnrollmentsUsersSendSmsRecoveryService({
        cnp,
        phone,
      });

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_UPDATE_RECOVERY_PASSWORD_METHOD_REQUEST,
        payload: {
          data: {
            phone,
          },
        },
      });

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_SUCCESS,
      });

      return Navigation.navigate(RouteName.FORGOTPASSWORDCODE);
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postCloseErrorUsersSendSmsRecovery = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_FAILURE_CLOSE,
  });
};

const postEnrollmentsUsersValidateSmsRequest =
  ({ cnp, token }: IRequestValidateSms) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_REQUEST,
      });

      await postEnrollmentsUsersValidateSmsService({
        cnp,
        token,
      });

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_UPDATE_RECOVERY_PASSWORD_METHOD_REQUEST,
        payload: {
          data: {
            token,
          },
        },
      });

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_SUCCESS,
      });

      return Navigation.navigate(RouteName.FORGOTPASSWORDUPDATE);
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postCloseErrorUsersValidateSms = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_FAILURE_CLOSE,
  });
};

const putEnrollmentsUsersUpdatePasswordRecoveryRequest =
  (data: IRequestRecoveryPassword) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_REQUEST,
      });

      await putEnrollmentsUsersPasswordRecoveryService(data);

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_SUCCESS,
      });

      return Navigation.navigate(RouteName.LOGIN);
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const putCloseSuccessUsersUpdatePasswordRecovery =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_SUCCESS_CLOSE,
    });
  };

const putCloseErrorUsersUpdatePasswordRecovery =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_FAILURE_CLOSE,
    });
  };

const validateBusinessException = (response: any): void => {
  if (
    [
      EnumResponseCode.USER_ALREADY_EXISTS,
      EnumResponseCode.USER_NOT_EXISTS_IN_PARTNERS,
    ].includes(response?.message)
  )
    throw new BusinessException(response?.message);
};

const getEnrollmentsUsersValidateRequest =
  (
    { cnp, recaptchaTokenV2, recaptchaTokenV3 }: IRequestValidateRegister,
    dataNavigation: any,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_VALIDATE_METHOD_REQUEST,
      });

      apiHeaderSetRecaptchaToken({
        recaptchaTokenV2: `${recaptchaTokenV2}`,
        recaptchaTokenV3: `${recaptchaTokenV3}`,
      });
      const responseAuth = await postUnloggedSession(
        cnp,
        'forgotPasswordAndRegistration',
      );
      apiHeaderSetOutsideToken({ token: responseAuth?.token || '' });
      apiHeaderResetRecaptchaToken();

      const response = (await getEnrollmentsUsersValidateService({
        cnp,
      })) as IResponseValidateRegister;

      validateBusinessException(response);

      if (response?.message === EnumResponseCode.USER_ALREADY_EXISTS) {
        dispatch({
          type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_REQUEST,
        });

        const response = (await getEnrollmentsUsersObfuscatedService(
          cnp,
          true,
        )) as IResponseObfuscated;

        dispatch({
          type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_SUCCESS,
          payload: {
            data: {
              ...response,
            },
          },
        });

        Navigation.navigate(RouteName.FORGOTPASSWORDPHONE, { cnp });

        apiHeaderResetRecaptchaToken();

        return dispatch({
          type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_SUCCESS,
        });
      }

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_VALIDATE_METHOD_SUCCESS,
      });

      if (!dataNavigation?.email || !dataNavigation?.phone) {
        return postEnrollmentsUsersSendSmsRegisterRequest(
          {
            cnp: dataNavigation?.cnp || '',
            email: dataNavigation?.email,
            phone: dataNavigation?.phone,
            isEntrepreneur: false,
            isManager: false,
          },
          {
            ...dataNavigation,
            method: dataNavigation?.email ? 'email' : 'sms',
            clientType: 'client',
          },
        )(dispatch);
      }
      apiHeaderResetRecaptchaToken();

      return Navigation.navigate(RouteName.REGISTERVERIFICATIONMETHOD, {
        ...dataNavigation,
        clientType: 'client',
      });
    } catch (error: any) {
      if (error instanceof BusinessException) {
        if (error.type === EnumResponseCode.USER_NOT_EXISTS_IN_PARTNERS) {
          dispatch({
            type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_VALIDATE_METHOD_SUCCESS,
          });

          apiHeaderResetRecaptchaToken();

          return Navigation.navigate(RouteName.REGISTERNOTCLIENT, {
            ...dataNavigation,
            clientType:
              dataNavigation && dataNavigation?.clientType !== 'client'
                ? dataNavigation?.clientType
                : 'notClient',
          });
        }

        return dispatch({
          type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_VALIDATE_METHOD_FAILURE,
          payload: {
            error: {
              type: error.type,
            },
          },
        });
      }

      apiHeaderResetRecaptchaToken();

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_VALIDATE_METHOD_FAILURE,
        payload: {
          error: {
            type: error.response.data.type,
            state: true,
          },
        },
      });
    }
  };

const getCloseErrorEnrollmentsUsersValidate = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_VALIDATE_METHOD_FAILURE_CLOSE,
  });
};

const postEnrollmentsUsersSendSmsRegisterRequest =
  (
    { cnp, phone, isEntrepreneur, isManager, email }: IRequestSendSms,
    dataNavigation: any,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_REQUEST,
      });

      Navigation.navigate(RouteName.REGISTERTWOVERSIOVERIFYCODE, {
        ...dataNavigation,
      });

      await postEnrollmentsUsersSendSmsRegisterService({
        cnp,
        phone: dataNavigation && dataNavigation?.method === 'email' ? '' : phone,
        isEntrepreneur,
        isManager,
        email: dataNavigation && dataNavigation?.method === 'email' ? email : '',
      });

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_FAILURE_CLOSE,
      });

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postCloseErrorEnrollmentsUsersSendSmsRegister =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_FAILURE_CLOSE,
    });
  };

const postEnrollmentsUsersValidateSmsRegisterRequest =
  ({ cnp, token }: IRequestValidateSms, dataNavigation: any) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_REQUEST,
      });

      await postEnrollmentsUsersValidateSmsService({
        cnp,
        token,
      });

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_UPDATE_CREATE_CLIENT_METHOD_REQUEST,
        payload: {
          data: {
            token,
          },
        },
      });

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_SUCCESS,
      });

      return Navigation.navigate(RouteName.REGISTERPASSWORDCLIENT, {
        ...dataNavigation,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.data?.type,
            state: true,
          },
        },
      });
    }
  };

const postCloseErrorEnrollmentsValidateSmsRegister =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_FAILURE_CLOSE,
    });
  };

const updateEnrollmentsCreateClientRequest =
  (data?: IRequestCreateUserClient) => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_UPDATE_CREATE_CLIENT_METHOD_REQUEST,
      payload: {
        data: data ? { ...data } : null,
      },
    });
  };

const resolvePost = ({
  isNotClient,
  isManager,
  isEntrepreneur,
}: {
  isNotClient: boolean;
  isManager: boolean;
  isEntrepreneur: boolean;
}): `notClient` | `manager` | `entrepreneur` | `client` => {
  if (isNotClient) return `notClient`;
  if (isEntrepreneur) return `entrepreneur`;
  if (isManager) return `manager`;

  return `client`;
};

const postsEnrollments = {
  notClient: postEnrollmentsUsersNotClientCreateRegisterService,
  entrepreneur: postEnrollmentsUsersNotClientCreateRegisterService,
  manager: postEnrollmentsManagerUsersCreateRegisterService,
  client: postEnrollmentsUsersCreateRegisterService,
};

const postEnrollmentsUsersCreateRegisterRequest =
  (data: IRequestCreateUserClient | any) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_CREATE_METHOD_REQUEST,
      });

      apiHeaderSetRecaptchaToken({
        recaptchaTokenV2: data?.recaptchaTokenV2,
        recaptchaTokenV3: data?.recaptchaTokenV3,
      });

      const postType = resolvePost(data);
      const postsEnrollment = postsEnrollments[postType];

      const responseRegister = (await postsEnrollment(data)) as any;

      localStorage.setItem('@digiIgua_data_userLogin', responseRegister?.token);

      const {
        isClient,
        isManager,
        isEntrepreneur,
      }: { isClient: boolean; isManager: boolean; isEntrepreneur: boolean } =
        jwtDecode(responseRegister?.token);

      dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_AUTH_METHOD_SUCCESS,
        payload: {
          data: data?.password,
          isClient,
          isManager,
          isEntrepreneur,
        },
      });

      dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_SESSIONS_FIRST_ACCESS,
      });

      const responseMe =
        (await getEnrollmentsMeService()) as IResponseEnrollmentsMe[];

      if (responseMe.length < 2) {
        dispatch({
          type: RepositoriesTypesAuth.ENROLLMENTS_REGISTRATION_CHECK_REQUEST,
          payload: {
            data: responseMe[0],
          },
        });
      }

      dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_GET_ME_METHOD_SUCCESS,
        payload: {
          data: responseMe,
        },
      });

      dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_GET_SESSIONS_METHOD_SUCCESS,
        payload: {
          data: responseRegister?.token,
        },
      });

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_CREATE_METHOD_SUCCESS,
      });

      const responseUsersInfo =
        (await getEnrollmentsUsersInfoService()) as IResponseUsersInfo;

      const responseTogglesMe = (await togglesMeServices()) as IResponse[];

      dispatch({
        type: RepositoriesTypesEnrollmentsTogglesMe.TOGGLES_ME_GET_METHOD_SUCCESS,
        payload: {
          data: responseTogglesMe,
        },
      });

      apiHeaderResetRecaptchaToken();

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_METHOD_SUCCESS,
        payload: {
          data: responseUsersInfo,
        },
      });
    } catch (error: any) {
      apiHeaderResetRecaptchaToken();

      if (
        error.response.data.type ===
        EnumResponseCode.INCOMPLETE_CUSTOMER_DATA_IN_MANAGER
      ) {
        return dispatch({
          type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_CREATE_METHOD_FAILURE,
          payload: {
            error: {
              type: error?.response?.data?.type,
              status: error?.response?.data?.status,
              state: true,
            },
          },
        });
      }

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_CREATE_METHOD_FAILURE,
        payload: {
          error: {
            type: error?.response?.data?.type,
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getEnrollmentsUsersObfuscatedRequest =
  (cnp: string, isForgot?: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_REQUEST,
      });

      const response = (await getEnrollmentsUsersObfuscatedService(
        cnp,
      )) as IResponseObfuscated;

      if (!isForgot) {
        dispatch({
          type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_SUCCESS,
          payload: {
            data: {
              ...response,
            },
          },
        });

        return Navigation.navigate(RouteName.PASSWORDEXPIRATION, { cnp });
      }

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_SUCCESS,
        payload: {
          data: {
            ...response,
          },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postEnrollmentsUsersSendRecoveryRequest =
  (data: IRequestSendRecovery) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_REQUEST,
      });

      const response = (await postEnrollmentsUsersSendRecoveryService(
        data,
      )) as IResponseSendCodeRecovery;

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_FAILURE_CLOSE,
      });

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_SUCCESS,
        payload: {
          data: {
            ...response,
          },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postCloseEnrollmentsUsersSendRecovery = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_FAILURE_CLOSE,
  });
};

const postEnrollmentsUsersSendRecoveryRecoveryRequest =
  ({ cnp, token }: IRequestValidateSms, hasForgot?: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_REQUEST,
      });

      await postEnrollmentsUsersValidateSmsService({
        cnp,
        token,
      });

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_UPDATE_CREATE_CLIENT_METHOD_REQUEST,
        payload: {
          data: {
            token,
          },
        },
      });

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_SUCCESS,
      });

      return Navigation.navigate(
        hasForgot
          ? RouteName.FORGOTPASSWORDUPDATE
          : RouteName.PASSWORDEXPIRATIONNEWPASSWORD,
        { cnp, token },
      );
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_FAILURE,
        payload: {
          error: {
            type: error?.data?.type,
          },
        },
      });
    }
  };

const putEnrollmentsUsersUpdatePasswordRequest =
  (data: IRequestRecoveryPassword) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_REQUEST,
      });

      apiHeaderSetRecaptchaToken({
        recaptchaTokenV2: data?.recaptchaTokenV2,
        recaptchaTokenV3: data?.recaptchaTokenV3,
      });

      await putEnrollmentsUsersPasswordRecoveryService(data);

      apiHeaderResetRecaptchaToken();

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_SUCCESS,
      });
    } catch (error: any) {
      apiHeaderResetRecaptchaToken();

      return dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_FAILURE,
        payload: {
          type: error?.response?.data.type,
          state: true,
        },
      });
    }
  };

const putCloseSuccessUsersUpdatePassword = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_SUCCESS_CLOSE,
  });
};

const putCloseErrorUsersUpdatePassword = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_FAILURE_CLOSE,
  });
};

const postCloseErrorEnrollmentsUsersCreateRegister =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_CREATE_METHOD_FAILURE_CLOSE,
    });
  };

const postDataRegisterNotClient =
  (data: IRequestCreateUserNotClient) => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_POST_DATA_NOT_CLIENT_METHOD_REQUEST,
      payload: data,
    });
  };

export {
  getEnrollmentsUsersRequest,
  getEnrollmentsUsersRequestClear,
  updateEnrollmentsUsersPasswordRequest,
  updateSuccessCloseEnrollmentsPasswordUsers,
  updateErrorCloseEnrollmentsPasswordUsers,
  updateEnrollmentsUsersRequest,
  updateSuccessCloseEnrollmentsUsers,
  updateErrorCloseEnrollmentsUsers,
  getEnrollmentsUsersRecoveryValidateRequest,
  getCloseErrorEnrollmentsUsersRecoveryValidate,
  postEnrollmentsUsersSendSmsRecoveryRequest,
  postCloseErrorUsersSendSmsRecovery,
  postEnrollmentsUsersValidateSmsRequest,
  postCloseErrorUsersValidateSms,
  putEnrollmentsUsersUpdatePasswordRecoveryRequest,
  putCloseSuccessUsersUpdatePasswordRecovery,
  putCloseErrorUsersUpdatePasswordRecovery,
  getEnrollmentsUsersValidateRequest,
  getCloseErrorEnrollmentsUsersValidate,
  postEnrollmentsUsersSendSmsRegisterRequest,
  postCloseErrorEnrollmentsUsersSendSmsRegister,
  postEnrollmentsUsersValidateSmsRegisterRequest,
  postCloseErrorEnrollmentsValidateSmsRegister,
  updateEnrollmentsCreateClientRequest,
  postEnrollmentsUsersCreateRegisterRequest,
  postCloseErrorEnrollmentsUsersCreateRegister,
  postDataRegisterNotClient,
  getEnrollmentsUsersObfuscatedRequest,
  postEnrollmentsUsersSendRecoveryRequest,
  postCloseEnrollmentsUsersSendRecovery,
  postEnrollmentsUsersSendRecoveryRecoveryRequest,
  putEnrollmentsUsersUpdatePasswordRequest,
  putCloseSuccessUsersUpdatePassword,
  putCloseErrorUsersUpdatePassword,
};
