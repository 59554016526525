import styled, { css } from 'styled-components';
import { Breakpoints } from '~/styles';

const tableTextCss = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #000000;
`;

export const DescriptionText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #6a7279;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #32a2b4;
  }
`;

export const ContactUsTable = styled.section`
  width: 100%;
  height: 344px;
  max-height: 100%;
  background: #f8f9fa;
  border: 1px solid #ebeced;
  overflow: scroll;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: 383px;
  }
`;

export const ContactUsTableHeader = styled.div`
  ${tableTextCss};
  height: 56px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 8px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 0 24px;
  }
`;

export const ContactUsRow = styled.a`
  display: flex;
  height: 36px;
  text-decoration: none;
`;

export const ContactUsItem = styled.span`
  ${tableTextCss};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebeced;

  :first-of-type {
    padding: 0 8px;
    justify-content: flex-start;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    :first-of-type {
      padding: 0 24px;
    }
  }
`;
