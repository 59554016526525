import { Reducer } from 'redux';

import { RepositoriesTypesUsers, IRepositoriesStateEnrollmentsUsers } from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsUsers = {
  data: null,
  dataCreateClient: null,
  dataCreateNotClient: null,
  dataSendCode: null,
  dataRecoveryPassword: null,
  dataObfuscated: null,
  rediRectRegister: false,
  loading: false,
  loadingUpdatePassword: false,
  loadingUpdate: false,
  loadingSendCodeRecovery: false,
  loadingValidateRecovery: false,
  loadingSendSms: false,
  loadingUpdatePasswordRecovery: false,
  loadingValidateRegister: false,
  loadingRegisterSendSms: false,
  loadingValidateSmsRegister: false,
  loadingValidateSms: false,
  loadingCreate: false,
  loadingCreateRegister: false,
  loadingObfuscated: false,
  loadingPutPasswordRecovery: false,
  error: null,
  errorUpdatePassword: null,
  errorUpdate: null,
  errorValidateRecovery: null,
  errorSendSms: null,
  errorSendCodeRecovery: null,
  errorUpdatePasswordRecovery: null,
  errorValidateRegister: null,
  errorRegisterSendSms: null,
  errorValidateSmsRegister: null,
  errorValidateSms: null,
  errorCreate: null,
  errorCreateRegister: null,
  errorObfuscated: null,
  errorPutPasswordRecovery: null,
  successUpdatePassword: false,
  successUpdate: false,
  successUpdatePasswordRecovery: false,
  registerMetothEmail: false,
};

const reducer: Reducer<IRepositoriesStateEnrollmentsUsers, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        errorUpdate: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loading: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_METHOD_CLEAR:
      return {
        ...state,
        data: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_REQUEST:
      return {
        ...state,
        loadingUpdatePassword: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_SUCCESS:
      return {
        ...state,
        loadingUpdatePassword: false,
        successUpdatePassword: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successUpdatePassword: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_FAILURE:
      return {
        ...state,
        errorUpdatePassword: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingUpdatePassword: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorUpdatePassword: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_METHOD_REQUEST:
      return {
        ...state,
        loadingUpdate: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_METHOD_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        successUpdate: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successUpdate: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_METHOD_FAILURE:
      return {
        ...state,
        errorUpdate: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingUpdate: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorUpdate: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_UPDATE_RECOVERY_PASSWORD_METHOD_REQUEST:
      return {
        ...state,
        dataRecoveryPassword: action.payload.data
          ? { ...state.dataRecoveryPassword, ...action.payload.data }
          : null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_REQUEST:
      return {
        ...state,
        loadingValidateRecovery: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_SUCCESS:
      return {
        ...state,
        loadingValidateRecovery: false,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_FAILURE:
      return {
        ...state,
        errorValidateRecovery: {
          status: action.payload.error.status,
          type: action.payload.error.type,
          state: true,
        },
        loadingValidateRecovery: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorValidateRecovery: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_REQUEST:
      return {
        ...state,
        loadingSendSms: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_SUCCESS:
      return {
        ...state,
        loadingSendSms: false,
        errorSendSms: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_FAILURE:
      return {
        ...state,
        errorSendSms: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingSendSms: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorSendSms: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_REQUEST:
      return {
        ...state,
        loadingValidateSms: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_SUCCESS:
      return {
        ...state,
        loadingValidateSms: false,
        errorValidateSms: null,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_FAILURE:
      return {
        ...state,
        errorValidateSms: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingValidateSms: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorValidateSms: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_REQUEST:
      return {
        ...state,
        loadingUpdatePasswordRecovery: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_SUCCESS:
      return {
        ...state,
        loadingUpdatePasswordRecovery: false,
        errorUpdatePasswordRecovery: null,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_FAILURE:
      return {
        ...state,
        errorUpdatePasswordRecovery: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingUpdatePasswordRecovery: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorUpdatePasswordRecovery: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_VALIDATE_METHOD_REQUEST:
      return {
        ...state,
        loadingValidateRegister: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_VALIDATE_METHOD_SUCCESS:
      return {
        ...state,
        loadingValidateRegister: false,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_VALIDATE_METHOD_FAILURE:
      return {
        ...state,
        errorValidateRegister: {
          status: action.payload.error.status,
          type: action.payload.error.type,
          state: true,
        },
        loadingValidateRegister: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_VALIDATE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorValidateRegister: null,
        loadingValidateRegister: false,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_REQUEST:
      return {
        ...state,
        loadingRegisterSendSms: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_SUCCESS:
      return {
        ...state,
        loadingRegisterSendSms: false,
        errorRegisterSendSms: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_FAILURE:
      return {
        ...state,
        errorRegisterSendSms: {
          status: action.payload?.error?.status,
          message: action.payload?.error?.type,
          state: true,
        },
        loadingRegisterSendSms: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorRegisterSendSms: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_REQUEST:
      return {
        ...state,
        loadingSendCodeRecovery: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_SUCCESS:
      return {
        ...state,
        loadingSendCodeRecovery: false,
        errorSendCodeRecovery: null,
        dataSendCode: action.payload.data,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_FAILURE:
      return {
        ...state,
        errorSendCodeRecovery: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingSendCodeRecovery: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorSendCodeRecovery: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_CREATE_METHOD_REQUEST:
      return {
        ...state,
        loadingCreate: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_CREATE_METHOD_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_REQUEST:
      return {
        ...state,
        loadingValidateSmsRegister: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_SUCCESS:
      return {
        ...state,
        loadingValidateSmsRegister: false,
        errorValidateSmsRegister: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_FAILURE:
      return {
        ...state,
        errorValidateSmsRegister: {
          type: action.payload?.error?.type,
        },
        loadingValidateSmsRegister: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorValidateSmsRegister: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_CREATE_METHOD_FAILURE:
      return {
        ...state,
        errorCreate: {
          status: action.payload.error.status,
          message: action.payload.error.type,
          state: true,
        },
        loadingCreate: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_USERS_CREATE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorCreate: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_UPDATE_CREATE_CLIENT_METHOD_REQUEST:
      return {
        ...state,
        dataCreateClient: action.payload.data
          ? { ...state.dataCreateClient, ...action.payload.data }
          : null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_REQUEST:
      return {
        ...state,
        loadingObfuscated: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_SUCCESS:
      return {
        ...state,
        dataObfuscated: { ...action.payload.data },
        loadingObfuscated: false,
        errorObfuscated: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_FAILURE:
      return {
        ...state,
        errorObfuscated: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingObfuscated: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorObfuscated: null,
      };

    case RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_REQUEST:
      return {
        ...state,
        loadingPutPasswordRecovery: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_SUCCESS:
      return {
        ...state,
        loadingPutPasswordRecovery: false,
        successUpdatePasswordRecovery: true,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successUpdatePasswordRecovery: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_FAILURE:
      return {
        ...state,
        errorPutPasswordRecovery: {
          status: action.payload.status,
          type: action.payload?.type,
          state: true,
        },
        loadingPutPasswordRecovery: false,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorPutPasswordRecovery: null,
      };

    case RepositoriesTypesUsers.CREATEMETOTH:
      return {
        ...state,
        registerMetothEmail: action.payload.data,
      };
    case RepositoriesTypesUsers.ENROLLMENTS_POST_DATA_NOT_CLIENT_METHOD_REQUEST:
      return {
        ...state,
        dataCreateNotClient: action.payload,
      };
    case RepositoriesTypesUsers.CLEAN:
      return {
        ...state,
        successUpdate: false,
        successUpdatePassword: false,
        errorUpdatePassword: {
          status: '',
          message: '',
          state: false,
        },
        errorUpdate: {
          status: '',
          message: '',
          state: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
