import styled, { css } from 'styled-components';
import { colors } from '~/design_system/styles/colors';

const textStyles = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: ${colors.grey_100};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ContainerRules = styled.div`
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  background: ${colors.grey_10};
  border-radius: 8px;
  padding: 16px;
`;

export const ListRules = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const RuleText = styled.li`
  ${textStyles};
  display: flex;
  justify-content: space-between;
`;
