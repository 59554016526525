import React from 'react';
import { Color } from '~/styles';
import InvoiceSlick from './InvoiceSlick';
import {
  Container,
  PaymentGroupCard,
  ContainerInvoices,
  ContainerPaymentGroup,
} from './styles';
import {
  StatusGroupCongratulations,
  StatusGroupInstallment,
  StatusGroupPending,
  StatusGroupWait,
} from './icon';
import { IState } from '~/store/modules/types';
import { useSelector } from 'react-redux';

const EasyInvoice = () => {
  const loading = useSelector((item: IState) => item?.enrollmentsInvoices?.loading);
  const invoices = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.data?.invoices,
  );
  const invoicesGroup = invoices?.filter(
    (invoice) =>
      invoice.status.label === 'PENDENTE' || invoice.status.label === 'VENCIDA',
  );
  const registrationCheck = useSelector(
    (item: IState) => item?.enrollmentsAuth.registrationCheck,
  );

  const getStatusInvoicesGroup = () => {
    const invoicesGroupLengths = invoicesGroup?.length || 0;
    const invoicesGroupPending = invoicesGroup?.filter(
      (invoice) => invoice.status.label === 'PENDENTE',
    );
    const invoicesGroupDefeated = invoicesGroup?.filter(
      (invoice) => invoice.status.label === 'VENCIDA',
    );

    if (!invoices || invoices.length < 1 || loading) {
      return {
        color: Color.blueSeconde,
        icon: <StatusGroupWait />,
        title: 'Aguarde!',
        description:
          'Sua matrícula ainda não tem faturas. Em caso de dúvidas, favor entre em contato pelo Fale conosco.',
      };
    }

    if (invoicesGroup && invoicesGroup?.length > 1) {
      return {
        color: Color.red,
        icon: <StatusGroupInstallment />,
        title: `${invoicesGroupLengths} faturas em aberto`,
        description: (
          <>
            Faturas referentes a matrícula
            <br />
            <br />
            {registrationCheck?.code}
            <br />
            {registrationCheck?.address_connection}.
          </>
        ),
      };
    }

    if (
      invoicesGroupLengths === 1 &&
      invoicesGroupPending &&
      invoicesGroupPending.length === 1
    ) {
      return {
        color: Color.blueIntense,
        icon: <StatusGroupPending />,
        title: 'Sua fatura vence em breve',
        description: (
          <>
            Faturas referentes a matrícula
            <br />
            <br />
            {registrationCheck?.code}
            <br />
            {registrationCheck?.address_connection}.
          </>
        ),
      };
    }

    if (
      invoicesGroupLengths === 1 &&
      invoicesGroupDefeated &&
      invoicesGroupDefeated.length === 1
    ) {
      return {
        color: Color.red,
        icon: <StatusGroupInstallment />,
        title: 'Não perca tempo!',
        description: (
          <>
            Faturas referentes a matrícula
            <br />
            <br />
            {registrationCheck?.code}
            <br />
            {registrationCheck?.address_connection}.
          </>
        ),
      };
    }

    return {
      color: Color.green,
      icon: <StatusGroupCongratulations />,
      title: 'Parabéns!',
      description: 'Agradecemos por manter as suas contas em dia.',
    };
  };

  return (
    <Container>
      <ContainerPaymentGroup>
        <PaymentGroupCard fullHeight color={getStatusInvoicesGroup().color}>
          <p>
            <span>{getStatusInvoicesGroup().icon}</span>
            {getStatusInvoicesGroup().title}
          </p>
          <p>
            <span>{getStatusInvoicesGroup().icon} </span>
            {!invoices || invoices.length < 1 || loading ? (
              <div>{getStatusInvoicesGroup().description}</div>
            ) : (
              getStatusInvoicesGroup().description
            )}
          </p>
        </PaymentGroupCard>
      </ContainerPaymentGroup>
      <ContainerInvoices>
        <InvoiceSlick />
      </ContainerInvoices>
    </Container>
  );
};

export default EasyInvoice;
