import React, { FC } from 'react';

interface IProps {
  width?: number;
  height?: number;
}
const BorderQrCode: FC<IProps> = ({ width = 40, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 37.7816V18C2 9.16345 9.16344 2 18 2H37.7768"
        stroke="#C7C9C7"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { BorderQrCode };
