import React, { FC, ReactNode } from 'react';

import {
  ContainerIcon,
  ContainerTexts,
  DescriptionText,
  Main,
  TitleText,
} from './styles';
import { IEnumMessageType } from './enum';
import {
  MessageDefaultIcon,
  MessageErrorIcon,
  MessageSuccessIcon,
  MessageWarningIcon,
} from './icons';

interface IMessageProps {
  type:
    | IEnumMessageType.DEFAULT
    | IEnumMessageType.WARNING
    | IEnumMessageType.SUCCESS
    | IEnumMessageType.ERROR;
  title: string;
  messageText: string | ReactNode;
}

const Message: FC<IMessageProps> = ({ type, title, messageText }) => {
  const iconMessage = {
    [IEnumMessageType.DEFAULT]: <MessageDefaultIcon />,
    [IEnumMessageType.WARNING]: <MessageWarningIcon />,
    [IEnumMessageType.SUCCESS]: <MessageSuccessIcon />,
    [IEnumMessageType.ERROR]: <MessageErrorIcon />,
  };

  return (
    <Main>
      <ContainerIcon>{iconMessage[type]}</ContainerIcon>
      <ContainerTexts>
        <TitleText>{title}</TitleText>
        <DescriptionText>{messageText}</DescriptionText>
      </ContainerTexts>
    </Main>
  );
};

export default Message;
