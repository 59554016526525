import React, { FC } from 'react';
import { Main, InfoDoublePayment, ContainerInfoDoublePayment } from './styles';

import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { AlertIcon } from '~/components/ModalCopyDataUpdate/Icons';
import formatter from '~/utils/formatter';

interface IProps {
  onClose: () => void;
  onConfirmPayment: () => void;
  showDuplicateModal?: {
    totalInvoicesAmount: number;
    totalChargesAmount: number;
  };
  installment: number;
}

const ModalDoublePayment: FC<IProps> = ({
  onClose,
  onConfirmPayment,
  showDuplicateModal,
  installment,
}) => {
  const handleCalculateInstallment = (amount: number) => {
    return formatter.formatCurrency(amount / installment);
  };

  return (
    <Main>
      <AlertIcon />
      <h1>Atenção!</h1>
      <p>Serão feitas duas cobranças separadas no seu cartão.</p>
      <ContainerInfoDoublePayment>
        <InfoDoublePayment>
          <p>
            {' '}
            {installment}x{' '}
            {handleCalculateInstallment(showDuplicateModal?.totalChargesAmount || 0)}
          </p>
          <p> Serviços</p>
        </InfoDoublePayment>
        <InfoDoublePayment>
          <p>
            {installment}x{' '}
            {handleCalculateInstallment(
              showDuplicateModal?.totalInvoicesAmount || 0,
            )}
          </p>
          <p> Faturas</p>
        </InfoDoublePayment>
      </ContainerInfoDoublePayment>
      <ButtonText
        id="ok-modal-aviso"
        backgroundColor={Color.blueSeconde}
        typeBtn="content"
        text="OK"
        onClick={() => {
          onClose();
          onConfirmPayment();
        }}
      />
    </Main>
  );
};

export default ModalDoublePayment;
