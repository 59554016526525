import React, { FC, useState } from 'react';
import {
  AmountText,
  ButtonVisible,
  Container,
  ContainerLabels,
  LabelText,
} from './styles';
import formatter from '~/utils/formatter';
import { EllipseIcon, EyeInvisibleIcon, EyeVisibleIcon } from './Icons';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Color } from '~/styles';

const BoxDetail: FC = () => {
  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );
  const overdueInvoices = dataDischargeDebts?.info?.total?.expiredQuantity || 0;
  const pendingInvoices = dataDischargeDebts?.info?.total?.pendingQuantity || 0;
  const amount = dataDischargeDebts?.info?.total?.amount || 0;

  const [visible, setVisible] = useState<boolean>(true);

  return (
    <Container>
      <LabelText>Débitos totais</LabelText>
      <AmountText>
        {visible ? (amount ? formatter.formatCurrency(amount) : 'R$ -') : 'R$ -'}{' '}
        <ButtonVisible type="button" onClick={() => setVisible(!visible)}>
          {visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />}
        </ButtonVisible>
      </AmountText>
      <ContainerLabels>
        <LabelText>
          {' '}
          <div>
            <EllipseIcon color={Color.red} />
          </div>
          {overdueInvoices} Vencidos
        </LabelText>
        <LabelText>
          {' '}
          <div>
            <EllipseIcon color={Color.yellow} />
          </div>
          {pendingInvoices} Pendentes
        </LabelText>
      </ContainerLabels>
    </Container>
  );
};

export default BoxDetail;
