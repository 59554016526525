import React, { FC } from 'react';

import {
  Card,
  ContainerCardBackgroundIcon,
  ContainerCards,
  DescriptionButton,
  DescriptionText,
  SubTitleText,
} from '../styles';
import { LeaksLackWaterBackgroundIcon, NewVentureBackgroundIcon } from '../icons';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

interface ICardsBottomProps {
  handleChangeModalContactUs: () => void;
}

const CardsBottom: FC<ICardsBottomProps> = ({ handleChangeModalContactUs }) => {
  return (
    <ContainerCards>
      <Card>
        <ContainerCardBackgroundIcon>
          <LeaksLackWaterBackgroundIcon />
        </ContainerCardBackgroundIcon>
        <SubTitleText>Vazamentos e falta d’água</SubTitleText>
        <DescriptionText>
          Caso precise reportar algum vazamento ou falta d’água, ou para acompanhar
          alguma solicitação do tipo já em andamento entre em contato pelo{' '}
          <DescriptionButton type="button" onClick={handleChangeModalContactUs}>
            Fale conosco
          </DescriptionButton>
          .
        </DescriptionText>
      </Card>
      <Card>
        <ContainerCardBackgroundIcon>
          <NewVentureBackgroundIcon />
        </ContainerCardBackgroundIcon>
        <SubTitleText>Novo empreendimento</SubTitleText>
        <DescriptionText>
          Caso precise verificar a viabilidade de ligação de água e esgoto em caso de
          novos empreendimentos acesse a central de DPA/DPE.
        </DescriptionText>
        <DescriptionButton
          type="button"
          onClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
        >
          DPA/DPE
        </DescriptionButton>
      </Card>
    </ContainerCards>
  );
};

export default CardsBottom;
