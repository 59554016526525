import styled, { css } from 'styled-components';
import { colors } from '~/design_system/styles/colors';
import { Breakpoints } from '~/styles';

export const ContainerMethods = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    gap: 32px;
  }
`;
interface IMethodButton {
  empty?: boolean;
}

export const MethodButton = styled.button<IMethodButton>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 12px;
  background: ${colors.white};
  border: 1px solid ${colors.grey_20};
  border-radius: 8px;
  cursor: pointer;

  :disabled {
    opacity: 0.4;
  }

  ${({ empty }) =>
    empty &&
    css`
      background: ${colors.grey_10};
      border: 1px solid ${colors.grey_20};
    `}

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 24px 12px;
    flex-direction: column;
    max-width: 180px;
  }
`;

export const ContainerMethodTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    align-items: center;
  }
`;
const textStyles = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
`;

export const MethodTypeText = styled.p<{ disabled: boolean }>`
  ${textStyles};
  color: ${({ disabled }) => (disabled ? colors.grey_40 : colors.grey_100)};
`;

export const MethodUserInfoText = styled.p`
  ${textStyles};
  color: ${colors.grey_50};
`;

export const ContactUsText = styled.span`
  ${textStyles};
  font-weight: 600;
  color: ${colors.aqua_60};
`;
