/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';

import {
  Container,
  Content,
  ContainerNotification,
  CardNotification,
  ContainerEmptyMessage,
  EnrollmentText,
  Title,
  BottomDescriptionText,
  StatusInfoNotification,
  StatusInfoDateNotification,
  StatusTopInfoNotification,
  StatusBottomInfoNotification,
  ButtonBack,
  CardNotificationBody,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Margin } from '~/components/Margin';
import { putUpdateNotificationRequest } from '~/store/modules/notifications/actions';
import moment from 'moment';
import EmptyMessage from '~/components/EmptyMessage';
import ArroButtonwBackSVG from '~/assets/Icons/ArrowBack copy';
import { NotificationEllipseIcon } from '../icons';

interface IProps {
  setSelecetedNotification: (e: any) => void;
  selectedNotification: any;
}

const ModalNotifications: FC<IProps> = ({
  selectedNotification,
  setSelecetedNotification,
}: IProps) => {
  const dispatch = useDispatch();

  const code = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.code,
  );
  const data = useSelector((state: IState) => state.notifications.data);

  function formatTextWithLineBreaks(text: string): (string | JSX.Element)[] {
    return text.split('\n').map((part, index: number) => (
      <React.Fragment key={index}>
        {part}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  }

  return (
    <Container>
      <Content>
        <Title>Notificações</Title>
        <EnrollmentText>
          Matrícula {code} <div id="border" />
        </EnrollmentText>
        {data && data?.length > 0 ? (
          <ContainerNotification>
            {selectedNotification ? (
              <>
                <CardNotificationBody>
                  <CardNotification
                    key={selectedNotification?.id}
                    type="button"
                    is_read={false}
                    read_view
                  >
                    <StatusTopInfoNotification>
                      <StatusInfoDateNotification read_view>
                        {selectedNotification.createdAt
                          ? moment(selectedNotification.createdAt).format('D MMM')
                          : ''}
                        <NotificationEllipseIcon />
                        {selectedNotification.createdAt
                          ? moment(selectedNotification.createdAt).format('dddd')
                          : ''}
                      </StatusInfoDateNotification>
                    </StatusTopInfoNotification>
                    <StatusBottomInfoNotification read_view>
                      {formatTextWithLineBreaks(selectedNotification.body)}
                    </StatusBottomInfoNotification>
                  </CardNotification>
                </CardNotificationBody>
                <ButtonBack
                  type="button"
                  onClick={() => setSelecetedNotification(undefined)}
                >
                  <ArroButtonwBackSVG />
                </ButtonBack>
              </>
            ) : (
              <>
                <CardNotificationBody>
                  {data?.map((notification) => (
                    <CardNotification
                      key={notification.id}
                      onClick={() => {
                        setSelecetedNotification(notification);
                        if (notification.is_read) return;
                        dispatch(putUpdateNotificationRequest(notification.id));
                      }}
                      type="button"
                      is_read={notification.is_read}
                    >
                      <StatusTopInfoNotification>
                        <StatusInfoNotification is_read={notification.is_read}>
                          {notification.is_read ? 'Lida' : 'Nova'}
                        </StatusInfoNotification>
                        <StatusInfoDateNotification>
                          {notification.createdAt
                            ? moment(notification.createdAt).format('D MMM')
                            : ''}
                          <NotificationEllipseIcon />
                          {notification.createdAt
                            ? moment(notification.createdAt).format('dddd')
                            : ''}
                        </StatusInfoDateNotification>
                      </StatusTopInfoNotification>
                      <StatusBottomInfoNotification>
                        {notification.body}
                      </StatusBottomInfoNotification>
                    </CardNotification>
                  ))}
                </CardNotificationBody>
                <BottomDescriptionText>
                  Essas foram todas as notificações dos últimos 12 meses
                </BottomDescriptionText>
              </>
            )}
          </ContainerNotification>
        ) : (
          <ContainerEmptyMessage read_view={selectedNotification}>
            <Margin height={48} />
            <EmptyMessage marginHeight={15}>
              <p>
                Você ainda não possui <br /> notificações
              </p>
            </EmptyMessage>
            <Margin height={48} />
          </ContainerEmptyMessage>
        )}
      </Content>
    </Container>
  );
};

export default ModalNotifications;
