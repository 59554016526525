import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Navigation from '~/utils/Navigation';

import { Main, ContainerButtons } from './styles';
import {
  getCloseErrorEnrollmentsUsersRecoveryValidate,
  getEnrollmentsUsersRecoveryValidateRequest,
  putCloseSuccessUsersUpdatePassword,
} from '~/store/modules/enrollmentsUsers/actions';
import formatter from '~/utils/formatter';
import { checkCnp } from '~/utils/validation';
import { IState } from '~/store/modules/types';
import { history } from '~/store';
import { Recaptcha } from '~/components/Recaptcha';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';
import { CardForm } from '~/design_system/components/CardForm';
import { Button } from '~/design_system/components/Button';
import { IEnumButtonVariant } from '~/design_system/components/Button/enum';
import Input from '~/design_system/components/Input';

const ForgotPassword: FC = () => {
  const dispatch = useDispatch();

  const dataLogin = history?.location?.state as any;

  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorValidateRecovery,
  );

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingValidateRecovery,
  );

  const [cnp, setCnp] = useState<string>(dataLogin?.cnp || '');
  const [cnpError, setCnpError] = useState<string>('');
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState<string | null>(null);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false);

  const clearErros = () => {
    dispatch(getCloseErrorEnrollmentsUsersRecoveryValidate());
    dispatch(putCloseSuccessUsersUpdatePassword());
  };

  useEffect(() => {
    if (error && error.type === EnumResponseCode.USER_NOT_EXISTS_IN_PARTNERS) {
      setCnpError('CPF ou CNPJ não cadastrado.');
    } else {
      setCnpError('');
    }

    if (
      error &&
      [
        EnumResponseCode.TOKENS_RECAPTCHA_MISSING,
        EnumResponseCode.TOKEN_V3_RECAPTCHA_MISSING,
        EnumResponseCode.TOKEN_V2_RECAPTCHA_MISSING,
        EnumResponseCode.TOKEN_V3_RECAPTCHA_INVALID,
        EnumResponseCode.TOKEN_V2_RECAPTCHA_INVALID,
        EnumResponseCode.RECAPTCHA_GENERIC,
      ].includes(error.type as string)
    ) {
      setShowRecaptcha(true);
    } else {
      setShowRecaptcha(false);
    }
  }, [error]);

  useEffect(() => {
    if (error) clearErros();

    const cnpIsvalid = checkCnp(cnp);

    if (cnp && !cnpIsvalid) {
      setCnpError('CPF ou CNPJ inválido');
    } else {
      setCnpError('');
    }
  }, [cnp]);

  useEffect(() => {
    clearErros();
  }, []);

  return (
    <Main>
      <Recaptcha
        setRecaptchaTokenV3={setRecaptchaTokenV3}
        setRecaptchaTokenV2={setRecaptchaTokenV2}
        showRecaptchaV2={showRecaptcha}
        onClose={() => {
          dispatch(getCloseErrorEnrollmentsUsersRecoveryValidate());
        }}
      />
      <CardForm
        title="Recuperar acesso"
        description="Para começar, precisamos localizar sua matrícula em nosso sistema. Por favor, informe seu CPF ou CNPJ."
        maxWidth={411}
        doubleGap
      >
        <Input
          name="cnp"
          label="CPF ou CNPJ"
          placeholder="Digite CPF ou CNPJ"
          value={cnp}
          onChange={(e) => setCnp(formatter.cpfCnpjMask(e.target.value))}
          error={!!cnpError}
          errorText={cnpError}
        />
        <ContainerButtons>
          <Button
            onClick={() => {
              if (!!cnpError || !cnp) return;
              dispatch(
                getEnrollmentsUsersRecoveryValidateRequest({
                  cnp,
                  recaptchaTokenV3: recaptchaTokenV3 || '',
                  recaptchaTokenV2: recaptchaTokenV2 || '',
                }),
              );
            }}
            loading={loading}
            disabled={!!cnpError || !cnp}
          >
            Continuar
          </Button>
          <Button
            variant={IEnumButtonVariant.TEXT}
            onClick={() => Navigation.goBack()}
          >
            Voltar
          </Button>
        </ContainerButtons>
      </CardForm>
    </Main>
  );
};

export default ForgotPassword;
