import {
  IRequestIPaymentVoucherService,
  IRequestPayment,
} from '~/store/modules/payment/types';
import { api } from './api';

function postPaymentService({
  invoices,
  charges,
  debtNegotiation,
  payment,
  isAuthenticated,
}: IRequestPayment) {
  return new Promise((resolve, reject) => {
    try {
      const postPayment: any = async () => {
        const url = isAuthenticated ? `v3/payments` : `v3/outsides/payments`;
        const debtNegotiationData = debtNegotiation
          ? { debtNegotiation }
          : undefined;

        const response = await api.post(url, {
          ...(invoices || undefined),
          ...(charges || undefined),
          ...debtNegotiationData,
          payment,
        });
        return response.data;
      };
      resolve(postPayment());
    } catch (error) {
      reject(error);
    }
  });
}

function getPaymentReceiptService({
  isAuthenticated,
  queryParam,
}: IRequestIPaymentVoucherService) {
  return new Promise((resolve, reject) => {
    try {
      const getPaymentReceipt: any = async () => {
        const url = isAuthenticated
          ? `v3/payments/voucher`
          : `v3/outsides/payments/voucher`;

        const groupIdParam = queryParam?.groupId
          ? { groupId: queryParam?.groupId }
          : {};
        const invoicesParam = queryParam?.invoices || undefined;
        const chargesParam = queryParam?.charges || undefined;

        const response = await api.get(url, {
          params: {
            ...groupIdParam,
            ...invoicesParam,
            ...chargesParam,
          },
        });

        return response.data;
      };
      resolve(getPaymentReceipt());
    } catch (error) {
      reject(error);
    }
  });
}

export { postPaymentService, getPaymentReceiptService };
