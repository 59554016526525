import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.div`
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 24px 10px 100px 10px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 24px 24px 198px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 70px);
    padding: 24px 0 203px 0;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(100%);
    padding: 24px 0 241px 0;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 0 24px 0;
  display: none;

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    display: inherit;
  }
`;

export const Card = styled.div`
  width: calc(100% - 32px);
  padding: 24px 0 40px;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: ${Color.white};
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  border-top: 16px solid ${Color.blueIntense};
  border-radius: 24px;
  max-width: 520px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 12px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 40px;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${Color.blueIntense};
  text-align: left;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    text-align: center;
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: ${Color.grayLightSeconde};
`;

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #202022;
`;

export const DescriptionSmall = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #6a7279;
`;

export const DescriptionButton = styled.button`
  width: fit-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
  color: #32a2b4;
  border: none;
  background: none;
  cursor: pointer;
`;

export const TextOption = styled.p`
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: #e9ecef;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${Color.blueIntense};
  text-transform: uppercase;
`;

export const ContainerOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
  }
`;

export const ContainerStepper = styled.div`
  padding: 0 13px;
`;
