import React, { FC } from 'react';
import { BarCodeImg, ContainerQRCode } from './styles';
import { ContainerPositionBorderQRCode } from '~/views/SimplifiedInvoiceV4/styles';
import { BorderQrCode } from '~/views/SimplifiedInvoiceV4/icons';

const BorderQrCodeStyle = () => {
  return <BorderQrCode width={32} height={32} />;
};

interface IDescriptionPaymentProps {
  barcodeImageBase64: string;
}

export const BarCode: FC<IDescriptionPaymentProps> = ({ barcodeImageBase64 }) => {
  const imageSrc = `data:image/png;base64,${barcodeImageBase64}`;

  return (
    <ContainerQRCode>
      <ContainerPositionBorderQRCode position="top-left">
        <BorderQrCodeStyle />
      </ContainerPositionBorderQRCode>
      <ContainerPositionBorderQRCode position="top-right">
        <BorderQrCodeStyle />
      </ContainerPositionBorderQRCode>
      <BarCodeImg src={imageSrc} alt="Código de Barras" />
      <ContainerPositionBorderQRCode position="bottom-left">
        <BorderQrCodeStyle />
      </ContainerPositionBorderQRCode>
      <ContainerPositionBorderQRCode position="bottom-right">
        <BorderQrCodeStyle />
      </ContainerPositionBorderQRCode>
    </ContainerQRCode>
  );
};
