interface IFormatPaymentIds {
  type: 'INVOICE' | 'CHARGE';
  id: string;
  enrollmentInfo: string;
}

export const formatPaymentIds = ({
  type,
  id,
  enrollmentInfo,
}: IFormatPaymentIds): any => {
  const keyPayment = type === 'INVOICE' ? 'enrollmentId' : 'operation';

  return {
    id,
    [keyPayment]: enrollmentInfo,
  };
};

export const formatPaymentIdsGroup = ({
  type,
  id,
  enrollmentInfo,
}: IFormatPaymentIds): any => {
  const typePayment = type === 'INVOICE' ? 'invoices' : 'charges';
  const keyPayment = type === 'INVOICE' ? 'enrollmentId' : 'operation';

  return {
    [typePayment]: [
      {
        id,
        [keyPayment]: enrollmentInfo,
      },
    ],
  };
};
