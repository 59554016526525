import React, { FC, ReactNode } from 'react';
import {
  CheckboxContainer,
  CheckmarkSVG,
  HiddenCheckbox,
  StyledCheckbox,
} from './styles';

interface IInputCheckedProps {
  label?: string;
  checked: boolean;
  onChange?: (ev: any) => void;
  children?: ReactNode;
}

const InputChecked: FC<IInputCheckedProps> = ({
  label,
  checked,
  onChange,
  children,
}) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} onChange={onChange} />
      <StyledCheckbox checked={checked}>
        <CheckmarkSVG checked={checked} width="18" height="18" viewBox="0 0 12 12">
          <path d="M2 6l3 2 5-5" />
        </CheckmarkSVG>
      </StyledCheckbox>
      {label || children}
    </CheckboxContainer>
  );
};

export default InputChecked;
