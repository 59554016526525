import React from 'react';
import PendingDetails from './PendingDetails';
import { Container } from './styles';
import SummaryPendingIssues from './SummaryPendingIssues';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import SkeletonCustom from '~/components/Skeleton';

const MyPendingIssues = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const loadingDebtsNegotiation = useSelector(
    (item: IState) => item.negotiation.loadingDebts,
  );

  return (
    <Container>
      {loadingDebtsNegotiation ? (
        <>
          <SkeletonCustom width={isMobile ? undefined : 252} height={75} />
          <SkeletonCustom height={99} />
        </>
      ) : (
        <>
          <SummaryPendingIssues />
          <PendingDetails />
        </>
      )}
    </Container>
  );
};

export default MyPendingIssues;
