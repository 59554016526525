/* eslint-disable prefer-const */
import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

import { IState } from '~/store/modules/types';
import { postErrorCloseInvoiceGroupPayment } from '~/store/modules/enrollmentsInvoices/actions';
import Payment from '~/components/Payment';
import EasyDuplicateSinglePaymentDataPaymentOld from './EasyDuplicateSinglePaymentDataPaymentOld';
import { history } from '~/store';
import {
  activateModalDataUpdate,
  getInvoiceDownload,
} from '~/store/modules/outsides/actions';
import { checkToggle } from '~/utils/toggles';
import { formatPaymentIds } from '~/components/Payment/utils';
import { IPaymentInvoices } from '~/store/modules/payment/types';

const EasyDuplicateSinglePaymentDataPayment: FC = () => {
  const dataInvoiceInstallmentGroup = history.location.state as any;

  const dispatch = useDispatch();

  const dataInvoiceInstallments = useSelector(
    (item: IState) => item?.outsides?.dataInvoiceInstallments,
  );
  const dataRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.dataRegistrationUpdate,
  );
  const loading = useSelector((item: IState) => item?.payment.loading);
  const loadingDownload = useSelector(
    (item: IState) => item?.outsides.loadingDownload,
  );
  const error = useSelector((item: IState) => item?.payment?.error);
  const toggles = useSelector((item: IState) => item.toggles.data);

  const possible_quantity_installments = dataInvoiceInstallments
    ? dataInvoiceInstallments?.length || 1
    : 1;
  const applyPaymentUnavailable = dataInvoiceInstallmentGroup?.typeView !== 'detail';
  const typeViewDetail = dataInvoiceInstallmentGroup?.typeView === 'detail';
  const paymentIds = typeViewDetail
    ? [
        dataInvoiceInstallmentGroup?.invoicesGroup[0]?.invoice_id ||
          dataInvoiceInstallmentGroup?.invoicesGroup[0]?.id ||
          '',
      ]
    : dataInvoiceInstallmentGroup?.invoicesGroup?.map(
        (invoiceGroup: any) => invoiceGroup?.invoice_id,
      );
  const isInvoice = dataInvoiceInstallmentGroup?.invoicesGroup[0]?.invoice_id;
  const cnp = dataInvoiceInstallmentGroup?.cnp;
  const enrollment = dataInvoiceInstallmentGroup?.enrollment;

  const [paymentModule, setPaymentModule] = useState<boolean>(true);

  const verifyToggles = async () => {
    setPaymentModule(await checkToggle(toggles, 'PAYMENT_MODULE'));
  };

  useEffect(() => {
    if (toggles && toggles.length > 0) verifyToggles();
  }, [toggles]);

  const backNavigation = () => {
    if (typeViewDetail) {
      Navigation.navigate(RouteName.EASYDUPLICATE, {
        typeView: dataInvoiceInstallmentGroup?.typeView,
        invoice: dataInvoiceInstallmentGroup.invoicesGroup[0],
        cnp: dataInvoiceInstallmentGroup?.cnp,
        enrollment: dataInvoiceInstallmentGroup?.enrollment,
      });
    } else {
      Navigation.navigate(RouteName.EASYDUPLICATESINGLEPAYMENT, {
        invoices: dataInvoiceInstallmentGroup?.invoices,
        invoicesDischargeDebts: dataInvoiceInstallmentGroup?.invoicesDischargeDebts,
        typeView: 'group',
        enrollment: dataInvoiceInstallmentGroup?.enrollment,
        cnp: dataInvoiceInstallmentGroup?.cnp,
      });
    }
  };

  const links = typeViewDetail
    ? [
        {
          label: 'Home',
          active: false,
          onClick: () => Navigation.navigate(RouteName.LANDING),
        },
        {
          label: 'Segunda via fácil',
          active: false,
          onClick: () => Navigation.navigate(RouteName.EASYDUPLICATE),
        },
        {
          label: 'Fatura completa',
          active: false,
          onClick: () => backNavigation(),
        },
        {
          label: 'Pagamento com cartão de crédito',
          active: true,
        },
      ]
    : [
        {
          label: 'Home',
          active: false,
          onClick: () => Navigation.navigate(RouteName.LANDING),
        },
        {
          label: 'Pagamento único',
          active: true,
        },
      ];

  useEffect(() => {
    dispatch(postErrorCloseInvoiceGroupPayment());
  }, []);

  const loadingLabel = useMemo(() => {
    if (loading) return 'Processando...';
    return 'Baixando...';
  }, [loading, loadingDownload]);

  useEffect(() => {
    if (error && error?.state) {
      ReactGA.event({
        category: 'Pagamento Único',
        action: '[Pg. Único][WEB] Pagamento sem Sucesso',
      });
    }
  }, [error]);

  const handleDownloadBillet = (
    typePaymentSelected: 'credit_card' | 'pix' | 'billet',
  ) => {
    if (loadingDownload) return;

    ReactGA.event({
      category: 'Pagamento de fatura',
      action: '[AL][Web][Home][Pagamento de Fatura]Baixar boleto simplificado',
    });

    if (typePaymentSelected === 'billet') {
      ReactGA.event({
        category: 'Baixar Boleto',
        action: '[WEB][2a Via Fácil][Boleto] Baixar Boleto',
      });
    } else {
      ReactGA.event({
        category: 'Baixar Boleto',
        action: '[WEB][2a Via Fácil][PIX] Baixar Boleto',
      });
    }

    dispatch(
      getInvoiceDownload(
        paymentIds,
        isInvoice ? 'invoice' : 'charge',
        cnp,
        enrollment?.split('-')[0],
      ),
    );

    if (dataRegistrationUpdate?.isUpdatable)
      dispatch(activateModalDataUpdate('download'));
  };

  if (!paymentModule) return <EasyDuplicateSinglePaymentDataPaymentOld />;

  const getDataPaymentIds = (): any => {
    let invoices: Array<IPaymentInvoices> = [];

    paymentIds.map((id: number) => {
      invoices?.push(
        formatPaymentIds({
          type: 'INVOICE',
          id: String(id),
          enrollmentInfo: enrollment,
        }),
      );

      return id;
    });

    return { invoices };
  };

  return (
    <Payment
      links={links}
      loading={loading || loadingDownload}
      loadingLabel={loadingLabel}
      paymentUnavailable={{
        credit_card: !isInvoice,
        pix: applyPaymentUnavailable,
        billet: applyPaymentUnavailable,
      }}
      typePayment="easy_duplicate_payment"
      paymentData={{
        invoices: getDataPaymentIds(),
        amount: dataInvoiceInstallmentGroup?.totalAmount || 0,
        possible_quantity_installments,
        qr_code_key: dataInvoiceInstallmentGroup?.qr_code_key,
        bar_code_number: dataInvoiceInstallmentGroup?.bar_code_number,
        bar_code_base64: dataInvoiceInstallmentGroup?.bar_code_base64,
        routeInvoiceReceipt: RouteName?.EASYDUPLICATESINGLEPAYMENTDATAPAYMENTRECEIPT,
      }}
      operation={{
        company_name: dataRegistrationUpdate?.company_name || '',
        cnp: dataRegistrationUpdate?.cnp_alert || '',
      }}
      handleDownloadBillet={handleDownloadBillet}
      handleBackNavigation={backNavigation}
    />
  );
};

export default EasyDuplicateSinglePaymentDataPayment;
