import styled from 'styled-components';
import { Breakpoints } from '~/styles';
import { IEnumModalType } from './enum';
import { colors } from '~/design_system/styles/colors';

const color = {
  [IEnumModalType.DEFAULT]: colors.aqua_60,
  [IEnumModalType.WARNING]: colors.yellow_80,
  [IEnumModalType.SUCCESS]: colors.green_80,
  [IEnumModalType.ERROR]: colors.red_80,
};

export const Main = styled.div`
  width: 100%;
  max-width: calc(100% - 88px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 24px;
  background: ${colors.white};
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  border-radius: 16px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    max-width: calc(400px - 64px);
  }
`;

export const TitleText = styled.h3<{
  type:
    | IEnumModalType.DEFAULT
    | IEnumModalType.WARNING
    | IEnumModalType.SUCCESS
    | IEnumModalType.ERROR;
}>`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${({ type }) => color[type]};
`;

export const ButtonClose = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const ContainerInfo = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 24px 40px;
  }
`;

export const DescriptionText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  color: ${colors.grey_100};
`;
