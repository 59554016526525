import React from 'react';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';

import { Margin } from '~/components/Margin';
import {
  AmountText,
  Border,
  ButtonPayment,
  Container,
  ContainerInfo,
  DueDateText,
  DescriptionText,
  PaymentText,
  ButtonWrap,
  BoxHeader,
} from './styles';
import { IDadosDebts } from '~/store/modules/outsides/types';
import formatter from '~/utils/formatter';
import { statusColor } from '~/utils/invoiceStatus';
import ArrowSVG from '~/assets/Icons/Arrow';
import { getOutsidesInvoicesInstallmentsRequest } from '~/store/modules/outsides/actions';
import { RouteName } from '~/routes/Routes.name';

interface IProps {
  data: IDadosDebts;
  cnp: string;
  enrollment: string;
  setSelectedInvoice: (invoice: any) => void;
  setMethod: (state: 'pixCode' | 'barCode') => void;
  paymentModule: boolean;
}

const InvoiceDischargeDebts: React.FC<IProps> = ({
  data,
  cnp,
  enrollment,
  setSelectedInvoice,
  setMethod,
  paymentModule,
}) => {
  const dispatch = useDispatch();

  return (
    <Container>
      <Border color={statusColor(data?.status?.label || '', true)} />
      <ContainerInfo>
        <BoxHeader>
          <DescriptionText>{data.description}</DescriptionText>
          <DueDateText color={statusColor(data?.status?.label || '', true)}>
            {data?.status?.label === 'VENCIDA' ? 'Vencida ' : 'Vence '} em{' '}
            {moment(data?.maturity_date).format('DD/MM/YY')}
          </DueDateText>
        </BoxHeader>
        <Margin height={16} />
        <PaymentText />
        <Margin height={6} />
        <AmountText color={statusColor(data?.status?.label || '', true)}>
          {formatter.formatCurrency(data?.amount)}
        </AmountText>
        <Margin height={6} />
        <ButtonWrap>
          <ButtonPayment
            type="button"
            onClick={() => {
              if (data.pix_code) {
                setMethod('pixCode');
              } else {
                setMethod('barCode');
              }

              ReactGA.event({
                category: 'Pagamento Único',
                action: '[QD][C4][WEB] – Pagamento de uma única dívida',
              });

              ReactGA.event({
                category: 'Pagamento de uma única dívida',
                action: '[QD][C3][WEB] – Pagamento de uma única dívida',
              });

              if (paymentModule) {
                dispatch(
                  getOutsidesInvoicesInstallmentsRequest(
                    cnp,
                    data?.amount || 0,
                    {
                      typeView: 'detail',
                      invoicesGroup: [
                        {
                          ...data,
                        },
                      ],
                      totalAmount: data?.amount,
                      cnp,
                      enrollment,
                      qr_code_key: data?.pix_code,
                      bar_code_number: data?.bar_code_number,
                    },
                    RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENT,
                  ),
                );
              } else {
                setSelectedInvoice(data);
              }
            }}
          >
            <p>Pagar</p>
            <ArrowSVG />
          </ButtonPayment>
        </ButtonWrap>
      </ContainerInfo>
    </Container>
  );
};

export default InvoiceDischargeDebts;
