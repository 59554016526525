/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-bind */
import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import configsAppJsonImport from '~/configs/app.config';
import ReactGA from 'react-ga4';
import {
  ButtonPayment,
  ContaineInvoices,
  ContainerBreadCrumb,
  ContainerButtonBack,
  ContainerButtonsList,
  ContainerCardFull,
  ContainerForgotPassword,
  ContainerMethods,
  ContainerPageNumber,
  ContainerPagination,
  ContainerSinglePayment,
  ContainerWarningBox,
  Content,
  Divider,
  Main,
  PageNumber,
  PaymentButton,
  TextList,
  Wrapper,
} from './styles';
import { Container, Input } from '~/common';
import { Button } from '~/components/ButtonV3';
import { BreadCrumb, IDatePage } from './BreadCrumb';
import MyCard from './MyCard';
import { IState } from '~/store/modules/types';
import {
  clearDischargeDebts,
  clearEasyDuplicateInvoices,
  getEasyDuplicateInvoices,
  getEasyDuplicateInvoicesByToken,
  getEasyInvoicesCredentialsByToken,
  getErrorCloseDischargeDebts,
  getErrorCloseEasyDuplicateInvoices,
  getOutsidesInvoicesInstallmentsRequest,
  getOutsidesRegistrationUpdateRequest,
  postErrorCloseOutsidesRegistrationUpdate,
} from '~/store/modules/outsides/actions';
import { IEasyInvoices } from '~/store/modules/outsides/types';
import formatter from '~/utils/formatter';
import { Breakpoints, Color } from '~/styles';
import { Margin } from '~/components/Margin';
import { CardSubtitle, CardTitle } from './MyCard/styles';
import { Link, useParams } from 'react-router-dom';
import { RouteName } from '~/routes/Routes.name';
import { UsefulLinks } from './UsefulLinks';
import Invoice from './Invoice';
import InvoiceDetail from './InvoiceDetail';
import PixSVG from '~/assets/Icons/pix';
import BarCodeSVG from '~/assets/Icons/BarCode';
import EmptyMessage from '~/components/EmptyMessage';
import LoadingComponent from '~/components/Loading';
import { checkCnp } from '~/utils/validation';
import ArrowSVG from '~/assets/Icons/Arrow';
import Navigation from '~/utils/Navigation';
import { useMediaQuery } from 'react-responsive';
import { PaymentIcon } from '../Invoices/icons';
import { CreditCardIcon } from './Icons';
import { history } from '~/store';
import WarningBox from '~/components/WarningBox';
import { saveEasyInvoicesCredentials } from '~/store/modules/outsidesCredentials/actions';
import InputCheckedV3 from '~/common/InputCheckedV3';
import InvoiceDischargeDebts from './InvoiceDischargeDebts';
// import ReCAPTCHA from 'react-google-recaptcha';
import { Recaptcha } from '~/components/Recaptcha';
import { showRecaptchaV2 } from '~/utils/showRecaptcha';
import { checkToggle } from '~/utils/toggles';

const configsAppJson = configsAppJsonImport as any;

export interface Values {
  cnp: string;
  enrollment: string;
  invoice: IEasyInvoices | null;
  method: 'pixCode' | 'barCode';
  type: 'CPF' | 'CNPJ';
  customError: string;
  invoiceList: IEasyInvoices[] | null;
  disabled: boolean;
}

const EasyDuplicate: FC = () => {
  const dataHistory = history.location.state as any;
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isTablet = useMediaQuery({ maxWidth: Breakpoints.laptop.max - 1 });

  const easyInvoices = useSelector((item: IState) => item.outsides.easyInvoices);
  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );
  const loading = useSelector((item: IState) => item.outsides.loading);
  const dataCredentials = useSelector(
    (item: IState) => item.outsides.dataCredentials,
  );
  const loadingInstallments = useSelector(
    (item: IState) => item?.outsides?.loadingInstallments,
  );
  const error = useSelector((item: IState) => item.outsides.error);

  const outsidesCredentials = useSelector(
    (item: IState) => item.outsidesCredentials.data,
  );
  const toggles = useSelector((item: IState) => item.toggles.data);

  const [cnp, setCnp] = useState<string>('');
  const [enrollment, setEnrollment] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [selectedInvoice, setSelectedInvoice] = useState<IEasyInvoices | null>(null);
  const [resetSelectedInvoice, setResetSelectedInvoice] = useState<boolean>(false);
  const [method, setMethod] = useState<'barCode' | 'pixCode' | 'creditCard'>(
    'barCode',
  );
  const [pages, setPages] = useState<Array<number>>([]);
  const [errorCnp, setErrorCnp] = useState<boolean>(false);
  const [errorCnpMessage, setErrorCnpMessage] = useState<string>('');
  const [errorRegistrationMessage, setErrorRegistrationMessage] =
    useState<string>('');
  const [typeList, setTypeList] = useState<'invoices' | 'others'>('invoices');
  const [rememberChecked, setRememberChecked] = useState<boolean>(true);
  const [loadingCopy, setLoadingCopy] = useState<boolean>(false);
  const singlePayment = true;
  const linksDefault: IDatePage[] = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.LANDING),
    },
    {
      label: 'Segunda via fácil',
      active: true,
      onClick: () => {
        dispatch(clearEasyDuplicateInvoices());
        setSelectedInvoice(null);
        setTypeList('invoices');
      },
    },
  ];
  const [links, setLinks] = useState<IDatePage[]>(linksDefault);
  const [, setEnableSubmit] = useState<boolean>(configsAppJson?.NODE_ENV !== 'PRD');
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState<string | null>(null);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const [paymentModule, setPaymentModule] = useState<boolean>(true);

  const [showRecaptcha, setShowRecaptcha] = useState(
    showRecaptchaV2(error || undefined),
  );

  useEffect(() => {
    setShowRecaptcha(showRecaptchaV2(error || undefined));
  }, [error]);

  const verifyToggles = async () => {
    setPaymentModule(await checkToggle(toggles, 'PAYMENT_MODULE'));
  };

  useEffect(() => {
    if (toggles && toggles.length > 0) verifyToggles();
  }, [toggles]);

  const recaptchaTokens = {
    recaptchaTokenV3: recaptchaTokenV3 || '',
    recaptchaTokenV2: recaptchaTokenV2 || '',
  };

  useEffect(() => {
    if (token) {
      dispatch(
        getEasyDuplicateInvoicesByToken(token, {
          page: 1,
          size: 5,
        }),
      );

      dispatch(getEasyInvoicesCredentialsByToken(token));
    }
  }, []);

  useEffect(() => {
    if (dataCredentials?.cnp && dataCredentials.enrollment) {
      setCnp(dataCredentials.cnp);
      setEnrollment(dataCredentials.enrollment);
    }
  }, [dataCredentials]);

  useEffect(() => {
    if (
      outsidesCredentials &&
      outsidesCredentials.cnp &&
      outsidesCredentials.enrollment &&
      outsidesCredentials.remember
    ) {
      setCnp((outsidesCredentials && outsidesCredentials.cnp) || '');
      setEnrollment((outsidesCredentials && outsidesCredentials.enrollment) || '');
    }

    setTypeList('invoices');

    if (dataHistory) {
      setCnp(dataHistory?.cnp);
      setEnrollment(dataHistory?.enrollment);

      if (dataHistory?.typeView === 'detail') {
        if (!paymentModule) setSelectedInvoice(dataHistory?.invoice);
        setResetSelectedInvoice(true);
      }
    } else {
      dispatch(clearEasyDuplicateInvoices());
      dispatch(clearDischargeDebts());
    }

    dispatch(getErrorCloseEasyDuplicateInvoices());
    dispatch(getErrorCloseDischargeDebts());
    dispatch(postErrorCloseOutsidesRegistrationUpdate());

    ReactGA.event({
      category: 'Faturas',
      action: 'Segunda via fácil',
    });
  }, []);

  useEffect(() => {
    if (
      (easyInvoices && easyInvoices?.invoices?.length > 0) ||
      (dataDischargeDebts && dataDischargeDebts?.dados?.length > 0)
    ) {
      let arrayLinks: IDatePage[] = [...linksDefault];
      arrayLinks[1].active = false;
      arrayLinks.push({
        label: 'Faturas simplificadas',
        active: true,
        onClick: () => setSelectedInvoice(null),
      });

      if (selectedInvoice) {
        arrayLinks[1].active = false;
        arrayLinks[2].active = false;

        arrayLinks.push({
          label:
            method === 'barCode' ? 'Pagamento com boleto bancário' : 'Pagamento PIX',
          active: true,
        });
      }
      setLinks(arrayLinks);
    } else {
      setLinks(linksDefault);
    }
  }, [easyInvoices, dataDischargeDebts, selectedInvoice, method]);

  useEffect(() => {
    if (!cnp || !enrollment) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [cnp, enrollment]);

  useEffect(() => {
    if (error && error.status === 404) {
      setErrorRegistrationMessage(
        `${
          formatter.formatarNumber(cnp)?.length === 11 ? 'CPF' : 'CNPJ'
        }/ Matrícula não encontrado, tente novamente`,
      );
      setErrorCnp(true);
    }
    if (error && error.status === 400) {
      setErrorRegistrationMessage('MATRÍCULA INVÁLIDA');
    }
  }, [error]);

  useEffect(() => {
    setErrorCnpMessage('');
    setErrorCnp(false);
  }, [cnp]);

  useEffect(() => {
    setErrorRegistrationMessage('');
    dispatch(getErrorCloseEasyDuplicateInvoices());
  }, [enrollment]);

  useEffect(() => {
    if (easyInvoices?.pagination && easyInvoices?.pagination?.maxPages > 1) {
      let arrayPages = [];
      for (let index = 1; index <= easyInvoices?.pagination?.maxPages; index++) {
        arrayPages.push(index);
      }

      setPages(arrayPages);
    }
  }, [easyInvoices]);

  useEffect(() => {
    if (
      (!easyInvoices || easyInvoices?.invoices?.length === 0) &&
      !loading &&
      dataDischargeDebts &&
      dataDischargeDebts?.dados?.length > 0
    ) {
      setTypeList('others');
    }
  }, [easyInvoices, loading, dataDischargeDebts]);

  const getInvoices = async () => {
    if (!cnp) {
      setErrorCnpMessage('Preenchimento obrigatório');
      setErrorCnp(true);
      return;
    }

    if (cnp) {
      const cnpIsvalid = checkCnp(cnp);

      if (!cnpIsvalid) {
        setErrorCnpMessage('CPF/CNPJ INVÁLIDO');
        setErrorCnp(true);
        return;
      }
    }

    if (!enrollment) {
      setErrorRegistrationMessage('Preenchimento obrigatório');
      return;
    }

    await dispatch(
      getEasyDuplicateInvoices({
        ...recaptchaTokens,
        cnp,
        enrollmentId: enrollment.split('-', 1)[0],
        params: {
          page: 1,
          size: 5,
        },
      }),
    );

    dispatch(
      saveEasyInvoicesCredentials({
        cnp,
        enrollment,
        remember: rememberChecked,
      }),
    );

    dispatch(getOutsidesRegistrationUpdateRequest(cnp));
  };

  return (
    <Main>
      <Container>
        <Recaptcha
          setRecaptchaTokenV3={setRecaptchaTokenV3}
          setRecaptchaTokenV2={setRecaptchaTokenV2}
          showRecaptchaV2={showRecaptcha}
          onClose={() => {
            dispatch(getErrorCloseEasyDuplicateInvoices());
            dispatch(getErrorCloseDischargeDebts());
            dispatch(postErrorCloseOutsidesRegistrationUpdate());
          }}
        />
        <Wrapper>
          {!isMobile && !isTablet && (
            <ContainerBreadCrumb>
              <BreadCrumb links={links} />
            </ContainerBreadCrumb>
          )}
          <Content>
            {loadingInstallments && <LoadingComponent labelWait="Carregando..." />}
            {selectedInvoice ||
            (dataHistory?.typeView === 'detail' && !resetSelectedInvoice) ? (
              <MyCard
                type="full"
                title={
                  method === 'barCode'
                    ? 'Pagamento boleto bancário'
                    : 'Pagamento PIX'
                }
              >
                <ContainerCardFull detailInvoice>
                  <Margin height={34} />
                  <InvoiceDetail
                    data={selectedInvoice || dataHistory?.invoice}
                    method={method}
                    cnp={cnp}
                    registration={enrollment}
                    typeList={typeList}
                    setLoadingCopy={setLoadingCopy}
                  />
                  <Margin height={44} />
                  <ContainerMethods>
                    <p>Selecione a forma de pagamento</p>
                    <Margin height={25} />
                    <div>
                      {selectedInvoice?.pix_code ? (
                        <>
                          <ButtonPayment
                            type="button"
                            onClick={() => {
                              if (method === 'pixCode' || loadingCopy) return;
                              ReactGA.event({
                                category: 'Pagamento',
                                action: 'Pagar 2ª via - PIX',
                              });
                              setMethod('pixCode');
                            }}
                            method={method === 'pixCode' || false}
                          >
                            <PixSVG v3 />
                            <Margin height={5} />
                            PIX
                            <Margin height={5} />
                          </ButtonPayment>
                          <Margin width={isMobile ? 8 : 24} />
                        </>
                      ) : null}
                      <ButtonPayment
                        type="button"
                        onClick={() => {
                          if (method === 'barCode' || loadingCopy) return;
                          ReactGA.event({
                            category: 'Pagamento',
                            action: 'Pagar 2ª via - Boleto',
                          });
                          setMethod('barCode');
                        }}
                        method={method === 'barCode' || false}
                      >
                        <Margin height={5} />
                        <BarCodeSVG width={38} heigth={30} />
                        <Margin height={5} />
                        Boleto
                      </ButtonPayment>
                      {singlePayment && typeList === 'invoices' && (
                        <>
                          <Margin width={isMobile ? 8 : 24} />
                          <ButtonPayment
                            type="button"
                            onClick={() => {
                              if (method === 'creditCard' || loadingCopy) return;

                              ReactGA.event({
                                category: 'Pagamento',
                                action:
                                  '[Ñ Logado][Pag. Única Fatura][WEB] Cartão de Crédito',
                              });

                              ReactGA.event({
                                category: 'Pagamento',
                                action:
                                  '[QD][C3][WEB] - Pg. de uma única dívida – Cartão de credito',
                              });

                              dispatch(
                                getOutsidesInvoicesInstallmentsRequest(
                                  cnp,
                                  selectedInvoice?.amount || 0,
                                  {
                                    typeView: 'detail',
                                    invoicesGroup: [
                                      {
                                        ...selectedInvoice,
                                      },
                                    ],
                                    totalAmount: selectedInvoice?.amount,
                                    cnp,
                                    enrollment,
                                    qr_code_key: selectedInvoice?.pix_code,
                                    bar_code_number:
                                      selectedInvoice?.bar_code_number,
                                  },
                                  RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENT,
                                ),
                              );
                            }}
                            method={method === 'creditCard' || false}
                          >
                            <Margin height={5} />
                            <CreditCardIcon />
                            <Margin height={5} />
                            Cartão de crédito
                          </ButtonPayment>
                        </>
                      )}
                    </div>
                  </ContainerMethods>
                  <Margin height={44} />
                  <ContainerWarningBox>
                    <WarningBox
                      v3
                      label="Ao realizar o pagamento de faturas vencidas, os juros e multa serão calculados e cobrados na sua próxima fatura."
                    />
                  </ContainerWarningBox>
                  <Margin height={44} />
                  <ContainerButtonBack>
                    <Button
                      type="button"
                      backgroundColor={Color.white}
                      color={Color.gray}
                      onClick={() => setSelectedInvoice(null)}
                    >
                      Voltar
                    </Button>
                  </ContainerButtonBack>
                  <Margin height={74} />
                </ContainerCardFull>
              </MyCard>
            ) : easyInvoices?.invoices ? (
              <>
                {loading && <LoadingComponent labelWait="Carregando..." />}
                <MyCard type="full" title="Faturas simplificadas">
                  <ContainerCardFull>
                    <Margin height={isMobile ? 28 : isTablet ? 34 : 26} />
                    {singlePayment && typeList === 'invoices' && (
                      <>
                        {easyInvoices?.pagination?.totalItens > 1 && (
                          <>
                            <ContainerSinglePayment
                              style={{ paddingLeft: !isTablet ? '24px' : undefined }}
                            >
                              <div>
                                <p>Você possui pendências!</p>
                                <p>
                                  Pague todas suas pendências em até 12X sem juros!
                                </p>
                              </div>
                              <PaymentButton
                                type="button"
                                onClick={() => {
                                  ReactGA.event({
                                    category: 'Pagamento Único',
                                    action:
                                      '[Ñ Logado][Pag. Único][WEB] Faturas - Pagamento Único',
                                  });

                                  ReactGA.event({
                                    category: 'Pagamento Único',
                                    action: '[QD][C3][WEB] – Pagamento único',
                                  });

                                  dispatch(
                                    getEasyDuplicateInvoices({
                                      ...recaptchaTokens,
                                      cnp,
                                      enrollmentId: enrollment.split('-', 1)[0],
                                      params: {
                                        page: 1,
                                        size: easyInvoices?.pagination.totalItens,
                                      },
                                      routeData: {
                                        invoices: easyInvoices?.invoices,
                                        typeView: 'group',
                                        enrollment,
                                        cnp,
                                      },
                                      paramsDischargeDebts: {
                                        page: 1,
                                        size: dataDischargeDebts
                                          ? dataDischargeDebts?.pagination
                                              ?.totalItens
                                          : 0,
                                      },
                                    }),
                                  );

                                  // dispatch(
                                  //   getEasyDuplicateInvoices(
                                  //     cnp,
                                  //     enrollment.split('-', 1)[0],
                                  //     {
                                  //       page: 1,
                                  //       size: easyInvoices?.pagination.totalItens,
                                  //     },
                                  //     {
                                  //       invoices: easyInvoices?.invoices,
                                  //       typeView: 'group',
                                  //       enrollment,
                                  //       cnp,
                                  //     },
                                  //     {
                                  //       page: 1,
                                  //       size: dataDischargeDebts
                                  //         ? dataDischargeDebts?.pagination
                                  //             ?.totalItens
                                  //         : 0,
                                  //     },
                                  //   ),
                                  // );
                                }}
                              >
                                Pagamento único
                                <PaymentIcon />
                              </PaymentButton>
                            </ContainerSinglePayment>
                            <Margin height={16} />
                          </>
                        )}
                      </>
                    )}
                    <ContainerButtonsList typeList={typeList}>
                      <button type="button" onClick={() => setTypeList('invoices')}>
                        Faturas
                      </button>
                      <button type="button" onClick={() => setTypeList('others')}>
                        Outros
                      </button>
                    </ContainerButtonsList>
                    {typeList === 'invoices' ? (
                      <>
                        {easyInvoices?.invoices?.length > 0 ? (
                          <>
                            <TextList>Faturas vinculadas à sua matrícula:</TextList>
                            {easyInvoices?.invoices?.map((invoice: any) => (
                              <ContaineInvoices key={invoice.invoice_id}>
                                <Margin height={16} />
                                <Invoice
                                  key={invoice.invoice_id}
                                  data={invoice}
                                  cnp={cnp}
                                  enrollment={enrollment}
                                  setSelectedInvoice={setSelectedInvoice}
                                  setMethod={setMethod}
                                  paymentModule={paymentModule}
                                />
                              </ContaineInvoices>
                            ))}
                          </>
                        ) : (
                          <>
                            <Margin height={40} />
                            <EmptyMessage textMessage="Matrícula sem faturas pendentes!" />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {dataDischargeDebts &&
                        dataDischargeDebts?.dados?.length > 0 ? (
                          <>
                            <TextList>
                              Parcelas de serviço, negociações e outras cobranças
                              vinculadas ao seu CPF/CNPJ:
                            </TextList>
                            {dataDischargeDebts?.dados?.map((invoice: any) => {
                              return (
                                <ContaineInvoices key={invoice.id}>
                                  <Margin height={16} />
                                  <InvoiceDischargeDebts
                                    key={invoice.id}
                                    data={invoice}
                                    cnp={cnp}
                                    enrollment={enrollment}
                                    setSelectedInvoice={setSelectedInvoice}
                                    setMethod={setMethod}
                                    paymentModule={paymentModule}
                                  />
                                </ContaineInvoices>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <Margin height={40} />
                            <EmptyMessage textMessage="CPF/CNPJ não possui dívidas em aberto" />
                          </>
                        )}
                      </>
                    )}
                    <Margin height={26} />
                    {easyInvoices?.pagination?.maxPages > 1 && (
                      <ContainerPagination>
                        <button
                          type="button"
                          onClick={() => {
                            if (easyInvoices?.pagination?.page === 1) return;
                            if (easyInvoices?.pagination?.page) {
                              if (token) {
                                dispatch(
                                  getEasyDuplicateInvoicesByToken(token, {
                                    page: easyInvoices?.pagination?.page - 1,
                                    size: 5,
                                  }),
                                );
                              } else {
                                dispatch(
                                  getEasyDuplicateInvoices({
                                    ...recaptchaTokens,
                                    cnp,
                                    enrollmentId: enrollment.split('-', 1)[0],
                                    params: {
                                      page: easyInvoices?.pagination?.page - 1,
                                      size: 5,
                                    },
                                  }),
                                );

                                // dispatch(
                                //   getEasyDuplicateInvoices(
                                //     cnp,
                                //     enrollment.split('-', 1)[0],
                                //     {
                                //       page: easyInvoices?.pagination?.page - 1,
                                //       size: 5,
                                //     },
                                //   ),
                                // );
                              }
                            }
                          }}
                          className="change-page"
                        >
                          <ArrowSVG />
                        </button>
                        <Margin width={16} />
                        <ContainerPageNumber>
                          {pages.map((page) => (
                            <PageNumber
                              key={page}
                              isActive={page === easyInvoices?.pagination?.page}
                              type="button"
                              onClick={() => {
                                if (easyInvoices?.pagination?.page === page) return;
                                if (easyInvoices?.pagination?.page) {
                                  if (token) {
                                    dispatch(
                                      getEasyDuplicateInvoicesByToken(token, {
                                        page,
                                        size: 5,
                                      }),
                                    );
                                  } else {
                                    dispatch(
                                      getEasyDuplicateInvoices({
                                        ...recaptchaTokens,
                                        cnp,
                                        enrollmentId: enrollment?.split('-', 1)[0],
                                        params: {
                                          page,
                                          size: 5,
                                        },
                                      }),
                                    );

                                    // dispatch(
                                    //   getEasyDuplicateInvoices(
                                    //     cnp,
                                    //     enrollment?.split('-', 1)[0],
                                    //     {
                                    //       page,
                                    //       size: 5,
                                    //     },
                                    //   ),
                                    // );
                                  }
                                }
                              }}
                            >
                              {page}
                            </PageNumber>
                          ))}
                        </ContainerPageNumber>
                        <Margin width={16} />
                        <button
                          type="button"
                          onClick={() => {
                            if (
                              easyInvoices?.pagination?.page ===
                              easyInvoices?.pagination?.maxPages
                            )
                              return;
                            if (easyInvoices?.pagination?.page) {
                              if (token) {
                                dispatch(
                                  getEasyDuplicateInvoicesByToken(token, {
                                    page: easyInvoices?.pagination?.page + 1,
                                    size: 5,
                                  }),
                                );
                              } else {
                                dispatch(
                                  getEasyDuplicateInvoices({
                                    ...recaptchaTokens,
                                    cnp,
                                    enrollmentId: enrollment?.split('-', 1)[0],
                                    params: {
                                      page: easyInvoices?.pagination?.page + 1,
                                      size: 5,
                                    },
                                  }),
                                );

                                // dispatch(
                                //   getEasyDuplicateInvoices(
                                //     cnp,
                                //     enrollment.split('-', 1)[0],
                                //     {
                                //       page: easyInvoices?.pagination?.page + 1,
                                //       size: 5,
                                //     },
                                //   ),
                                // );
                              }
                            }
                          }}
                          className="change-page"
                        >
                          <ArrowSVG />
                        </button>
                      </ContainerPagination>
                    )}
                    <Margin height={16} />
                    <ContainerButtonBack>
                      <Button
                        type="button"
                        color={Color.gray}
                        onClick={() => {
                          if (
                            !outsidesCredentials ||
                            !outsidesCredentials?.remember
                          ) {
                            setCnp('');
                            setEnrollment('');
                          }
                          setTypeList('invoices');
                          dispatch(clearEasyDuplicateInvoices());
                          dispatch(clearDischargeDebts());
                          setEnableSubmit(configsAppJson?.NODE_ENV !== 'PRD');
                        }}
                      >
                        Voltar
                      </Button>
                    </ContainerButtonBack>
                    <Margin height={56} />
                  </ContainerCardFull>
                </MyCard>
              </>
            ) : (
              <>
                {token && <LoadingComponent labelWait="Carregando..." />}
                <MyCard
                  title="SEGUNDA VIA FÁCIL"
                  subtitle="Digite o CPF/CNPJ do titular e número da matrícula para ter acesso direto a fatura de um imóvel."
                >
                  <Input
                    name="cnp"
                    label="CPF ou CNPJ*"
                    value={cnp}
                    onChange={(e) => setCnp(formatter.cpfCnpjMask(e.target.value))}
                    error={errorCnp}
                    errorText={errorCnpMessage}
                    inputMode="numeric"
                    borderColor={Color.blueIntense}
                  />
                  <Margin height={12} />
                  <Input
                    name="enrollment"
                    label="Matrícula*"
                    value={enrollment}
                    onChange={(e) =>
                      setEnrollment(formatter.enrollment(e.target.value))
                    }
                    error={!!errorRegistrationMessage}
                    errorText={errorRegistrationMessage}
                    inputMode="numeric"
                    borderColor={Color.blueIntense}
                  />
                  <ContainerForgotPassword>
                    <InputCheckedV3
                      checked={rememberChecked}
                      onChange={(ev) => setRememberChecked(ev.target.checked)}
                      label="Lembrar-me"
                    />
                  </ContainerForgotPassword>
                  {/* {configsAppJson?.NODE_ENV === 'PRD' && (
                    <>
                      <Margin height={24} />
                      <div
                        style={{
                          filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                          margin: 'auto',
                          width: 'min-content',
                        }}
                      >
                        <ReCAPTCHA
                          sitekey={
                            configsAppJson?.GOOGLE_RECAPTCHA[
                              configsAppJson?.NODE_ENV
                            ]
                          }
                          onErrored={() => setEnableSubmit(false)}
                          onExpired={() => setEnableSubmit(false)}
                          onChange={(j) => setEnableSubmit(!!j)}
                          size="normal"
                        />
                      </div>
                    </>
                  )} */}
                  <Margin height={isMobile ? 12 : 24} />
                  <Button
                    type="button"
                    loading={loading}
                    backgroundColor={disabled ? Color.grayLightSeconde : Color.green}
                    disabled={disabled}
                    onClick={getInvoices}
                    style={{
                      height: '48px',
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '17px',
                    }}
                  >
                    Continuar
                  </Button>
                  <Margin height={isMobile ? 0 : 60} />
                </MyCard>
                {!isMobile && (
                  <>
                    {!isTablet && <Divider />}
                    {isTablet && <Margin height={81} />}
                    <MyCard type="link">
                      <CardTitle style={{ textAlign: 'left' }}>
                        Serviços digi iguá
                      </CardTitle>
                      <CardSubtitle style={{ textAlign: 'left' }}>
                        Faça seu <Link to={RouteName.LOGIN}>login</Link> e tenha
                        acesso a muito mais!
                      </CardSubtitle>
                      <UsefulLinks />
                    </MyCard>
                  </>
                )}
              </>
            )}
          </Content>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default EasyDuplicate;
