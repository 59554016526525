import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { useDispatch } from 'react-redux';
import { activateModalSalesforceSatisfaction } from '~/store/modules/outsides/actions';
import PaymentReceipt from '~/components/PaymentReceipt';

const SinglePaymentDataPaymentReceipt: FC = () => {
  const dispatch = useDispatch();

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.HOME),
    },
    {
      label: 'Comprovante de pagamento',
      active: true,
    },
  ];

  useEffect(() => {
    ReactGA.event({
      category: 'Pagamento Único',
      action: '[Pg. Único] [WEB] Pagamento com Sucesso',
    });
    dispatch(activateModalSalesforceSatisfaction());
  }, []);

  return (
    <PaymentReceipt
      links={links}
      printGA={{
        category: 'Pagamento Único',
        action: '[Pag. Único][WEB] Baixar comprovante',
      }}
      loading={false}
      labelWait="Baixando..."
      navigateHomeRoute={RouteName.HOME}
    />
  );
};

export default SinglePaymentDataPaymentReceipt;
