import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from '~/store/modules/types';
import { getOrderServicesListRequest } from '~/store/modules/orderServices/actions';
import PaginationDefault from '~/components/Pagination';

const Pagination: FC = () => {
  const dispatch = useDispatch();

  const pagination = useSelector(
    (item: IState) => item.orderServices.data?.pagination,
  ) || { totalItens: 0, maxPages: 0, page: 0 };
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      getOrderServicesListRequest({
        page,
        size: 6,
      }),
    );
  }, [page]);

  return <PaginationDefault setPage={setPage} pagination={pagination} />;
};

export default Pagination;
