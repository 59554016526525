import React, { FC, ReactNode } from 'react';

import { DescriptionText, Main, TitleText } from './styles';
import { Button } from '../Button';
import { Dialog } from '../Dialog';
import {
  ModalDefaultIcon,
  ModalWarningIcon,
  ModalSuccessIcon,
  ModalErrorIcon,
} from './icons';
import { IEnumButtonTypeButton } from '../Button/enum';
import { IEnumModalType } from './enum';

interface IModalProps {
  type:
    | IEnumModalType.DEFAULT
    | IEnumModalType.WARNING
    | IEnumModalType.SUCCESS
    | IEnumModalType.ERROR;
  title?: string;
  description?: string | ReactNode;
  textButton?: string;
  loading?: boolean;
  onClick: () => void;
}

interface IDataTypeModal {
  icon: ReactNode;
  title: string;
  description: string | ReactNode;
  typeButton:
    | IEnumButtonTypeButton.DEFAULT
    | IEnumButtonTypeButton.WARNING
    | IEnumButtonTypeButton.SUCCESS
    | IEnumButtonTypeButton.ERROR;
  textButton: string;
}

const Modal: FC<IModalProps> = ({
  type,
  title,
  description = '',
  textButton = 'Entendi',
  loading,
  onClick,
}) => {
  const dataTypeModal: Record<IEnumModalType, IDataTypeModal> = {
    [IEnumModalType.DEFAULT]: {
      icon: <ModalDefaultIcon />,
      title: title || 'Atenção!',
      description,
      typeButton: IEnumButtonTypeButton.DEFAULT,
      textButton,
    },
    [IEnumModalType.WARNING]: {
      icon: <ModalWarningIcon />,
      title: title || 'Atenção!',
      description,
      typeButton: IEnumButtonTypeButton.WARNING,
      textButton,
    },
    [IEnumModalType.SUCCESS]: {
      icon: <ModalSuccessIcon />,
      title: title || 'Sucesso!',
      description,
      typeButton: IEnumButtonTypeButton.SUCCESS,
      textButton,
    },
    [IEnumModalType.ERROR]: {
      icon: <ModalErrorIcon />,
      title: title || 'Ocorreu um erro!',
      description,
      typeButton: IEnumButtonTypeButton.ERROR,
      textButton,
    },
  };

  return (
    <Dialog open fullScreen>
      <Main>
        <div>{dataTypeModal[type].icon}</div>
        <TitleText type={type}>{dataTypeModal[type].title}</TitleText>
        {dataTypeModal[type].description && (
          <DescriptionText>{dataTypeModal[type].description}</DescriptionText>
        )}
        <Button
          typeButton={dataTypeModal[type].typeButton}
          loading={loading}
          onClick={onClick}
        >
          {dataTypeModal[type].textButton}
        </Button>
      </Main>
    </Dialog>
  );
};

export default Modal;
