import styled from 'styled-components/';
import { colors } from '~/design_system/styles/colors';

export const Main = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.yellow_50};
  border-radius: 8px;
  padding: 16px;
  gap: 12px;
  display: flex;
`;

export const ContainerIcon = styled.span`
  max-height: 44px;
`;

export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: ${colors.grey_100};
  text-align: left;
`;

export const DescriptionText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: ${colors.grey_100};
`;

export const Button = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: ${colors.aqua_60};
  border: none;
  background: none;
  cursor: pointer;
`;
