import { IEnumErrorResponse } from '~/enums/errorsEnum';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';

export const showRecaptchaV2 = (error: IEnumErrorResponse | null) => {
  const showRecaptchaV2 =
    error?.type === EnumResponseCode.TOKENS_RECAPTCHA_MISSING ||
    error?.type === EnumResponseCode.TOKEN_V2_RECAPTCHA_MISSING ||
    error?.type === EnumResponseCode.TOKEN_V3_RECAPTCHA_MISSING ||
    error?.type === EnumResponseCode.TOKEN_V3_RECAPTCHA_INVALID ||
    error?.type === EnumResponseCode.TOKEN_V2_RECAPTCHA_INVALID ||
    error?.type === EnumResponseCode.RECAPTCHA_GENERIC ||
    error?.message === EnumResponseCode.TOKENS_RECAPTCHA_MISSING ||
    error?.message === EnumResponseCode.TOKEN_V2_RECAPTCHA_MISSING ||
    error?.message === EnumResponseCode.TOKEN_V3_RECAPTCHA_MISSING ||
    error?.message === EnumResponseCode.TOKEN_V3_RECAPTCHA_INVALID ||
    error?.message === EnumResponseCode.TOKEN_V2_RECAPTCHA_INVALID ||
    error?.message === EnumResponseCode.RECAPTCHA_GENERIC;

  return showRecaptchaV2;
};
