import formatter from '~/utils/formatter';
import { api } from './api';
import {
  IRequestCreateDebtsNegotiation,
  IRequestDebtsNegotiation,
  IRequestDebtsNegotiationEnrollments,
  IRequestDebtsNegotiationInstallments,
  IRequestDebtsNegotiationListDetail,
  IRequestDebtsNegotiationListNegotiations,
  IRequestDebtsNegotiationTerms,
} from '~/store/modules/negotiation/types';

const timeout = 90000;

function getDebtsNegotiationEnrollmentsService({
  cnp,
  filter,
  isAuthenticated,
}: IRequestDebtsNegotiationEnrollments) {
  return new Promise((resolve, reject) => {
    try {
      const getDebtsNegotiationEnrollments: any = async () => {
        const url = isAuthenticated
          ? 'debts-negotiation/enrollments'
          : 'outsides/debts-negotiation/enrollments';

        const urlFilter = filter
          ? `/${filter?.enrollment}/operation/${filter?.operationId}`
          : '';

        const response = await api.get(`v3/${url}${urlFilter}`, {
          headers: {
            'x-cnp': formatter.formatarNumber(cnp),
          },
        });

        return response.data;
      };

      resolve(getDebtsNegotiationEnrollments());
    } catch (error) {
      reject(error);
    }
  });
}

function getDebtsNegotiationService({
  cnp,
  isAuthenticated,
}: IRequestDebtsNegotiation) {
  return new Promise((resolve, reject) => {
    try {
      const getDebtsNegotiation: any = async () => {
        const url = isAuthenticated
          ? 'debts-negotiation'
          : 'outsides/debts-negotiation';

        const response = await api.get(`v3/${url}`, {
          headers: {
            'x-cnp': formatter.formatarNumber(cnp),
          },
        });

        return response.data;
      };

      resolve(getDebtsNegotiation());
    } catch (error) {
      reject(error);
    }
  });
}

function getDebtsNegotiationInstallmentsService({
  enrollment,
  operationId,
  params,
  isAuthenticated,
}: IRequestDebtsNegotiationInstallments) {
  return new Promise((resolve, reject) => {
    try {
      const getDebtsNegotiationInstallments: any = async () => {
        const url = isAuthenticated
          ? 'debts-negotiation/enrollments'
          : 'outsides/debts-negotiation/enrollments';

        const response = await api.get(
          `v3/${url}/${enrollment}/operation/${operationId}/installments`,
          {
            params,
          },
        );

        return response.data;
      };

      resolve(getDebtsNegotiationInstallments());
    } catch (error) {
      reject(error);
    }
  });
}

function getDebtsNegotiationTermsService({
  isAuthenticated,
  enrollment,
  operationId,
}: IRequestDebtsNegotiationTerms) {
  return new Promise((resolve, reject) => {
    try {
      const getDebtsNegotiationTerms: any = async () => {
        const url = isAuthenticated
          ? 'debts-negotiation/terms'
          : 'outsides/debts-negotiation/terms';

        const response = await api.get(
          `v3/${url}/enrollments/${enrollment}/operation/${operationId}`,
        );

        return response.data;
      };

      resolve(getDebtsNegotiationTerms());
    } catch (error) {
      reject(error);
    }
  });
}

function postDebtsNegotiationService({
  isAuthenticated,
  enrollment,
  operationId,
  campaignBillingId,
  downPaymentAmount,
  installmentsId,
}: IRequestCreateDebtsNegotiation) {
  return new Promise((resolve, reject) => {
    try {
      const postDebtsNegotiation: any = async () => {
        const url = isAuthenticated
          ? 'debts-negotiation'
          : 'outsides/debts-negotiation';

        const response = await api.post(`v3/${url}`, {
          enrollment,
          operationId,
          campaignBillingConditionId: campaignBillingId,
          downPaymentAmount,
          installmentsId,
        });

        return response.data;
      };

      resolve(postDebtsNegotiation());
    } catch (error) {
      reject(error);
    }
  });
}

function getDebtsNegotiationAuthService() {
  return new Promise((resolve, reject) => {
    try {
      const getDebtsNegotiation: any = async () => {
        const response = await api.get('v3/invoices/debts', {
          params: {
            page: 1,
            size: 1000,
          },
          timeout,
        });

        return response.data;
      };

      resolve(getDebtsNegotiation());
    } catch (error) {
      reject(error);
    }
  });
}

function getDebtsNegotiationListService(
  data: IRequestDebtsNegotiationListNegotiations,
) {
  return new Promise((resolve, reject) => {
    try {
      const getDebtsNegotiationList: any = async () => {
        const response = await api.get(`v3/debts-negotiation`, {
          timeout,
          params: { ...data },
        });

        return response.data;
      };

      resolve(getDebtsNegotiationList());
    } catch (error) {
      reject(error);
    }
  });
}

function getDebtsNegotiationListDetailService({
  id,
  operation,
  enrollment,
}: IRequestDebtsNegotiationListDetail) {
  return new Promise((resolve, reject) => {
    try {
      const getDebtsNegotiationListDetail: any = async () => {
        const response = await api.get(`v3/debts-negotiation/${id}`, {
          timeout,
          params: {
            operation,
            enrollment,
          },
        });

        return response.data;
      };

      resolve(getDebtsNegotiationListDetail());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getDebtsNegotiationEnrollmentsService,
  getDebtsNegotiationService,
  getDebtsNegotiationInstallmentsService,
  getDebtsNegotiationTermsService,
  postDebtsNegotiationService,
  getDebtsNegotiationAuthService,
  getDebtsNegotiationListService,
  getDebtsNegotiationListDetailService,
};
