import styled, { css } from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsContainerNotification {
  is_read?: boolean;
  read_view?: boolean;
}

export const Container = styled.div`
  min-width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    min-width: calc(488px - 32px);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h1`
  color: ${Color.blueIntense};
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
`;

export const EnrollmentText = styled.span`
  color: ${Color.blueSeconde};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 16px;
  border-bottom: 1px solid ${Color.grayLightSeconde};
  position: relative;
  height: 24px;
  display: flex;
  align-items: center;

  #border {
    width: 110px;
    height: 2px;
    background: ${Color.blueSeconde};
    position: absolute;
    bottom: -1px;
    left: 0;
    margin: 0;
    z-index: 9999;
  }
`;

export const ContainerNotification = styled.div`
  width: 100%;
  height: auto;
  max-height: 440px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    max-height: 660px;
  }
`;

export const CardNotification = styled.button<IPropsContainerNotification>`
  width: 100%;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  color: #02505b;
  border: none;
  background: none;
  border-bottom: ${({ read_view }) =>
    read_view ? 'none' : `1px solid ${Color.grayLightSeconde}`};
  cursor: pointer;
  background: ${({ is_read }) => (is_read ? Color.white : '#F8F9FA')};
  padding: 12px;
`;

export const StatusTopInfoNotification = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

export const StatusInfoNotification = styled.button<IPropsContainerNotification>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 10px;
  width: 41px;
  height: 24px;
  background: ${({ is_read }) => (is_read ? '#BCC3CA ' : Color.blueSeconde)};
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #ffffff;
`;

export const StatusInfoDateNotification = styled.p<IPropsContainerNotification>`
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #6a7279;

  ${({ read_view }) =>
    read_view &&
    css`
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    `}
`;

export const CardNotificationBody = styled.div`
  max-height: 356px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background: #ebeced;
  }

  ::-webkit-scrollbar-thumb {
    background: #a1a7ac;
    border-radius: 100px;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    max-height: 508px;
  }
`;

export const StatusBottomInfoNotification = styled.p<IPropsContainerNotification>`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #202022;
  text-align: left;

  ${({ read_view }) =>
    !read_view &&
    css`
      max-width: 280px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: ${Breakpoints.tablet.min}px) {
        max-width: 422px;
      }
    `}
`;

export const ContainerEmptyMessage = styled.div<IPropsContainerNotification>`
  margin: ${({ read_view }) => (read_view ? '14px 0px' : '28px auto 14px auto')};
  margin-inline-start: ${({ read_view }) =>
    read_view ? 'initial !important' : 'auto'};

  svg {
    width: 41px;
    height: 51px;
  }

  button {
    background: transparent;
    cursor: pointer;
  }
`;

export const BottomDescriptionText = styled.p`
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #6a7279;
  text-align: left;
  margin-top: 16px;
`;

export const ButtonBack = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  margin-top: 16px;
`;
