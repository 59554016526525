import React, { FC } from 'react';

import { DescriptionText, InfoImg } from './styles';
import questionInfoImg from './assets/question-info.png';

const InfoEnrollment: FC = () => {
  return (
    <>
      <DescriptionText>
        Digite a matrícula informada na sua conta para ter acesso ao histórico
        detalhado, parcelamento da fatura e muito mais!
      </DescriptionText>
      <DescriptionText>
        Caso ainda não possua nenhuma fatura entre em contato pelo{' '}
        <span>Fale conosco</span>.
      </DescriptionText>
      <InfoImg src={questionInfoImg} alt="Número da matrícula na sua fatura" />
    </>
  );
};

export default InfoEnrollment;
