import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { getErrorCloseEnrollmentsInvoicesDownload } from '~/store/modules/enrollmentsInvoices/actions';
import NegotiationPayment from '~/components/Negotation/NegotiationPayment';
import { IState } from '~/store/modules/types';

const DebtNegotiationSinglePaymentDataPayment: FC = () => {
  const dispatch = useDispatch();

  const token = useSelector((item: IState) => item.enrollmentsAuth.token);

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(token ? RouteName.HOME : RouteName.LANDING),
    },
    {
      label: 'Negociação de dívidas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DISCHARGEDEBTS),
    },
    {
      label: 'Dívidas em aberto',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DEBTNEGOTIATION),
    },
    {
      label: 'Pagamento único',
      active: false,
      onClick: () => Navigation.goBack(),
    },
    {
      label: 'Dados de pagamento',
      active: true,
    },
  ];

  useEffect(() => {
    dispatch(getErrorCloseEnrollmentsInvoicesDownload());

    ReactGA.event({
      category: 'Pagamento de fatura',
      action: '[AL][Web][Pagamento de Fatura]Escolher Pix',
    });
  }, []);

  if (token) delete links[1];

  return <NegotiationPayment links={links} />;
};

export default DebtNegotiationSinglePaymentDataPayment;
