import React from 'react';

const MessageDefaultIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
        fill="#32A2B4"
      />
      <g clipPath="url(#clip0_265_616)">
        <path
          d="M15 11H17V13H15V11ZM15 15H17V21H15V15ZM16 6C10.48 6 6 10.48 6 16C6 21.52 10.48 26 16 26C21.52 26 26 21.52 26 16C26 10.48 21.52 6 16 6ZM16 24C11.59 24 8 20.41 8 16C8 11.59 11.59 8 16 8C20.41 8 24 11.59 24 16C24 20.41 20.41 24 16 24Z"
          fill="#F5FCFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_265_616">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const MessageWarningIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
        fill="#FBC347"
      />
      <path
        d="M16 9.99L23.53 23H8.47L16 9.99ZM16 6L5 25H27L16 6ZM17 20H15V22H17V20ZM17 14H15V18H17V14Z"
        fill="#FFF9ED"
      />
    </svg>
  );
};

const MessageSuccessIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
        fill="#37B482"
      />
      <path
        d="M16 6C10.489 6 6 10.489 6 16C6 21.511 10.489 26 16 26C21.511 26 26 21.511 26 16C26 10.489 21.511 6 16 6ZM16 8C20.4301 8 24 11.5699 24 16C24 20.4301 20.4301 24 16 24C11.5699 24 8 20.4301 8 16C8 11.5699 11.5699 8 16 8ZM20.293 12.293L14 18.5859L11.707 16.293L10.293 17.707L14 21.4141L21.707 13.707L20.293 12.293Z"
        fill="white"
      />
    </svg>
  );
};

const MessageErrorIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
        fill="#E6735F"
      />
      <path
        d="M15 19H17V21H15V19ZM15 11H17V17H15V11ZM15.99 6C10.47 6 6 10.48 6 16C6 21.52 10.47 26 15.99 26C21.52 26 26 21.52 26 16C26 10.48 21.52 6 15.99 6ZM16 24C11.58 24 8 20.42 8 16C8 11.58 11.58 8 16 8C20.42 8 24 11.58 24 16C24 20.42 20.42 24 16 24Z"
        fill="#FEF5F4"
      />
    </svg>
  );
};

export {
  MessageDefaultIcon,
  MessageWarningIcon,
  MessageSuccessIcon,
  MessageErrorIcon,
};
