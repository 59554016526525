/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react';

import { Border, ContainerBorder, RowInfo, ContainerDetail } from '../styles';

import { Margin } from '~/components/Margin';
import formatter from '~/utils/formatter';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import { IPaymentVoucher } from '~/store/modules/payment/types';

const UnifiedInfo: FC<IPaymentVoucher> = ({ header, details }) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <>
      <RowInfo>
        <p className="capitalize">
          Cliente: &ensp;{header?.name ? header?.name?.toLowerCase() : '-'}
        </p>
      </RowInfo>
      <RowInfo backgroundWhite>
        <p>
          CPF: &ensp;
          {header?.cnp ? formatter.cpfCnpjMask(header?.cnp || '') : '-'}
        </p>
      </RowInfo>
      <RowInfo>
        <p>Endereço: &ensp;{header?.adrress || '-'}</p>
      </RowInfo>
      <RowInfo backgroundWhite />
      <RowInfo>
        <p>Valor total:</p>
        <p className="text-end">
          {header?.amount_total
            ? formatter.formatCurrency(header?.amount_total)
            : '-'}
        </p>
      </RowInfo>
      <RowInfo backgroundWhite>
        <p>Tipo/bandeira: &ensp;{header?.type || '-'}</p>
      </RowInfo>
      <RowInfo>
        <p>
          Pagamento: &ensp;
          {header?.received_date
            ? moment(header?.received_date).format('DD/MM/YYYY HH:mm')
            : '-'}
        </p>
      </RowInfo>
      <RowInfo backgroundWhite>
        <p>Autenticação de pagamento: {header?.payment_id || '-'}</p>
      </RowInfo>
      <RowInfo backgroundWhite />
      <Margin height={24} />
      <ContainerBorder>
        <Border />
        <h2>Detalhes</h2>
        <Border />
      </ContainerBorder>
      {details &&
        details?.map((detail) => (
          <ContainerDetail>
            <RowInfo key={detail?.invoice_id}>
              <p>Referência: &ensp;{detail?.reference || '-'}</p>
            </RowInfo>
            <RowInfo backgroundWhite>
              <p>Fatura: &ensp;{detail?.invoice_id || '-'}</p>
            </RowInfo>
            <RowInfo>
              <p>
                Vencimento: &ensp;
                {detail?.maturity_date
                  ? moment(detail?.maturity_date).format('DD/MM/YYYY')
                  : '-'}
              </p>
            </RowInfo>
            <RowInfo backgroundWhite>
              <p>
                Emissão: &ensp;
                {detail?.issue_date_hour
                  ? moment(detail?.issue_date_hour).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </p>
            </RowInfo>
            {detail.items?.map((item, index) => (
              <RowInfo key={item?.description}>
                <p>
                  {index === 0 ? (
                    'Descrição:  '
                  ) : (
                    <>
                      {!isMobile && (
                        <>
                          &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
                        </>
                      )}
                    </>
                  )}{' '}
                  {item?.description?.toLowerCase() || '-'}
                </p>
                <p className="text-end">
                  {item?.amount ? formatter.formatCurrency(item?.amount) : '-'}
                </p>
              </RowInfo>
            ))}
            <RowInfo backgroundWhite>
              <p>Valor:</p>
              <p>
                {detail?.amount ? formatter.formatCurrency(detail?.amount) : '-'}
              </p>
            </RowInfo>
            <RowInfo backgroundWhite />
          </ContainerDetail>
        ))}
    </>
  );
};

export default UnifiedInfo;
