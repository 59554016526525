import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import ArrowSVG from '~/assets/Icons/Arrow';
import {
  Container,
  ContainerBorder,
  ContainerMobile,
  ContentMobile,
  DetailButton,
  RowMobile,
  Text,
} from './styles';
import { Breakpoints } from '~/styles';
import { statusNegotiationColor } from '~/utils/negotiationStatus';
import { ContainerColumn } from '../styles';
import { IResponseDebtsNegotiationListNegotiations } from '~/store/modules/negotiation/types';
import formatter from '~/utils/formatter';
import { useDispatch } from 'react-redux';
import { getDebtsNegotiationListDetailRequest } from '~/store/modules/negotiation/actions';

interface IProps {
  data: IResponseDebtsNegotiationListNegotiations;
}

const ContainerNegotiation: FC<IProps> = ({ data }) => {
  const dispatch = useDispatch();

  const isLaptop = useMediaQuery({ minWidth: Breakpoints.laptop.min });

  const isCanceled = data?.status?.toUpperCase() === 'CANCELADO';

  const handleformatAmount = () => {
    return data.installments > 1
      ? `${data.installments}X ${formatter.formatCurrency(data.amount)}`
      : formatter.formatCurrency(data.amount);
  };

  const handleDetailNegotiation = () => {
    dispatch(
      getDebtsNegotiationListDetailRequest({
        id: data?.id,
        operation: data.operation,
        enrollment: data.enrollment,
      }),
    );
  };

  return isLaptop ? (
    <Container color={statusNegotiationColor(data?.status)} isCanceled={isCanceled}>
      <ContainerBorder color={statusNegotiationColor(data?.status)}>
        <span />
      </ContainerBorder>
      <ContainerColumn flexBasis={20}>
        <Text>{data?.id}</Text>
      </ContainerColumn>
      <ContainerColumn flexBasis={25}>
        <Text>{data?.type}</Text>
      </ContainerColumn>
      <ContainerColumn flexBasis={15}>
        <Text>{handleformatAmount()}</Text>
      </ContainerColumn>
      <ContainerColumn flexBasis={10} />
      <ContainerColumn flexBasis={30}>
        <Text>{data?.status}</Text>
        <DetailButton
          type="button"
          onClick={handleDetailNegotiation}
          isCanceled={isCanceled}
        >
          <ArrowSVG />
        </DetailButton>
      </ContainerColumn>
    </Container>
  ) : (
    <ContainerMobile
      color={statusNegotiationColor(data?.status)}
      isCanceled={isCanceled}
    >
      <ContainerBorder color={statusNegotiationColor(data?.status)}>
        <span />
      </ContainerBorder>
      <ContentMobile>
        <RowMobile>
          <Text>{data?.id}</Text>
          <Text>{data?.type} </Text>
        </RowMobile>
        <RowMobile>
          <Text>{handleformatAmount()}</Text>
        </RowMobile>
        <RowMobile>
          <Text>{data?.status}</Text>
          <DetailButton
            type="button"
            onClick={handleDetailNegotiation}
            isCanceled={isCanceled}
          >
            <ArrowSVG />
          </DetailButton>
        </RowMobile>
      </ContentMobile>
    </ContainerMobile>
  );
};

export default ContainerNegotiation;
