/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-const */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

import { Margin } from '~/components/Margin';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerAmount,
  ContainerHeader,
  ContainerInvoice,
} from './styles';
import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Breakpoints, Color } from '~/styles';
import { RouteName } from '~/routes/Routes.name';
import { history } from '~/store';
import { IEasyInvoices, IDadosDebts } from '~/store/modules/outsides/types';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import { statusColor } from '~/utils/invoiceStatus';
import { getOutsidesInvoicesInstallmentsRequest } from '~/store/modules/outsides/actions';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import WarningBox from '~/components/WarningBox';

interface IInvoiceGroup extends IEasyInvoices {
  active: boolean;
}

interface IInvoiceGroupDischargeDebts extends IDadosDebts {
  active: boolean;
}

const EasyDuplicateSinglePayment: FC = () => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.laptop.min });

  const invoices = history.location.state as any;

  const [invoicesGroup, setInvoicesGroup] = useState<Array<IInvoiceGroup>>([]);
  const [invoicesGroupDischargeDebts, setInvoicesGroupDischargeDebts] = useState<
    Array<IInvoiceGroupDischargeDebts>
  >([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [checkedAllDischargeDebts, setCheckedAllDischargeDebts] =
    useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const loadingInstallments = useSelector(
    (item: IState) => item?.outsides?.loadingInstallments,
  );

  useEffect(() => {
    let data: Array<IInvoiceGroup> = [];
    let dataDischargeDebts: Array<IInvoiceGroupDischargeDebts> = [];

    invoices?.invoices?.reverse()?.map((invoice: any) => {
      return data.push({
        ...invoice,
        active: true,
      });
    });

    invoices?.invoicesDischargeDebts?.reverse()?.map((invoice: any) => {
      return dataDischargeDebts.push({
        ...invoice,
        active: true,
      });
    });

    setInvoicesGroup(data);
    setInvoicesGroupDischargeDebts(dataDischargeDebts);
  }, []);

  const getActiveInvoices = () => {
    let data: Array<any> = [];
    let dataDischargeDebts: Array<any> = [];

    invoicesGroup?.map((invoice) => {
      if (invoice.active)
        return data.push({
          ...invoice,
        });

      return invoice;
    });

    invoicesGroupDischargeDebts?.map((invoice) => {
      if (invoice.active)
        return dataDischargeDebts.push({
          ...invoice,
        });

      return invoice;
    });

    return data.concat(dataDischargeDebts);
  };

  const addInvoices = () => {
    const amountInvoices = getActiveInvoices()?.reduce(
      (total: any, number: any) => total + number.amount,
      0,
    );

    setTotalAmount(amountInvoices);
  };

  useEffect(() => {
    if (invoicesGroup) {
      addInvoices();
      const invoiceInactive = invoicesGroup?.find((invoice) => !invoice.active);
      if (!invoiceInactive) setCheckedAll(true);
      else setCheckedAll(false);
    }

    if (invoicesGroupDischargeDebts) {
      addInvoices();
      const invoiceInactive = invoicesGroupDischargeDebts?.find(
        (invoice) => !invoice.active,
      );
      if (!invoiceInactive) setCheckedAllDischargeDebts(true);
      else setCheckedAllDischargeDebts(false);
    }
  }, [invoicesGroup, invoicesGroupDischargeDebts]);

  const handleCheck = (listType: 'invoices' | 'others') => {
    if (listType === 'invoices') {
      let data: Array<IInvoiceGroup> = [];
      invoices?.invoices?.map((invoice: any) => {
        return data.push({
          ...invoice,
          active: !checkedAll,
        });
      });
      setCheckedAll(!checkedAll);
      setInvoicesGroup(data);
    } else {
      let data: Array<IInvoiceGroupDischargeDebts> = [];
      invoices?.invoicesDischargeDebts?.map((invoice: any) => {
        return data.push({
          ...invoice,
          active: !checkedAllDischargeDebts,
        });
      });
      setCheckedAll(!checkedAllDischargeDebts);
      setInvoicesGroupDischargeDebts(data);
    }
  };

  const verifyActiveInvoice = () => {
    if (
      invoicesGroupDischargeDebts?.filter((invoice) => invoice.active).length > 0
    ) {
      const lengthInvoicesGroup = invoicesGroup?.filter(
        (invoice) => invoice.active,
      ).length;
      const lengthInvoicesGroupDischargeDebts = invoicesGroupDischargeDebts?.filter(
        (invoice) => invoice.active,
      ).length;
      return lengthInvoicesGroup + lengthInvoicesGroupDischargeDebts > 1;
    }

    return invoicesGroup?.filter((invoice) => invoice.active).length > 1;
  };

  return (
    <Main>
      {loadingInstallments && <LoadingComponent labelWait="Carregando..." />}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.LANDING),
            },
            {
              label: 'Pagamento único',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <h1>Pagamento único</h1>
        {invoicesGroup && invoicesGroup.length > 1 && (
          <>
            <Margin height={34} />
            <ContainerBorder>
              <Border />
              <h2>Faturas</h2>
              <Border />
            </ContainerBorder>
            <h3>Selecione e pague de forma agrupada:</h3>
            <ContainerHeader>
              <div>
                <label className="container">
                  <input
                    type="checkbox"
                    name="checkAll"
                    onChange={() => handleCheck('invoices')}
                    checked={checkedAll}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <p>Mês</p>
              {!isMobile && <p>Vencimento</p>}
              <p>Valor</p>
              <p>Referência</p>
            </ContainerHeader>
            {invoicesGroup &&
              invoicesGroup?.map((invoice, index) => (
                <ContainerInvoice
                  key={invoice.invoice_id}
                  color={statusColor(invoice?.status?.label, true)}
                >
                  <div>
                    <span />
                    <label className="container">
                      <input
                        type="checkbox"
                        name={`check${invoice.invoice_id}`}
                        onChange={() => {
                          let data: Array<IInvoiceGroup> = [...invoicesGroup];
                          data[index].active = !invoice?.active;
                          setCheckedAll(invoice?.active ? checkedAll : false);
                          setInvoicesGroup(data);
                        }}
                        checked={invoice.active}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <p className="status">
                    {invoice?.status?.label} <br /> <span>{invoice?.reference}</span>
                  </p>
                  {!isMobile && (
                    <p>{moment(invoice?.maturity_date).format('DD/MM/YYYY')}</p>
                  )}
                  <p>{formatter.formatCurrency(invoice?.amount)}</p>
                  <p>{invoices?.enrollment}</p>
                </ContainerInvoice>
              ))}
          </>
        )}
        {invoicesGroupDischargeDebts && invoicesGroupDischargeDebts.length > 1 && (
          <>
            <Margin height={34} />
            <ContainerBorder>
              <Border />
              <h2>Outros</h2>
              <Border />
            </ContainerBorder>
            <h3>
              Parcelas de serviço, negociações e outras cobranças vinculadas ao seu
              CPF/CNPJ:
            </h3>
            <ContainerHeader>
              <div>
                <label className="container">
                  <input
                    type="checkbox"
                    name="checkAll"
                    onChange={() => handleCheck('others')}
                    checked={checkedAllDischargeDebts}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <p>Tipo</p>
              {!isMobile && <p>Vencimento</p>}
              <p>Valor</p>
              <p>Referência</p>
            </ContainerHeader>
            {invoicesGroupDischargeDebts &&
              invoicesGroupDischargeDebts?.map((invoice, index) => (
                <ContainerInvoice
                  key={invoice.id}
                  color={statusColor(invoice?.status?.label, true)}
                >
                  <div>
                    <span />
                    <label className="container">
                      <input
                        type="checkbox"
                        name={`check${invoice.id}`}
                        onChange={() => {
                          let data: Array<IInvoiceGroupDischargeDebts> = [
                            ...invoicesGroupDischargeDebts,
                          ];
                          data[index].active = !invoice?.active;
                          setCheckedAllDischargeDebts(
                            invoice?.active ? checkedAllDischargeDebts : false,
                          );
                          setInvoicesGroupDischargeDebts(data);
                        }}
                        checked={invoice.active}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <p className="status">
                    {invoice?.status?.label} <br /> <span>{invoice?.reference}</span>
                  </p>
                  {!isMobile && (
                    <p>{moment(invoice?.maturity_date).format('DD/MM/YYYY')}</p>
                  )}
                  <p>{formatter.formatCurrency(invoice?.amount)}</p>
                  <p>{invoices?.enrollment}</p>
                </ContainerInvoice>
              ))}
          </>
        )}
        <ContainerAmount>
          <p>Valor total: {formatter.formatCurrency(totalAmount)}</p>
        </ContainerAmount>
        <Margin height={34} />
        <WarningBox label="Ao realizar o pagamento de faturas vencidas, os juros e multa serão calculados e cobrados na sua próxima fatura." />
        <Margin height={10} />
        <ContainerButtons>
          <ButtonText
            text="Voltar"
            typeBtn="content"
            onClick={() =>
              Navigation.navigate(RouteName.EASYDUPLICATE, {
                typeView: 'group',
                enrollment: invoices?.enrollment,
                cnp: invoices?.cnp,
              })
            }
          />
          <ButtonText
            typeBtn="content"
            backgroundColor={verifyActiveInvoice() ? Color.blueSeconde : Color.gray}
            onClick={() => {
              if (!verifyActiveInvoice()) return;

              ReactGA.event({
                category: 'Pagamento Único',
                action: '[Ñ Logado][Pag. Único][WEB] Ir para pagamento',
              });

              ReactGA.event({
                category: 'Pagamento Único',
                action: '[QD][C3][WEB] – Ir para pagamento',
              });

              let data: Array<any> = [];

              getActiveInvoices()?.map((invoice: any) => {
                return data.push({
                  ...invoice,
                  invoice_id: invoice?.invoice_id || invoice?.id,
                });
              });

              dispatch(
                getOutsidesInvoicesInstallmentsRequest(
                  invoices?.cnp || '',
                  totalAmount || 0,
                  {
                    invoices: [...invoices?.invoices],
                    invoicesDischargeDebts: [...invoices?.invoicesDischargeDebts],
                    invoicesGroup: [...data],
                    totalAmount,
                    enrollment: invoices?.enrollment,
                    cnp: invoices?.cnp,
                  },
                  RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENT,
                ),
              );
            }}
            nextIcon
          >
            Ir para pagamento
          </ButtonText>
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default EasyDuplicateSinglePayment;
