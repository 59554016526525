import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import formatter from '~/utils/formatter';
import { checkCnp } from '~/utils/validation';

import { RouteName } from '../../routes/Routes.name';
import { IState } from '~/store/modules/types';

import {
  Main,
  TextRegisterStyled,
  ContainerForgotPassword,
  ContainerDivisor,
  TextLinkRegister,
} from './styles';

import {
  getEnrollmentsAuthCloseRequest,
  getEnrollmentsAuthRequest,
} from '~/store/modules/enrollmentsAuth/actions';
import {
  getEnrollmentsUsersObfuscatedRequest,
  getEnrollmentsUsersRequestClear,
  updateEnrollmentsCreateClientRequest,
} from '~/store/modules/enrollmentsUsers/actions';
import { saveLoginCredentials } from '~/store/modules/enrollmentsLoginCredentials/actions';
import { Recaptcha } from '~/components/Recaptcha';
import { showRecaptchaV2 } from '~/utils/showRecaptcha';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';
import { CardForm } from '~/design_system/components/CardForm';
import Input from '~/design_system/components/Input';
import { Button } from '~/design_system/components/Button';
import { Divisor } from '~/design_system/components/Divisor';
import InputChecked from '~/design_system/components/InputChecked';
import Message from '~/design_system/components/Message';
import { IEnumMessageType } from '~/design_system/components/Message/enum';
import Modal from '~/design_system/components/Modal';
import { IEnumModalType } from '~/design_system/components/Modal/enum';

const LoginVersionTwo: React.FC = () => {
  const dispatch = useDispatch();

  const cnpInputRef = useRef<HTMLInputElement>(null);

  const loginValues = useSelector(
    (item: IState) => item.enrollmentsLoginCredentials.data,
  );

  const loading = useSelector((item: IState) => item.enrollmentsAuth.loading);
  const error = useSelector((item: IState) => item.enrollmentsAuth.error);
  const loadingObfuscated = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingObfuscated,
  );

  const [cnp, setCnp] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [rememberChecked, setRememberChecked] = useState<boolean>(true);
  const [cnpError, setCnpError] = useState<string>('');
  const [isNewClient, setIsNewClient] = useState<boolean>(false);
  const [openPasswordExpiration, setOpenPasswordExpiration] =
    useState<boolean>(false);
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState<string | null>(null);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false);

  const hasCnpRoute = cnpError === 'Usuário ou senha inválidos';
  const state = { cnp: hasCnpRoute ? cnp : '' };

  useEffect(() => {
    if (error) {
      if (
        [
          EnumResponseCode.TOKENS_RECAPTCHA_MISSING,
          EnumResponseCode.TOKEN_V3_RECAPTCHA_MISSING,
          EnumResponseCode.TOKEN_V3_RECAPTCHA_INVALID,
          EnumResponseCode.TOKEN_V2_RECAPTCHA_INVALID,
          EnumResponseCode.TOKEN_V2_RECAPTCHA_MISSING,
          EnumResponseCode.RECAPTCHA_GENERIC,
        ].includes(error.type as string)
      ) {
        setShowRecaptcha(true);
      } else if (error?.type === EnumResponseCode.USER_NOT_REGISTERED) {
        setIsNewClient(true);
      } else if (
        error &&
        error.type === EnumResponseCode.USER_NOT_FOUND_IN_PARTNES
      ) {
        setIsNewClient(true);
      } else if (error && error.type === EnumResponseCode.EXPIRED_PASSWORD) {
        setOpenPasswordExpiration(true);
      } else {
        setCnpError('Usuário ou senha inválidos');
      }
    } else {
      setCnpError('');
      setShowRecaptcha(false);
    }
  }, [error]);

  useEffect(() => {
    dispatch(getEnrollmentsAuthCloseRequest());
    setIsNewClient(false);
    setOpenPasswordExpiration(false);
    dispatch(updateEnrollmentsCreateClientRequest());
    setCnp(
      (loginValues &&
        loginValues.remember &&
        loginValues.cnp &&
        loginValues.password &&
        loginValues.cnp) ||
        '',
    );
    setPassword(
      (loginValues &&
        loginValues.remember &&
        loginValues.cnp &&
        loginValues.password &&
        loginValues.password) ||
        '',
    );

    cnpInputRef?.current?.focus();
  }, []);

  useEffect(() => {
    const cnpIsvalid = checkCnp(cnp);

    if (cnp && !cnpIsvalid) {
      setCnpError('CPF ou CNPJ inválido');
    } else {
      setCnpError('');
    }
  }, [cnp]);

  useEffect(() => {
    setCnpError('');
  }, [password]);

  useEffect(() => {
    if (!cnp || !password || cnpError) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [cnp, password, cnpError]);

  const validateCnp = () => {
    if (!cnp) return setCnpError('');

    const cnpIsvalid = checkCnp(cnp);

    if (!cnpIsvalid) {
      return setCnpError('CPF ou CNPJ inválido');
    }

    return setCnpError('');
  };

  const handleNavigatePasswordExpiration = () => {
    ReactGA.event({
      category: 'Expiração de senha',
      action: '[Expiração de Senha][WEB] Redefinir Minha Senha',
    });
    dispatch(getEnrollmentsUsersObfuscatedRequest(cnp));
  };

  return (
    <Main>
      {openPasswordExpiration && (
        <Modal
          type={IEnumModalType.DEFAULT}
          title="Sua senha expirou!"
          description="Para seguir, cadastre uma nova senha."
          loading={loadingObfuscated}
          onClick={handleNavigatePasswordExpiration}
        />
      )}
      <CardForm
        title="Faça seu login!"
        description="Acesse nossos serviços de forma fácil e tenha uma experiência personalizada!"
        iconType="login"
        maxWidth={411}
      >
        {isNewClient && (
          <Message
            type={IEnumMessageType.WARNING}
            title="Alerta"
            messageText={
              <>
                Olá cliente! Identificamos que é o seu primeiro acesso, faça{' '}
                <TextLinkRegister to={RouteName.REGISTERPROFILE}>
                  seu cadastro
                </TextLinkRegister>
                !
              </>
            }
          />
        )}
        <Input
          value={cnp}
          onChange={(ev) => setCnp(formatter.cpfCnpjMask(ev.target.value))}
          onBlur={validateCnp}
          label="CPF ou CNPJ"
          placeholder="Digite CPF ou CNPJ"
          name="cnp"
          ref={cnpInputRef}
          error={cnpError === 'USER_NOT_REGISTERED' ? false : !!cnpError}
          errorText={cnpError === 'USER_NOT_REGISTERED' ? '' : cnpError}
        />
        <Input
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
          label="Senha"
          placeholder="Digite a senha"
          name="password"
          type={!passwordVisible ? 'password' : 'text'}
          iconPassword
          passwordVisible={passwordVisible}
          setPasswordVisible={setPasswordVisible}
        />
        <ContainerForgotPassword>
          <InputChecked
            checked={rememberChecked}
            onChange={(ev) => setRememberChecked(ev.target.checked)}
            label="Lembrar-me"
          />
          <Link
            to={{
              pathname: RouteName.FORGOTPASSWORD,
              state,
            }}
          >
            Esqueci minha senha
          </Link>
        </ContainerForgotPassword>
        <Recaptcha
          setRecaptchaTokenV3={setRecaptchaTokenV3}
          setRecaptchaTokenV2={setRecaptchaTokenV2}
          showRecaptchaV2={showRecaptcha}
          onClose={() => {
            dispatch(getEnrollmentsAuthCloseRequest());
          }}
        />
        <Button
          onClick={() => {
            dispatch(getEnrollmentsUsersRequestClear());
            dispatch(
              getEnrollmentsAuthRequest({
                cnp,
                password,
                recaptchaTokenV3: recaptchaTokenV3 || '',
                recaptchaTokenV2: recaptchaTokenV2 || '',
              }),
            );
            dispatch(
              saveLoginCredentials({ remember: rememberChecked, cnp, password }),
            );
          }}
          loading={loading}
          disabled={disabled}
          id="login-button"
        >
          Entrar
        </Button>
        <ContainerDivisor>
          <Divisor />
        </ContainerDivisor>
        <TextRegisterStyled>
          Primeiro acesso? &nbsp;
          <Link
            to={{
              pathname: RouteName.REGISTERPROFILE,
              state,
            }}
          >
            Cadastre-se aqui
          </Link>
        </TextRegisterStyled>
      </CardForm>
    </Main>
  );
};

export default LoginVersionTwo;
