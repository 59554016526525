import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';

export const Main = styled.main`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 12px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 56px;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
