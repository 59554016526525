import styled from 'styled-components';

import { Color } from '../../../styles/colors';
import { Breakpoints } from '~/styles';

export const Main = styled.div`
  width: calc(100% - 86px);
  max-width: calc(400px - 66px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 17px;
  background: ${Color.white};
  padding: 32px;
  border-radius: 16px;
  border: 1px solid ${Color.yellow};
  gap: 16px;

  svg {
    width: 64px;
    height: 64px;
  }

  h1 {
    color: ${Color.yellow};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  p {
    color: ${Color.blackSeconde};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    button {
      color: ${Color.blueSeconde};
      text-decoration-line: underline;
      border: none;
      background: none;
      cursor: pointer;
      font-weight: 500;
    }
  }

  #ok-modal-aviso {
    border-radius: 8px;
    background: ${Color.yellow};
    height: 48px;
    color: ${Color.white};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
  }
`;

export const ContainerInfoDoublePayment = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;

export const InfoDoublePayment = styled.div`
  width: 100%;
  border: 1px solid ${Color.yellow};
  background: #fff8e8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 24px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${Color.blackSeconde};
`;
