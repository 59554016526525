import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const DescriptionText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #6a7279;
  margin-top: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

export const LinkText = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 122.5%;
  letter-spacing: -0.025em;
  color: #32a2b4;
`;
