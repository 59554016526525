import styled, { css } from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface IPropsCard {
  isBorder?: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 28px 10px 68px 10px;
  position: relative;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 28px 24px 207px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
    max-width: 1170px;
    padding: 28px 0 296px 20px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding-left: 20px;
  }

  @media (min-width: 1340px) {
    padding-left: 0;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1170px;
  margin-bottom: 28px;
`;

export const Card = styled.section<IPropsCard>`
  width: calc(100% - 32px);
  background: #ffffff;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  padding: 64px 16px 40px 16px;
  margin: 0 auto;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 77px);
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 62px);
    max-width: calc(870px - 80px);
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    max-width: calc(900px - 170px);
    padding: 64px 80px 40px 60px;
    margin: 0;
  }

  ${({ isBorder }) =>
    isBorder &&
    css`
      width: calc(100% - 32px);
      padding: 32px 16px 40px 16px;
      border-top: 16px solid ${Color.blueIntense};
      border-radius: 24px 24px 0px 0px;
    `}
`;

export const CardTitle = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #004b55;
`;

export const EnrollmentText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #202022;
`;

export const AddressText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #6a7279;
`;
