import React, { FC, useEffect } from 'react';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { getEnrollmentsInvoicesDetailRequest } from '~/store/modules/enrollmentsInvoices/actions';
import PaymentReceipt from '~/components/PaymentReceipt';
import {
  activateModalDataUpdate,
  activateModalSalesforceSatisfaction,
} from '~/store/modules/outsides/actions';

const SimplifiedInvoicePaymentsReceipt: FC = () => {
  const dispatch = useDispatch();

  const dataRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.dataRegistrationUpdate,
  );
  const invoiceReceipt = useSelector(
    (item: IState) => item.enrollmentsPayment.paymentVoucher,
  );
  const loadingDetailInvoice = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingDetail,
  );

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.HOME),
    },
    {
      label: 'Faturas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.INVOICES),
    },
    {
      label: 'Fatura completa',
      active: false,
      onClick: () =>
        dispatch(
          getEnrollmentsInvoicesDetailRequest(invoiceReceipt?.invoice_id || 0),
        ),
    },
    {
      label: 'Pagamento de fatura',
      active: true,
    },
    {
      label: 'Comprovante de pagamento',
      active: true,
    },
  ];

  useEffect(() => {
    dataRegistrationUpdate?.isUpdatable
      ? dispatch(activateModalDataUpdate())
      : dispatch(activateModalSalesforceSatisfaction());
  }, []);

  return (
    <PaymentReceipt
      links={links}
      printGA={{
        category: 'Pagamento de fatura',
        action: '[AL][Web][Fatura Detalhada][Pgto Cartão]Baixar Comprovante',
      }}
      loading={loadingDetailInvoice}
      labelWait={loadingDetailInvoice ? 'Carregando...' : 'Baixando...'}
      navigateHomeRoute={RouteName.HOME}
    />
  );
};

export default SimplifiedInvoicePaymentsReceipt;
