export const contactMock = {
  urlMaskWhatsapp: '{domain}{phone}?text={message}',
  operations: [
    {
      name: 'Alta  Floresta - MT',
      phone: '5565992976149',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Andradina - SP',
      phone: '5518996374603',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Canarana - MT',
      phone: '5565999728983',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Castilho - SP',
      phone: '5518996095213',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Colider - MT',
      phone: '5565996914602',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Comodoro - MT',
      phone: '5565992408214',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Cuiabá - MT',
      phone: '5565992766008',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Itapoa - SC',
      phone: '5547992780310',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Miguel Pereira - RJ',
      phone: '5508004000511',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Palestina - SP',
      phone: '5517981345564',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Paranagua - PR',
      phone: '5541992445030',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Paty do Alferes - RJ',
      phone: '5508004000510',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Piquete - SP',
      phone: '5511998094959',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Pontes  Lacerda - MT',
      phone: '5565992350558',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Rio de Janeiro - RJ',
      phone: '5508004000509',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
    {
      name: 'Sanessol - SP',
      phone: '5508007740195',
      message: 'Preciso de Ajuda',
      domain: 'https://wa.me/',
    },
  ],
};
