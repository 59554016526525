import { IEnumErrorResponse } from '~/enums/errorsEnum';

export enum RepositoriesTypesAuth {
  ENROLLMENTS_AUTH_METHOD_REQUEST = '@repositories/ENROLLMENTS_AUTH_METHOD_REQUEST',
  ENROLLMENTS_AUTH_METHOD_SUCCESS = '@repositories/ENROLLMENTS_AUTH_METHOD_SUCCESS',
  ENROLLMENTS_AUTH_METHOD_FAILURE = '@repositories/ENROLLMENTS_AUTH_METHOD_FAILURE',
  ENROLLMENTS_AUTH_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_AUTH_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_GET_SESSIONS_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_SESSIONS_METHOD_REQUEST',
  ENROLLMENTS_GET_SESSIONS_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_SESSIONS_METHOD_SUCCESS',
  ENROLLMENTS_GET_SESSIONS_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_SESSIONS_METHOD_FAILURE',

  ENROLLMENTS_SESSIONS_FIRST_ACCESS = '@repositories/ENROLLMENTS_SESSIONS_FIRST_ACCESS',
  ENROLLMENTS_SESSIONS_FIRST_ACCESS_CLEAR = '@repositories/ENROLLMENTS_SESSIONS_FIRST_ACCESS_CLEAR',

  ENROLLMENTS_GET_ME_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_ME_METHOD_REQUEST',
  ENROLLMENTS_GET_ME_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_ME_METHOD_SUCCESS',
  ENROLLMENTS_GET_ME_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_ME_METHOD_FAILURE',

  ENROLLMENTS_DESTROY_SESSION_REQUEST = '@repositories/ENROLLMENTS_DESTROY_SESSION_REQUEST',

  ENROLLMENTS_REGISTRATION_CHECK_REQUEST = '@repositories/ENROLLMENTS_REGISTRATION_CHECK_REQUEST',

  ENROLLMENTS_AUTH_ACTIVATE_MODAL = '@repositories/ENROLLMENTS_AUTH_ACTIVATE_MODAL',
  ENROLLMENTS_AUTH_CLEAR_MODAL = '@repositories/ENROLLMENTS_AUTH_CLEAR_MODAL',
  ENROLLMENTS_SET_AUTH_ROUTE_REDIRECT = '@repositories/ENROLLMENTS_SET_AUTH_ROUTE_REDIRECT',
  ENROLLMENTS_AUTH_ROUTE_REDIRECT_CLEAR = '@repositories/ENROLLMENTS_AUTH_ROUTE_REDIRECT_CLEAR',

  ENROLLMENTS_NOT_EXISTS_ACTIVATE_MODAL = '@repositories/ENROLLMENTS_NOT_EXISTS_ACTIVATE_MODAL',
  ENROLLMENTS_NOT_EXISTS_CLEAR_MODAL = '@repositories/ENROLLMENTS_NOT_EXISTS_CLEAR_MODAL',

  ENROLLMENTS_UPDATE_CURRENT_PASSWORD = '@repositories/ENROLLMENTS_UPDATE_CURRENT_PASSWORD',

  WATER_SUPPLY_INTERRUPTED_CLEAR = '@repositories/WATER_SUPPLY_INTERRUPTED_CLEAR',
}

export interface IRequestEnrollmentsSessions {
  cnp: string;
  password: string;
  recaptchaTokenV3: string;
  recaptchaTokenV2: string;
}

export interface IResponseEnrollmentsSessions {
  message?: string;
  token: string;
}

export interface IResponseEnrollmentsMe {
  code: string;
  enrollment: string;
  type_invoice: {
    id: number;
    label: string;
  } | null;
  consumer: string;
  water_connection_status: boolean | null;
  sewer_connection_status: string | null;
  address_connection: string;
  operation: {
    id: number;
    name: string;
  };
  invoices_pendants:
    | [
        {
          reference: string;
          data_reference: string;
          due_date: string;
          amount: number;
          status: {
            id: number;
            label: string;
          };
        },
      ]
    | [];
}

export interface IRegistrationCheck {
  code: string;
  enrollment: string;
  type_invoice: {
    id: number;
    label: string;
  } | null;
  consumer: string;
  water_connection_status: boolean | null;
  sewer_connection_status: string | null;
  address_connection: string;
  operation: {
    id: number;
    name: string;
    company_name: string;
    cnp: string;
  };
  invoices_pendants:
    | [
        {
          reference: string;
          data_reference: string;
          due_date: string;
          amount: number;
          status: {
            id: number;
            label: string;
          };
        },
      ]
    | [];
  eletronic_exists: boolean;
  water_supply_interrupted: boolean;
  water_supply_interrupted_registered_event?: boolean;
}

export interface IRepositoriesStateEnrollmentsAuth {
  readonly token: string;
  readonly IDSN: string;
  readonly password: string;
  readonly registrations: IResponseEnrollmentsMe[] | [];
  readonly registrationCheck: IRegistrationCheck | null;
  readonly firstAccess: boolean;
  readonly error: IEnumErrorResponse | null;
  readonly loading: boolean;
  readonly isClient?: boolean;
  readonly isManager?: boolean;
  readonly isEntrepreneur?: boolean;
  readonly modalAuth: boolean;
  readonly modalEnrollmentNotExists: boolean;
  readonly routeAuthRedirect: string;
}
