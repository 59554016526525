/* eslint-disable react/no-unescaped-entities */
import React, { FC, useState } from 'react';
import ReactGA from 'react-ga4';

import {
  ContainerTerms,
  BoxTerm,
  BoxTermAccept,
  CloseIconButton,
  Main,
  BoxTermAcceptButton,
} from './styles';

import { Margin } from '~/components/Margin';
import CloseModalSVG from '~/assets/Icons/CloseModal';
import InputCheckedV3 from '~/common/InputCheckedV3';
import { useDispatch, useSelector } from 'react-redux';
import { postDebtsNegotiationRequest } from '~/store/modules/negotiation/actions';
import formatter from '~/utils/formatter';
import { IState } from '~/store/modules/types';
import { IResponseDataDebtsNegotiationInstallmentsInstallment } from '~/store/modules/negotiation/types';
import { history } from '~/store';
import { IDebtNegotiationSimulationHistory } from '..';
import { ArrowIcon } from '../Icons';

interface IProps {
  onClose: () => void;
  installmentSelected:
    | IResponseDataDebtsNegotiationInstallmentsInstallment
    | undefined;
  entryValue: number;
}

const ModalTerm: FC<IProps> = ({ onClose, installmentSelected, entryValue }) => {
  const dataHistory = history.location.state as IDebtNegotiationSimulationHistory;

  const dispatch = useDispatch();

  const data = useSelector(
    (item: IState) => item?.negotiation?.dataDebtsNegotiationTerms,
  );
  const enrollmentCheck = useSelector(
    (item: IState) => item?.negotiation?.enrollmentCheck,
  );
  const [acceptedTerm, setAcceptedTerm] = useState<boolean>(false);

  const handleContinue = () => {
    if (!acceptedTerm) return;

    onClose();

    ReactGA.event({
      category: 'Negociação de dívidas',
      action:
        '[Negociação de dívidas][Termo de aceite] Continuar – Efetivação da negociação.',
    });

    dispatch(
      postDebtsNegotiationRequest({
        enrollment: enrollmentCheck?.enrollment || '',
        operationId: enrollmentCheck?.operationId || 0,
        type: 'INSTALLMENTS',
        campaignBillingId:
          dataHistory?.campaignPaymentConditions?.campaignPaymentConditionId || 0,
        downPaymentAmount: entryValue,
        installmentsId: installmentSelected?.id || '',
      }),
    );
  };

  const setSpaces = () => {
    return (
      <>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </>
    );
  };

  const handleSetCurrency = (value?: string) => {
    return value ? formatter.formatCurrency(Number(value)) : '';
  };

  return (
    <Main isAcceptedTerm={acceptedTerm}>
      <CloseIconButton onClick={onClose} type="button">
        <CloseModalSVG width={14} height={14} isBold />
      </CloseIconButton>
      <h3>Termo de aceite</h3>
      <p>Para efetivar a negociação, leia com atenção os termos a seguir:</p>
      <Margin height={16} />
      <BoxTerm>
        <p>Distrito Operacional: {data?.operationalDistrict}</p>
        <p>Matrícula: {data?.registrationNumber}</p>
        <p>Cliente: {data?.residentClientName}</p>
        <p> Proprietário: {data?.propertyOwnerName}</p>
        <p> Logradouro: {data?.address?.street}</p>
        <p> Município...: {data?.address?.city}</p>
        <p> CEP............: {data?.address?.zipCode}</p>
        <p> Número......: {data?.address?.houseNumber}</p>
        <p>Localização: {data?.address?.location}</p>
        <p>Telefone......: {data?.residentPhoneNumber}</p>
        <p>
          O(A) Sr(a).{data?.requester}, adiante assinado, na qualidade de cliente
          do(a) {data?.companyName}, reconhece dever à mencionada concessionária, o
          débito cujo valor histórico encontra-se demonstrado no "item 2", referente
          ao consumo de água e coleta de esgotos sanitários e prestação de serviços.
        </p>
        <p>1 - OBRIGAÇÕES DO CLIENTE</p>
        <p>
          Através do presente Termo, o Cliente assume o compromisso de cumprir as
          obrigações na forma, valores e prazos especificados, no "item 4", efetuando
          o pagamento das parcelas referentes ao débito em questão.
        </p>
        <p>2 - VALOR DO DÉBITO</p>
        <p>
          Valor Total das Faturas {setSpaces()}
          {handleSetCurrency(data?.debtAmount?.invoiceTotal)}
        </p>
        <p>
          Valor Total do Débito {setSpaces()}{' '}
          {handleSetCurrency(data?.debtAmount?.debtTotal)}
        </p>
        <p>3 - DESCONTO</p>
        <p>
          Valor Total dos Descontos {setSpaces()}{' '}
          {handleSetCurrency(data?.debtAmount?.discountAmount)}
        </p>
        <p>
          Valor do Desconto {setSpaces()}{' '}
          {handleSetCurrency(data?.debtAmount?.discountValue)}
        </p>
        <p>4 - PARCELAMENTO</p>
        <p>
          O parcelamento do valor total de débito ocorrerá conforme demonstrado a
          seguir:
        </p>
        <p>3.1 Valor da Parcela à Vista (R$)</p>
        <p>{formatter.formatCurrency(installmentSelected?.installmentAmount)}</p>
        <p>3.2 Número de Parcelas</p>
        <p>{installmentSelected?.installmentCount}</p>
        <p>3.3 Valor da Parcela (R$)</p>
        <p>{formatter.formatCurrency(installmentSelected?.installmentAmount)}</p>
        <p>3.4 Valor da Última Parcela (R$)</p>
        <p>{formatter.formatCurrency(installmentSelected?.lastInstallmentAmount)}</p>
        <br />
        <p>5 - DATA DE VENCIMENTO DAS PARCELAS</p>
        <p>
          Os valores das parcelas descritas no item 03 deste "Termo" serão incluídas
          em faturas subsequentes e o pagamento de cada parcela está vinculado ao
          pagamento da fatura que contiver a respectiva parcela.
        </p>
        <p>6 - PENALIDADES</p>
        <p>
          Ocorrendo atraso no pagamento de uma parcela, objeto deste instrumento,
          serão aplicadas as seguintes penalidades: a) O Cliente concorda e autoriza,
          que ocorrendo a não quitação de qualquer parcela do débito mencionado, dará
          a esta empresa o direito a "SUSPENSÃO" do fornecimento de água "SEM AVISO
          DE CORTE"; b) A não quitação da parcela a vista do parcelamento após um dia
          útil, contados a partir da data de assinatura do Termo de Acordo e
          Confissão de Dívidas, ensejará o imediato cancelamento do parcelamento e
          impedirá o usuário de realizar um novo acordo futuro.
        </p>
      </BoxTerm>
      <Margin height={10} />
      <BoxTermAccept>
        <ContainerTerms>
          <InputCheckedV3
            checked={acceptedTerm}
            onChange={(e) => setAcceptedTerm(e.target.checked)}
          >
            <p className="term">Li e aceito o termo de responsabilidade.</p>
          </InputCheckedV3>
        </ContainerTerms>
        <BoxTermAcceptButton
          type="button"
          onClick={handleContinue}
          acceptedTerm={acceptedTerm}
        >
          Continuar
          <ArrowIcon color={acceptedTerm ? '#212529' : '#FFF'} />
        </BoxTermAcceptButton>
      </BoxTermAccept>
    </Main>
  );
};

export default ModalTerm;
