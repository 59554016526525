import React, {
  FC,
  useState,
  ChangeEvent,
  useRef,
  useEffect,
  InputHTMLAttributes,
  ReactNode,
} from 'react';
import {
  ContainerAutocompleteStyled,
  AutocompleteStyled,
  Dropdown,
  LabelRow,
  ContainerSvg,
  LabelError,
} from './styles';
import PolygonSVG from '~/assets/Icons/Polygon';

interface Option {
  value: string;
  label: string | ReactNode;
}

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode;
  options: Option[];
  error?: boolean;
  errorText?: string;
  onSelectOption: (option: Option | null) => void;
  disabledInput?: boolean;
}

const Autocomplete: FC<IProps> = ({
  label,
  options,
  error = false,
  errorText,
  onSelectOption,
  onChange,
  disabledInput,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabledInput) return;

    const value = e?.target?.value;
    onChange && onChange(e);
    setIsOpen(true);
    filterOptions(value);
  };

  const handleOptionClick = (option: Option) => {
    onSelectOption(option);
    setIsOpen(false);
  };

  const filterOptions = (value: string) => {
    const filtered =
      options &&
      options?.filter((option) =>
        String(option?.label)?.toLowerCase().includes(value?.toLowerCase()),
      );
    setFilteredOptions(filtered);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  return (
    <ContainerAutocompleteStyled ref={inputRef}>
      {label && <LabelRow>{label}</LabelRow>}
      <AutocompleteStyled
        {...props}
        type="text"
        onChange={handleInputChange}
        onFocus={() => setIsOpen(true)}
        onBlur={handleInputBlur}
        error={error}
        disabledInput={disabledInput}
      />
      <ContainerSvg error={error}>
        <PolygonSVG />
      </ContainerSvg>
      {isOpen && filteredOptions.length > 0 && (
        <Dropdown>
          {filteredOptions?.map((option) => (
            <button
              key={option?.value}
              className="cursor-pointer p-2 hover:bg-gray-200 text-[14px] font-normal text-ds-black text-left capitalize"
              onClick={() => handleOptionClick(option)}
              type="button"
            >
              {/* {option?.label?.toLocaleLowerCase()} */}
              {option?.label}
            </button>
          ))}
        </Dropdown>
      )}
      {error && errorText && <LabelError>{errorText}</LabelError>}
    </ContainerAutocompleteStyled>
  );
};

export default Autocomplete;
