import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Container = styled.div`
  @media (min-width: ${Breakpoints.laptop.min}px) {
    border-radius: 24px;
  }
`;

export const Content = styled.div`
  position: relative;
`;

export const Img = styled.img`
  width: 100%;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 271px;
    height: 468px;
    border-radius: 24px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    width: 302px;
    height: 604px;
  }
`;

export const ButtonLink = styled.a`
  width: 80px;
  height: 30px;
  border-radius: 5px;
  position: absolute;
  bottom: 20px;

  img {
    width: 85px;
    height: 30px;
  }

  :first-of-type {
    right: 125px;
  }

  :last-of-type {
    right: 16px;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    bottom: 26px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    bottom: 30px;

    :first-of-type {
      right: 129px;
    }

    :last-of-type {
      right: 20px;
    }
  }
`;
