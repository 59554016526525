export enum RepositoriesTypesPayment {
  POST_PAYMENT_METHOD_REQUEST = '@repositories/POST_PAYMENT_METHOD_REQUEST',
  POST_PAYMENT_METHOD_SUCCESS = '@repositories/POST_PAYMENT_METHOD_SUCCESS',
  POST_PAYMENT_METHOD_NEGOTIATION_SUCCESS = '@repositories/POST_PAYMENT_METHOD_NEGOTIATION_SUCCESS',
  POST_PAYMENT_METHOD_FAILURE = '@repositories/POST_PAYMENT_METHOD_FAILURE',
  POST_PAYMENT_METHOD_FAILURE_CLOSE = '@repositories/POST_PAYMENT_METHOD_FAILURE_CLOSE',

  GET_PAYMENT_VOUCHER_METHOD_REQUEST = '@repositories/GET_PAYMENT_VOUCHER_METHOD_REQUEST',
  GET_PAYMENT_VOUCHER_METHOD_SUCCESS = '@repositories/GET_PAYMENT_VOUCHER_METHOD_SUCCESS',
  GET_PAYMENT_VOUCHER_METHOD_FAILURE = '@repositories/GET_PAYMENT_VOUCHER_METHOD_FAILURE',
  GET_PAYMENT_VOUCHER_METHOD_FAILURE_CLOSE = '@repositories/GET_PAYMENT_VOUCHER_METHOD_FAILURE_CLOSE',
}

interface IEventGa {
  category: string;
  action: string;
}

export interface IPaymentInvoices {
  id: Array<string>;
  enrollmentId: string;
}
export interface IPaymentCharges {
  id: Array<string>;
  operation: string;
}
export interface IPaymentDebtNegotiation {
  id: string;
  operation: string;
}

export interface IPaymentCard {
  paymentCardNumber: string;
  paymentCardHolder: string;
  paymentExpirationDate: string;
  paymentSecurityCode: number;
  paymentBrand: string;
  installments: number;
}

export interface IRequestPayment {
  invoices?: Array<IPaymentInvoices>;
  charges?: Array<IPaymentCharges>;
  debtNegotiation?: IPaymentDebtNegotiation;
  payment: IPaymentCard;
  dataSucessEventGA?: IEventGa;
  routeInvoiceReceipt: string;
  isAuthenticated: boolean;
  isNegotiation: boolean;
}

export interface IResponsePaymentMessage {
  code: string;
  message: string;
}

export interface IResponsePayment {
  message: IResponsePaymentMessage;
  groupId: number;
}

export interface IRequestIPaymentVoucherServiceQueryParam {
  groupId?: number;
  invoices?: {
    enrollmentId: any;
    invoceId: any;
  };
  charges?: {
    chargeId: any;
    operation: any;
  };
}
export interface IRequestIPaymentVoucherService {
  isAuthenticated: boolean;
  queryParam: IRequestIPaymentVoucherServiceQueryParam;
}

export interface IRequestIPaymentVoucher extends IRequestIPaymentVoucherService {
  routeInvoiceReceipt: string;
}

export interface IPaymentVoucherHeader {
  name: string;
  cnp: string;
  adrress: string;
  amount_total: number;
  payment_id: string;
  received_date: string;
  type: string;
}

export interface IPaymentVoucherItem {
  description: string;
  amount: number;
}
export interface IPaymentVoucherDetail {
  reference: string;
  invoice_id: number;
  maturity_date: string;
  issue_date_hour: string;
  amount: number;
  items: IPaymentVoucherItem[];
}

export interface IPaymentVoucher {
  header: IPaymentVoucherHeader;
  details: IPaymentVoucherDetail[];
}

export interface IRepositoriesStatePayment {
  readonly paymentVoucher: IPaymentVoucher | null;
  readonly successNegotation: boolean;
  readonly error: {
    message: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
  readonly errorVoucher: {
    message: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingVoucher: boolean;
}
