import React, { FC } from 'react';
import { CardStyled, DescriptionText, TitleText } from './styles';
import LoginSVG from '~/assets/Icons/Login';

interface ICardFormProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
  iconType?: 'login';
  maxWidth?: number;
  doubleGap?: boolean;
}

const icons = {
  login: <LoginSVG />,
};

export const CardForm: FC<ICardFormProps> = ({
  children,
  title,
  description,
  iconType,
  maxWidth,
  doubleGap,
}) => {
  return (
    <CardStyled maxWidth={maxWidth} doubleGap={doubleGap}>
      {title && (
        <TitleText>
          {iconType && <div>{icons[iconType]}</div>}
          {title}
        </TitleText>
      )}
      {description && <DescriptionText>{description}</DescriptionText>}
      {children}
    </CardStyled>
  );
};
