import styled, { css } from 'styled-components';
import { colors } from '~/design_system/styles/colors';

interface InputWrapProps {
  isFilled: boolean;
  isFocused: boolean;
  isError: boolean;
}

interface IInputTokenError {
  error?: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InputWrap = styled.div<InputWrapProps>`
  width: 100%;
  display: flex;
  gap: 8px;

  input {
    border: 1px solid ${colors.grey_40};
  }

  ${({ isFilled }) =>
    isFilled &&
    css`
      input {
        border: 1px solid ${colors.green_50};
      }
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      input {
        border: 1px solid ${colors.aqua_60};
      }
    `}

${({ isError }) =>
    isError &&
    css`
      input {
        border: 1px solid ${colors.red_50};
      }
    `}
`;

export const MyInputToken = styled.input<IInputTokenError>`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  gap: 10px;
  background: ${colors.white};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.grey_100};
  text-align: center;

  :focus-visible {
    outline: none;
  }

  :disabled {
    background: ${colors.grey_20};
    border: 1px solid ${colors.grey_40};
  }
`;

export const HelperMessage = styled.div<IInputTokenError>`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-align: center;
  color: ${({ error }) => (error ? colors.red_80 : colors.grey_50)};
`;

export const Button = styled.button`
  width: fit-content;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: ${colors.aqua_60};
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 auto;
`;
