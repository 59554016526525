import styled from 'styled-components/';
import { Breakpoints, Color } from '~/styles';

export const Main = styled.div`
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  padding: 18px 24px;
  flex: 1;
  border: 2px solid ${Color.yellow};
  border-radius: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 122.5%;
  text-align: left;
  background: #f2f2f2;

  @media screen and (max-width: ${Breakpoints.mobile.max}px) {
    flex-direction: column;
    max-height: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    flex-direction: row;
  }
`;

export const TitleText = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 122.5%;
  letter-spacing: -0.025em;
  color: #fab419;

  text-align: center;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    text-align: left;
  }
`;

export const ContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DescriptionText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 122.5%;
  letter-spacing: -0.025em;
  color: #000000;
  text-align: center;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    text-align: left;
  }
`;

export const Button = styled.button`
  padding: 12px 16px;
  gap: 12px;
  width: 108px;
  height: 44px;
  background: #37b482;
  border-radius: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
`;
