/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-const */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowSVG from '~/assets/Icons/Arrow';
import { IState } from '~/store/modules/types';
import { ContainerPagination, ContainerPageNumber, PageNumber } from './styles';
import { Margin } from '~/components/Margin';
import { getDebtsNegotiationListRequest } from '~/store/modules/negotiation/actions';

const Pagination: FC = () => {
  const dispatch = useDispatch();

  const pagination = useSelector(
    (item: IState) => item?.negotiation?.dataDebtsNegotiationList?.pagination,
  );
  const [pages, setPages] = useState<Array<number>>([]);

  useEffect(() => {
    if (pagination && pagination.maxPages > 1) {
      let arrayPages: any = [];
      for (let index = 1; index <= pagination.maxPages; index++) {
        arrayPages.push(index);
      }

      setPages(arrayPages);
    }
  }, [pagination]);

  return (
    <ContainerPagination>
      <button
        type="button"
        onClick={() => {
          if (pagination?.page === 1) return;
          if (pagination && pagination?.page) {
            dispatch(
              getDebtsNegotiationListRequest({
                page: pagination?.page - 1,
                size: 6,
              }),
            );
          }
        }}
        className="change-page"
      >
        <ArrowSVG />
      </button>
      <Margin width={16} />
      <ContainerPageNumber>
        {pages.map((page) => (
          <PageNumber
            key={page}
            isActive={page === pagination?.page}
            type="button"
            onClick={() => {
              if (pagination?.page === page) return;
              if (pagination?.page) {
                dispatch(
                  getDebtsNegotiationListRequest({
                    page,
                    size: 6,
                  }),
                );
              }
            }}
          >
            {page}
          </PageNumber>
        ))}
      </ContainerPageNumber>
      <Margin width={16} />
      <button
        type="button"
        onClick={() => {
          if (pagination?.page === pagination?.maxPages) return;
          if (pagination?.page) {
            dispatch(
              getDebtsNegotiationListRequest({
                page: pagination?.page + 1,
                size: 6,
              }),
            );
          }
        }}
        className="change-page"
      >
        <ArrowSVG />
      </button>
    </ContainerPagination>
  );
};

export default Pagination;
