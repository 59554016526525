import styled from 'styled-components';
import { Breakpoints } from '~/styles';
import { Color } from '~/styles/colors';

export const Main = styled.div<{ isAcceptedTerm: boolean }>`
  width: 100%;
  max-width: 530px;
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: ${Color.black};
  }

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: ${Color.grayDark};
  }

  .button-green {
    width: 100%;
    height: 32.18px;
    background: ${({ isAcceptedTerm }) =>
      isAcceptedTerm ? Color.green : Color.gray};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    align-self: end;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    .button-green {
      max-width: 200px;
    }
  }
`;

export const CloseIconButton = styled.button`
  width: 32px;
  height: 32px;
  background: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16px;
  right: 17px;
  cursor: pointer;
  border: 2px solid ${Color.grayLightSeconde};
  color: ${Color.grayLightSeconde};
  font-size: 16px;
  font-weight: 500;
  border-radius: 100px;
  z-index: 9999;
`;

export const ContainerTerms = styled.div`
  width: 100%;
  display: flex;

  .term {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 122.5%;
    color: ${Color.blueIntense};
    display: inline-block;
    letter-spacing: -0.49px;

    button {
      background: none;
      border: none;
      cursor: pointer;
      font-weight: 500;
      text-decoration: underline;
      color: ${Color.blueSeconde};
      text-align: left;
    }
  }
`;

export const BoxTerm = styled.div`
  max-width: calc(530px - 20px);
  height: calc(277px - 12px);
  border: 1px solid ${Color.gray};
  text-align: left;
  overflow-y: scroll;
  padding: 0 14px 0 9px;

  p,
  b {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${Color.blackSeconde};
  }

  b {
    font-weight: 700;
  }

  p {
    margin-bottom: 4px;
  }
`;

export const BoxTermAccept = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 36px;
`;

export const BoxTermAcceptButton = styled.button<{ acceptedTerm: boolean }>`
  width: 194px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background: ${({ acceptedTerm }) => (acceptedTerm ? '#37b4c8' : '#C7C7C7')};
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: ${({ acceptedTerm }) => (acceptedTerm ? '#1f1f1f' : '#FFF')};
  margin-left: auto;
  cursor: pointer;
`;
