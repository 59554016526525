import React, { FC } from 'react';
import moment from 'moment';
import { ContentRow, Text } from '../styles';
import WarningBox from '~/components/WarningBox';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';

interface IInstallmentDetails {
  lastInstallmentAmount: any;
  totalAmountWithDiscount: any;
  installmentCount: any;
  negotiationDate: any;
  registration: any;
  dueDate: any;
  installmentAmount: any;
  downPaymentAmount: any;
  totalAmount: any;
}

const InstallmentDetails: FC = () => {
  const dataDebtsNegotiationDetail = useSelector(
    (item: IState) => item?.negotiation?.dataDebtsNegotiationDetail,
  );

  const data: IInstallmentDetails = {
    lastInstallmentAmount:
      dataDebtsNegotiationDetail?.dataOpenNegotiation?.summary
        ?.lastInstallmentAmount ||
      dataDebtsNegotiationDetail?.dataDetailNegotiation?.lastInstallmentAmount,
    totalAmountWithDiscount:
      dataDebtsNegotiationDetail?.dataOpenNegotiation?.summary
        ?.totalAmountWithDiscount ||
      dataDebtsNegotiationDetail?.dataDetailNegotiation?.totalAmountWithDiscount,
    installmentCount:
      dataDebtsNegotiationDetail?.dataOpenNegotiation?.summary?.installmentCount ||
      dataDebtsNegotiationDetail?.dataDetailNegotiation?.installmentCount,
    negotiationDate:
      dataDebtsNegotiationDetail?.dataOpenNegotiation?.summary?.negotiationDate ||
      dataDebtsNegotiationDetail?.dataDetailNegotiation?.negotiationDate,
    registration:
      dataDebtsNegotiationDetail?.dataOpenNegotiation?.summary?.registration ||
      dataDebtsNegotiationDetail?.dataDetailNegotiation?.registration,
    dueDate:
      dataDebtsNegotiationDetail?.dataOpenNegotiation?.summary?.dueDate ||
      dataDebtsNegotiationDetail?.dataDetailNegotiation?.dueDate,
    installmentAmount:
      dataDebtsNegotiationDetail?.dataOpenNegotiation?.summary?.installmentAmount ||
      dataDebtsNegotiationDetail?.dataDetailNegotiation?.installmentAmount,
    downPaymentAmount:
      dataDebtsNegotiationDetail?.dataOpenNegotiation?.summary?.downPaymentAmount ||
      dataDebtsNegotiationDetail?.dataDetailNegotiation?.downPaymentAmount,
    totalAmount:
      dataDebtsNegotiationDetail?.dataOpenNegotiation?.summary?.totalAmount ||
      dataDebtsNegotiationDetail?.dataDetailNegotiation?.totalAmount,
  };

  return (
    <>
      <WarningBox label="A negociação só será efetivada após o pagamento da entrada." />
      <ContentRow />
      <ContentRow isWhite>
        <Text>Tipo de negociação:</Text>
        <Text>Parcelamento</Text>
      </ContentRow>
      <ContentRow>
        <Text>Valor da última parcela:</Text>
        <Text>{formatter.formatCurrency(data?.lastInstallmentAmount)}</Text>
      </ContentRow>
      <ContentRow isWhite>
        <Text>Valor total com desconto:</Text>
        <Text>{formatter.formatCurrency(data?.totalAmountWithDiscount)}</Text>
      </ContentRow>
      <ContentRow>
        <Text>Número total de parcelas:</Text>
        <Text>
          <Text>{data?.installmentCount}</Text>
        </Text>
      </ContentRow>
      <ContentRow isWhite>
        <Text>Data da negociação:</Text>
        <Text>{moment(data?.negotiationDate).format('DD/MM/YYYY')}</Text>
      </ContentRow>
      <ContentRow>
        <Text>Matrícula de origem:</Text>
        <Text>{data?.registration}</Text>
      </ContentRow>
      <ContentRow isWhite>
        <Text>Data do vencimento:</Text>
        <Text>{moment(data?.dueDate).format('DD/MM/YYYY')}</Text>
      </ContentRow>
      <ContentRow>
        <Text>Valor total parcelado:</Text>
        <Text>{formatter.formatCurrency(data?.installmentAmount)}</Text>
      </ContentRow>
      <ContentRow isWhite>
        <Text>Valor de entrada:</Text>
        <Text> {formatter.formatCurrency(data?.downPaymentAmount)}</Text>
      </ContentRow>
      <ContentRow>
        <Text>Valor total:</Text>
        <Text>{formatter.formatCurrency(data?.totalAmount)}</Text>
      </ContentRow>
    </>
  );
};

export default InstallmentDetails;
