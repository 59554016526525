import React from 'react';

const ModalDefaultIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#37B4C8" />
      <path
        d="M32 16C23.2 16 16 23.2 16 32C16 40.8 23.2 48 32 48C40.8 48 48 40.8 48 32C48 23.2 40.8 16 32 16Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 38.4004L32 30.4004"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0088 25.6001L31.9944 25.6001"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ModalWarningIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#FAB419" />
      <path
        d="M32 27.2001V35.2001"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0001 47.056H22.3041C16.7521 47.056 14.4321 43.088 17.1201 38.24L22.1121 29.248L26.8161 20.8C29.6641 15.664 34.3361 15.664 37.1841 20.8L41.8881 29.264L46.8801 38.256C49.5681 43.104 47.2321 47.072 41.6961 47.072H32.0001V47.056Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.9912 40H32.0056"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ModalSuccessIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#37B482" />
      <path
        d="M32 48C40.8 48 48 40.8 48 32C48 23.2 40.8 16 32 16C23.2 16 16 23.2 16 32C16 40.8 23.2 48 32 48Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2002 32L29.7282 36.528L38.8002 27.472"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ModalErrorIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#E6735F" />
      <path
        d="M19.9438 43.8769C23.1413 47.0744 27.4781 48.8707 32 48.8707C36.5219 48.8707 40.8587 47.0744 44.0562 43.8769C47.2537 40.6794 49.05 36.3427 49.05 31.8207C49.05 27.2988 47.2537 22.9621 44.0562 19.7646C40.8587 16.5671 36.5219 14.7707 32 14.7707C27.4781 14.7707 23.1413 16.5671 19.9438 19.7646C16.7463 22.9621 14.95 27.2988 14.95 31.8207C14.95 36.3427 16.7463 40.6794 19.9438 43.8769ZM45.3997 45.2204C41.8459 48.7742 37.0259 50.7707 32 50.7707C26.9741 50.7707 22.1541 48.7742 18.6003 45.2204C15.0465 41.6666 13.05 36.8466 13.05 31.8207C13.05 26.7949 15.0465 21.9749 18.6003 18.4211C22.1541 14.8672 26.9741 12.8707 32 12.8707C37.0259 12.8707 41.8459 14.8672 45.3997 18.4211C48.9535 21.9749 50.95 26.7949 50.95 31.8207C50.95 36.8466 48.9535 41.6666 45.3997 45.2204Z"
        fill="white"
        stroke="#E6735F"
        strokeWidth="0.5"
      />
      <path
        d="M24.1293 23.9503L24.1297 23.9499C24.218 23.8614 24.3228 23.7912 24.4382 23.7433C24.5537 23.6954 24.6774 23.6708 24.8023 23.6708C24.9273 23.6708 25.051 23.6954 25.1664 23.7433C25.2819 23.7912 25.3867 23.8614 25.4749 23.9499L25.4751 23.9501L31.8255 30.3029L32.0023 30.4797L32.1792 30.3029L38.5295 23.9501C38.6179 23.8617 38.7228 23.7917 38.8382 23.7438C38.9537 23.696 39.0774 23.6714 39.2023 23.6714C39.3273 23.6714 39.451 23.696 39.5665 23.7438C39.6819 23.7917 39.7868 23.8617 39.8752 23.9501C39.9635 24.0385 40.0336 24.1433 40.0814 24.2588C40.1292 24.3742 40.1539 24.498 40.1539 24.6229C40.1539 24.7479 40.1292 24.8716 40.0814 24.9871C40.0336 25.1025 39.9635 25.2074 39.8752 25.2957L33.5224 31.6461L33.3455 31.8229L33.5224 31.9997L39.8752 38.3501C39.8752 38.3501 39.8752 38.3501 39.8752 38.3501C39.9635 38.4385 40.0336 38.5434 40.0814 38.6588C40.1292 38.7742 40.1539 38.898 40.1539 39.0229C40.1539 39.1479 40.1292 39.2716 40.0814 39.3871C40.0336 39.5025 39.9635 39.6074 39.8752 39.6957C39.7868 39.7841 39.6819 39.8542 39.5665 39.902C39.451 39.9498 39.3273 39.9744 39.2023 39.9744C39.0774 39.9744 38.9537 39.9498 38.8382 39.902C38.7228 39.8542 38.6179 39.7841 38.5295 39.6958C38.5295 39.6958 38.5295 39.6958 38.5295 39.6957L32.1792 33.343L32.0023 33.1661L31.8255 33.343L25.4752 39.6957C25.3868 39.7841 25.2819 39.8542 25.1665 39.902C25.051 39.9498 24.9273 39.9744 24.8023 39.9744C24.6774 39.9744 24.5537 39.9498 24.4382 39.902C24.3228 39.8542 24.2179 39.7841 24.1295 39.6957C24.0412 39.6074 23.9711 39.5025 23.9233 39.3871C23.8754 39.2716 23.8508 39.1479 23.8508 39.0229C23.8508 38.898 23.8754 38.7742 23.9233 38.6588C23.9711 38.5433 24.0412 38.4385 24.1295 38.3501L30.4823 31.9997L30.6592 31.8229L30.4823 31.6461L24.1295 25.2957L24.1293 25.2955C24.0408 25.2073 23.9706 25.1024 23.9227 24.987C23.8748 24.8716 23.8502 24.7479 23.8502 24.6229C23.8502 24.498 23.8748 24.3742 23.9227 24.2588C23.9706 24.1434 24.0408 24.0386 24.1293 23.9503Z"
        fill="white"
        stroke="#E6735F"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export { ModalDefaultIcon, ModalWarningIcon, ModalSuccessIcon, ModalErrorIcon };
