import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

import { IState } from '~/store/modules/types';
import { postErrorCloseInvoiceGroupPayment } from '~/store/modules/enrollmentsInvoices/actions';
import Payment from '~/components/Payment';
import DischargeDebtsSinglePaymentDataPaymentOld from './DischargeDebtsSinglePaymentDataPaymentOld';
import { history } from '~/store';
import {
  activateModalDataUpdate,
  getInvoiceDownload,
} from '~/store/modules/outsides/actions';
import formatter from '~/utils/formatter';
import { checkToggle } from '~/utils/toggles';

const DischargeDebtsSinglePaymentDataPayment: FC = () => {
  const dataInvoiceInstallmentGroup = history.location.state as any;

  const dispatch = useDispatch();

  const dataInvoiceInstallments = useSelector(
    (item: IState) => item?.outsides?.dataInvoiceInstallments,
  );
  const dataRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.dataRegistrationUpdate,
  );
  const loading = useSelector((item: IState) => item?.payment.loading);
  const loadingDownload = useSelector(
    (item: IState) => item?.outsides.loadingDownload,
  );
  const error = useSelector((item: IState) => item?.payment?.error);
  const toggles = useSelector((item: IState) => item.toggles.data);

  const possible_quantity_installments = dataInvoiceInstallments
    ? dataInvoiceInstallments?.length || 1
    : 1;
  const applyPaymentUnavailable = dataInvoiceInstallmentGroup?.typeView !== 'detail';
  const paymentIds =
    dataInvoiceInstallmentGroup?.typeView === 'detail'
      ? [
          dataInvoiceInstallmentGroup?.invoicesGroup[0]?.invoice_id ||
            dataInvoiceInstallmentGroup?.invoicesGroup[0]?.id ||
            '',
        ]
      : dataInvoiceInstallmentGroup?.invoicesGroup?.map(
          (invoiceGroup: any) => invoiceGroup?.invoice_id,
        );
  const isInvoice =
    dataInvoiceInstallmentGroup?.invoicesGroup[0]?.type === 'INVOICE';
  const cnp = dataInvoiceInstallmentGroup?.cnp;
  const enrollment = dataInvoiceInstallmentGroup?.enrollment;

  const [paymentModule, setPaymentModule] = useState<boolean>(true);

  const verifyToggles = async () => {
    setPaymentModule(await checkToggle(toggles, 'PAYMENT_MODULE'));
  };

  useEffect(() => {
    if (toggles && toggles.length > 0) verifyToggles();
  }, [toggles]);

  const backNavigation = () => {
    if (dataInvoiceInstallmentGroup?.typeView === 'detail') {
      Navigation.navigate(RouteName.DISCHARGEDEBTS, {
        typeView: dataInvoiceInstallmentGroup?.typeView,
        invoice: dataInvoiceInstallmentGroup.invoicesGroup[0],
        cnp: dataInvoiceInstallmentGroup?.cnp,
        enrollment: dataInvoiceInstallmentGroup?.enrollment,
      });
    } else {
      Navigation.navigate(RouteName.DISCHARGEDEBTSSINGLEPAYMENT, {
        invoices: dataInvoiceInstallmentGroup?.invoices,
        typeView: 'group',
        enrollment: dataInvoiceInstallmentGroup?.enrollment,
        cnp: dataInvoiceInstallmentGroup?.cnp,
      });
    }
  };

  const links =
    dataInvoiceInstallmentGroup?.typeView === 'detail'
      ? [
          {
            label: 'Home',
            active: false,
            onClick: () => Navigation.navigate(RouteName.LANDING),
          },
          {
            label: 'Quitação de dívidas',
            active: false,
            onClick: () => Navigation.navigate(RouteName.DISCHARGEDEBTS),
          },
          {
            label: 'Dívidas em aberto',
            active: false,
            onClick: () => Navigation.goBack(),
          },
          {
            label: 'Pagamento com cartão de crédito',
            active: true,
          },
        ]
      : [
          {
            label: 'Home',
            active: false,
            onClick: () => Navigation.navigate(RouteName.LANDING),
          },
          {
            label: 'Pagamento único',
            active: true,
          },
        ];

  useEffect(() => {
    dispatch(postErrorCloseInvoiceGroupPayment());
  }, []);

  const loadingLabel = useMemo(() => {
    if (loading) return 'Processando...';
    return 'Baixando...';
  }, [loading, loadingDownload]);

  useEffect(() => {
    if (error) {
      if (dataInvoiceInstallmentGroup?.typeView === 'detail') {
        ReactGA.event({
          category: 'Pagamento',
          action:
            '[QD][C4][WEB] – Cartão – Pg. de uma única dívida - Recusa no Pagamento',
        });
      } else {
        ReactGA.event({
          category: 'Pagamento Único',
          action: '[QD][C4][WEB] – Cartão – PG. Único - Recusa no Pagamento',
        });
      }
    }
  }, [error]);

  const handleDownloadBillet = (
    typePaymentSelected: 'credit_card' | 'pix' | 'billet',
  ) => {
    if (loadingDownload) return;

    if (typePaymentSelected === 'billet') {
      ReactGA.event({
        category: 'Pagamento',
        action: '[QD][C4][WEB] - Pg. de uma única dívida – Copia/Cola Código Boleto',
      });
    } else {
      ReactGA.event({
        category: 'Pagamento',
        action: '[QD][C4][WEB] - Pg. de uma única dívida – Copia/Cola Código PIX',
      });
    }

    dispatch(
      getInvoiceDownload(
        paymentIds,
        isInvoice ? 'invoice' : 'charge',
        cnp,
        enrollment?.split('-')[0],
      ),
    );

    if (dataRegistrationUpdate?.isUpdatable)
      dispatch(activateModalDataUpdate('download'));
  };

  if (!paymentModule) return <DischargeDebtsSinglePaymentDataPaymentOld />;

  return (
    <Payment
      links={links}
      loading={loading || loadingDownload}
      loadingLabel={loadingLabel}
      paymentUnavailable={{
        credit_card: !isInvoice,
        pix: applyPaymentUnavailable,
        billet: applyPaymentUnavailable,
      }}
      typePayment="easy_duplicate_payment"
      enrollmentId={formatter.removeDigitEnrollment(enrollment) || ''}
      paymentData={{
        paymentIds,
        amount: dataInvoiceInstallmentGroup?.totalAmount || 0,
        possible_quantity_installments,
        qr_code_key: dataInvoiceInstallmentGroup?.qr_code_key,
        bar_code_number: dataInvoiceInstallmentGroup?.bar_code_number,
        bar_code_base64: dataInvoiceInstallmentGroup?.bar_code_base64,
        routeInvoiceReceipt:
          RouteName?.DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENTRECEIPT,
      }}
      operation={{
        company_name: dataRegistrationUpdate?.company_name || '',
        cnp: dataRegistrationUpdate?.cnp_alert || '',
      }}
      handleDownloadBillet={handleDownloadBillet}
      handleBackNavigation={backNavigation}
    />
  );
};

export default DischargeDebtsSinglePaymentDataPayment;
