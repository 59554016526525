import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const DescriptionText = styled.p`
  max-width: 550px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #202022;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #32a2b4;
  }
`;

export const InfoImg = styled.img`
  max-width: 377px;
  height: 136px;

  @media (min-width: ${Breakpoints.laptop.max}px) {
    height: 165px;
  }
`;
