import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ButtonLink, Container, Content, Img } from './styles';
import bannerDownloadAppMobileImg from '~/design_system/assets/images/banner-download-app-store-mobile.png';
import bannerDownloadAppTabletImg from '~/design_system/assets/images/banner-download-app-store-tablet.png';
import bannerDownloadAppDesktopImg from '~/design_system/assets/images/banner-download-app-store-desktop.png';
import appleStoreImg from '~/assets/Images/apple-store.png';
import googleStoreImg from '~/assets/Images/google-store.png';
import { Breakpoints } from '~/styles';

const BannerApp: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const isTablet = useMediaQuery({ maxWidth: Breakpoints.laptop.max });

  const handleGetBanner = () => {
    const banner = isMobile
      ? bannerDownloadAppMobileImg
      : isTablet
      ? bannerDownloadAppTabletImg
      : bannerDownloadAppDesktopImg;

    return banner;
  };

  return (
    <Container>
      <Content>
        <Img src={handleGetBanner()} alt="Banner - Baixar app" />
        <ButtonLink
          href="https://play.google.com/store/apps/details?id=com.iguaapp"
          target="_blank"
          rel="noreferrer"
        >
          <img src={googleStoreImg} alt="Botão - Loja Google" />
        </ButtonLink>
        <ButtonLink
          href="https://apps.apple.com/br/app/digi-igu%C3%A1/id1588069396"
          target="_blank"
          rel="noreferrer"
        >
          <img src={appleStoreImg} alt="Botão - Loja Apple" />
        </ButtonLink>
      </Content>
    </Container>
  );
};

export { BannerApp };
