/* eslint-disable new-cap */
import React, { FC, useState } from 'react';
import ReactGA from 'react-ga4';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerShare,
} from './styles';

import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Margin } from '~/components/Margin';
import Navigation from '~/utils/Navigation';
import { DownloadIcon } from './icon';
import LoadingComponent from '~/components/Loading';
import { IPaymentReceiptProps } from './types';
import UnifiedInfo from './UnifiedInfo';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

const PaymentReceipt: FC<IPaymentReceiptProps> = ({
  links,
  loading,
  labelWait,
  printGA,
  printSecondGA,
  navigateHomeRoute,
}) => {
  const paymentVoucher = useSelector(
    (item: IState) => item?.payment?.paymentVoucher,
  );

  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const printPDF = async () => {
    setDownloadLoading(true);

    if (printGA) {
      ReactGA.event({
        category: printGA?.category,
        action: printGA?.action,
      });
    }

    if (printSecondGA) {
      ReactGA.event({
        category: printSecondGA?.category,
        action: printSecondGA?.action,
      });
    }

    setTimeout(async () => {
      const pdf = new jsPDF('portrait', 'pt', 'a4', true);
      const domElement = document.getElementById('download-receipt');
      if (domElement) {
        const data = await html2canvas(domElement);
        const img = data.toDataURL('image/png');
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('comprovante.pdf');
      }

      setDownloadLoading(false);
    }, 1000);
  };

  return (
    <Main>
      {(loading || downloadLoading) && (
        <LoadingComponent labelWait={labelWait || 'Carregando...'} />
      )}
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card id="download-receipt">
        <h1>Comprovante de pagamento</h1>
        <Margin height={24} />
        <ContainerBorder>
          <Border />
          <h2>Sucesso!</h2>
          <Border />
        </ContainerBorder>
        <Margin height={14} />
        {!downloadLoading && (
          <>
            <ContainerShare>
              <button type="button" onClick={printPDF}>
                Baixar
                <DownloadIcon />
              </button>
              {/* <button type="button" onClick={() => console.log('Email')}>
                Email
                <EmailIcon />
              </button> */}
            </ContainerShare>
            <Margin height={24} />
          </>
        )}
        {paymentVoucher && <UnifiedInfo {...paymentVoucher} />}
        {!downloadLoading && (
          <ContainerButtons>
            <ButtonText
              text="Ir para home"
              typeBtn="content"
              onClick={() => Navigation.navigate(navigateHomeRoute)}
            />
          </ContainerButtons>
        )}
      </Card>
    </Main>
  );
};

export default PaymentReceipt;
