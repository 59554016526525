import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  gap: 24px;
  cursor: pointer;
  background: none;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 14px 24px;
    flex-direction: row;
    align-items: center;
    border: 1px solid #c7c9c7;
    border-radius: 16px;
  }
`;

export const DivisorMobile = styled.div`
  width: 100%;
  border: 0.5px solid #c7c9c7;
`;

export const Divisor = styled.div`
  height: 25.5px;
  border: 1px solid #c7c9c7;
`;
