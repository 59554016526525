import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

const InfoIcon = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <svg
      width={isMobile ? '50' : '64'}
      height={isMobile ? '50' : '64'}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill="#004B55" />
      <path
        d="M40 20C29 20 20 29 20 40C20 51 29 60 40 60C51 60 60 51 60 40C60 29 51 20 40 20Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 48L40 38"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.0117 32L39.9938 32"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SuccessIcon = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <svg
      width={isMobile ? '50' : '64'}
      height={isMobile ? '50' : '64'}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#37B482" />
      <path
        d="M32 48C40.8 48 48 40.8 48 32C48 23.2 40.8 16 32 16C23.2 16 16 23.2 16 32C16 40.8 23.2 48 32 48Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1953 31.9997L29.7233 36.5277L38.7953 27.4717"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const AlertIcon = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <svg
      width={isMobile ? '50' : '64'}
      height={isMobile ? '50' : '64'}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#FAB419" />
      <path
        d="M32 27.2V35.2"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0001 47.056H22.3041C16.7521 47.056 14.4321 43.088 17.1201 38.24L22.1121 29.248L26.8161 20.8C29.6641 15.664 34.3361 15.664 37.1841 20.8L41.8881 29.264L46.8801 38.256C49.5681 43.104 47.2321 47.072 41.6961 47.072H32.0001V47.056Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.9912 40H32.0056"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { InfoIcon, SuccessIcon, AlertIcon };
