import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';

import ArrowRightSVG from '~/assets/Icons/arrowRight';
import ArrowLeftSVG from '~/assets/Icons/arrowLeft';
import BtnArrow from './BtnArrow';
import { IState } from '~/store/modules/types';
import { IInvoices } from '~/store/modules/enrollmentsInvoices/types';
import SkeletonCardInvoice from './SkeletonCardInvoice';
import NewCardInvoice from './NewCardInvoice';

export default function InvoiceSlick() {
  const loading = useSelector((item: IState) => item?.enrollmentsInvoices?.loading);
  const dataInvoice =
    useSelector((item: IState) => item?.enrollmentsInvoices?.data?.invoices) || [];

  const [data, setData] = useState<IInvoices[] | []>([]);
  const [open, setOpen] = useState<number>(0);

  const [settings, setSettings] = useState({
    dots: false,
    infinite: false,
    arrows: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: (
      <BtnArrow className="left">
        <ArrowLeftSVG width={21} height={35} color="#C7C9C780" />
      </BtnArrow>
    ),
    nextArrow: (
      <BtnArrow className="right">
        <ArrowRightSVG width={21} height={35} color="#C7C9C780" />
      </BtnArrow>
    ),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: false,
          prevArrow: (
            <BtnArrow className="left">
              <ArrowLeftSVG width={21} height={35} color="#C7C9C780" />
            </BtnArrow>
          ),
          nextArrow: (
            <BtnArrow className="right">
              <ArrowRightSVG width={21} height={35} color="#C7C9C780" />
            </BtnArrow>
          ),
        },
      },
    ],
  });

  useEffect(() => {
    if (dataInvoice?.length > 0) {
      const openLength =
        dataInvoice?.length > 0
          ? dataInvoice?.filter(
              (item) =>
                item.status.label === 'PENDENTE' || item.status.label === 'VENCIDA',
            )
          : [];

      setOpen(openLength.length);

      setData(openLength.length > 0 ? openLength : dataInvoice);
      setSettings({
        ...settings,
        initialSlide: dataInvoice?.length > 0 ? dataInvoice.length - 1 : 0,
      });
    }
  }, [dataInvoice]);

  const nullItem = {
    invoice_id: 0,
    reference: '',
    status: {
      id: 0,
      label: '',
    },
    fmt_amount: '',
    billed_consumption: '',
    maturity_date: '',
  };

  return (
    <Slider {...settings}>
      {loading ? (
        <SkeletonCardInvoice />
      ) : data && data?.length > 0 ? (
        data?.map((item: any) => (
          <NewCardInvoice open={open} key={item.invoice_id} {...item} />
        ))
      ) : (
        <NewCardInvoice
          amount={0}
          issue_date_hour=""
          overdue={false}
          {...nullItem}
        />
      )}
    </Slider>
  );
}
