import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface IPropsPaymentGroupCard {
  color: string;
  fullHeight: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
  }
`;

export const ContainerPaymentGroup = styled.div`
  width: 100%;
  margin-bottom: 16px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 50%;
    margin-bottom: 0;
  }
`;

export const ContainerInvoices = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 50%;
  }
`;

export const PaymentGroupCard = styled.div<IPropsPaymentGroupCard>`
  min-height: ${({ fullHeight }) => (fullHeight ? 'calc(240px - 44px)' : '135px')};
  background: #f2f2f2;
  border-top: 8px solid ${({ color }) => color};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${({ fullHeight }) =>
    fullHeight ? '17px 24px 17px 24px' : '0 8px 0 24px'};

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 122.5%;
    display: flex;
    align-items: center;
    color: ${({ color }) => color};

    span {
      svg {
        width: 45px;
        margin-right: 24px;
      }
    }

    :last-of-type {
      margin-top: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: ${Color.blueIntense};

      svg {
        display: none;
        margin-right: 25px;
      }
    }

    div {
      :last-of-type {
        margin-top: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: ${Color.blueIntense};
      }

      button {
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: ${Color.blueSeconde};
        text-decoration: underline;
        background: none;
        border: none;
      }
    }
  }

  @media (min-width: ${Breakpoints.mobile.max}px) {
    max-width: calc(383px - 48px);
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    p {
      :last-of-type {
        svg {
          display: inherit;
          visibility: hidden;
        }
      }
    }
  }
`;
