/* eslint-disable prefer-const */
import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

import { IState } from '~/store/modules/types';
import { postErrorCloseInvoiceGroupPayment } from '~/store/modules/enrollmentsInvoices/actions';
import Payment from '~/components/Payment';
import SinglePaymentDataPaymentOld from './SinglePaymentDataPaymentOld';
import { history } from '~/store';
import { checkToggle } from '~/utils/toggles';
import { formatPaymentIds } from '~/components/Payment/utils';
import { IPaymentInvoices } from '~/store/modules/payment/types';

const SinglePaymentDataPayment: FC = () => {
  const invoicesHistory = history?.location?.state as any;

  const dispatch = useDispatch();

  const registration = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const dataInvoiceInstallmentGroup = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.dataInvoiceInstallmentGroup,
  );
  const loading = useSelector((item: IState) => item?.payment?.loading);
  const loadingVoucher = useSelector(
    (item: IState) => item?.payment?.loadingVoucher,
  );
  const error = useSelector((item: IState) => item?.payment?.error);
  const operation = useSelector(
    (item: IState) => item.enrollmentsAuth?.registrationCheck?.operation,
  );
  const toggles = useSelector((item: IState) => item.toggles.data);

  const [paymentModule, setPaymentModule] = useState<boolean>(true);

  const verifyToggles = async () => {
    setPaymentModule(await checkToggle(toggles, 'PAYMENT_MODULE'));
  };

  useEffect(() => {
    if (toggles && toggles.length > 0) verifyToggles();
  }, [toggles]);

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.HOME),
    },
    {
      label: 'Pagamento único',
      active: true,
    },
  ];

  useEffect(() => {
    dispatch(postErrorCloseInvoiceGroupPayment());
  }, []);

  const loadingLabel = useMemo(() => {
    if (loading) return 'Processando...';
    if (loadingVoucher) return 'Carregando...';
    return '';
  }, [loading, loadingVoucher]);

  useEffect(() => {
    if (error && error?.state) {
      ReactGA.event({
        category: 'Pagamento Único',
        action: '[Pg. Único][WEB] Pagamento sem Sucesso',
      });
    }
  }, [error]);

  if (!paymentModule) return <SinglePaymentDataPaymentOld />;

  const getDataPaymentIds = (): any => {
    let invoices: Array<IPaymentInvoices> = [];

    invoicesHistory?.paymentIds?.map((id: number) => {
      invoices?.push(
        formatPaymentIds({
          type: 'INVOICE',
          id: String(id),
          enrollmentInfo: registration || '',
        }),
      );

      return id;
    });

    return { invoices };
  };

  return (
    <Payment
      links={links}
      loading={loading || loadingVoucher}
      loadingLabel={loadingLabel}
      isAuthenticated
      paymentUnavailable={{ pix: true, billet: true }}
      typePayment="single_payment"
      paymentData={{
        invoices: getDataPaymentIds(),
        amount: dataInvoiceInstallmentGroup?.totalValue || 0,
        possible_quantity_installments:
          dataInvoiceInstallmentGroup?.installments || 1,
        routeInvoiceReceipt: RouteName.INVOICERECEIP,
      }}
      operation={{
        company_name: operation?.company_name || '',
        cnp: operation?.cnp || '',
      }}
    />
  );
};

export default SinglePaymentDataPayment;
