import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { history } from '~/store';

import { IState } from '~/store/modules/types';
import {
  getEnrollmentsInvoicesDetailRequest,
  getEnrollmentsInvoicesDownloadRequest,
  getEnrollmentsInvoicesGroupRequest,
  getErrorCloseEnrollmentsInvoicesDownload,
} from '~/store/modules/enrollmentsInvoices/actions';
import Payment from '~/components/Payment';
import { postErrorCloseEnrollmentsPayment } from '~/store/modules/enrollmentsPayment/actions';
import SimplifiedInvoicePaymentsOld from './SimplifiedInvoicePaymentsOld';
import { checkToggle } from '~/utils/toggles';
import { formatPaymentIds } from '~/components/Payment/utils';

const SimplifiedInvoicePayments: FC = () => {
  const invoiceDetail = history.location?.state as any;

  const dispatch = useDispatch();

  const registration = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const loading = useSelector((item: IState) => item?.payment?.loading);
  const loadingDownload = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingDownload,
  );
  const loadingDetailInvoice = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingDetail,
  );
  const loadingEnrollmentsInvoices = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.loading,
  );
  const error = useSelector((item: IState) => item.payment.error);
  const operation = useSelector(
    (item: IState) => item.enrollmentsAuth?.registrationCheck?.operation,
  );
  const toggles = useSelector((item: IState) => item.toggles.data);

  const [paymentModule, setPaymentModule] = useState<boolean>(true);

  const invoices: any = {
    invoices: [
      formatPaymentIds({
        type: 'INVOICE',
        id: invoiceDetail?.invoice_id,
        enrollmentInfo: registration || '',
      }),
    ],
  };

  const verifyToggles = async () => {
    setPaymentModule(await checkToggle(toggles, 'PAYMENT_MODULE'));
  };

  useEffect(() => {
    if (toggles && toggles.length > 0) verifyToggles();
  }, [toggles]);

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.HOME),
    },
    {
      label: 'Faturas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.INVOICES),
    },
    {
      label: 'Fatura completa',
      active: false,
      onClick: () =>
        dispatch(getEnrollmentsInvoicesDetailRequest(invoiceDetail?.invoice_id)),
    },
    {
      label: 'Pagamento de fatura',
      active: true,
    },
  ];

  useEffect(() => {
    dispatch(postErrorCloseEnrollmentsPayment());
    dispatch(getErrorCloseEnrollmentsInvoicesDownload());

    ReactGA.event({
      category: 'Pagamento de fatura',
      action: '“[AL][Web][Pagamento de Fatura]Escolher Pix”',
    });
  }, []);

  useEffect(() => {
    if (error && error?.state) {
      if (invoiceDetail?.typeView === 'detail') {
        ReactGA.event({
          category: 'Pagamento',
          action:
            '[QD][C4][WEB] – Cartão – Pg. de uma única dívida - Recusa no Pagamento',
        });
      } else {
        ReactGA.event({
          category: 'Pagamento Único',
          action: '[QD][C4][WEB] – Cartão – PG. Único - Recusa no Pagamento',
        });
      }

      ReactGA.event({
        category: 'Pagamento de fatura',
        action: '[AL][Web][Fatura Detalhada][Pgto Cartão]Pagamento sem Sucesso',
      });
    }
  }, [error]);

  const loadingLabel = useMemo(() => {
    if (loading) return 'Processando...';
    if (loadingDownload) return 'Baixando...';
    if (loadingDetailInvoice || loadingEnrollmentsInvoices) return 'Carregando...';
    return '';
  }, [loading, loadingDownload, loadingDetailInvoice, loadingEnrollmentsInvoices]);

  const handlePaymentGroup = () => {
    ReactGA.event({
      category: 'Pagamento Único',
      action: '[AL][Web][Home][Pagamento de Fatura]Pg.Único',
    });
    dispatch(getEnrollmentsInvoicesGroupRequest());
  };

  const handleDownloadBillet = () => {
    ReactGA.event({
      category: 'Pagamento de fatura',
      action: '[AL][Web][Home][Pagamento de Fatura]Baixar boleto simplificado',
    });

    dispatch(getEnrollmentsInvoicesDownloadRequest(invoiceDetail?.invoice_id));
  };

  if (!paymentModule) return <SimplifiedInvoicePaymentsOld />;

  return (
    <Payment
      links={links}
      loading={
        loading ||
        loadingDownload ||
        loadingDetailInvoice ||
        loadingEnrollmentsInvoices
      }
      loadingLabel={loadingLabel}
      typePayment="simplified_invoice"
      isAuthenticated
      paymentData={{
        invoices,
        amount: invoiceDetail?.amount,
        possible_quantity_installments:
          invoiceDetail?.possible_quantity_installments,
        routeInvoiceReceipt: RouteName.INVOICERECEIP,
        qr_code_key: invoiceDetail?.qr_code_key,
        bar_code_number: invoiceDetail?.bar_code_number,
        bar_code_base64: invoiceDetail?.bar_code_base64,
      }}
      handlePaymentGroup={handlePaymentGroup}
      handleDownloadBillet={handleDownloadBillet}
      operation={{
        company_name: operation?.company_name || '',
        cnp: operation?.cnp || '',
      }}
    />
  );
};

export default SimplifiedInvoicePayments;
