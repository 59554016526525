import React, { FC, ReactNode } from 'react';
import { ContainerServices, RowContainerServices, ServiceBox } from '../styles';
import {
  CentralRepairsServiceIcon,
  CertificateNegativeServiceIcon,
  DpaDpeServiceIcon,
  HistoryDetailedServiceIcon,
  InvoicesServiceIcon,
  TariffSocialServiceIcon,
} from './icons';

const ContentServices: FC = () => {
  const handleSetContainerIcon = (icon: ReactNode) => {
    return <div>{icon}</div>;
  };

  return (
    <ContainerServices>
      <RowContainerServices>
        <ServiceBox>
          {handleSetContainerIcon(<InvoicesServiceIcon />)}
          Faturas
        </ServiceBox>
        <ServiceBox>
          {handleSetContainerIcon(<CertificateNegativeServiceIcon />)}
          Certidão negativa
        </ServiceBox>
        <ServiceBox>
          {handleSetContainerIcon(<HistoryDetailedServiceIcon />)}
          Histórico detalhado
        </ServiceBox>
      </RowContainerServices>
      <RowContainerServices>
        <ServiceBox>
          {handleSetContainerIcon(<CentralRepairsServiceIcon />)}
          Central de reparos
        </ServiceBox>
        <ServiceBox>
          {handleSetContainerIcon(<DpaDpeServiceIcon />)}
          DPA/DPE
        </ServiceBox>
        <ServiceBox>
          {handleSetContainerIcon(<TariffSocialServiceIcon />)}
          Tarifa social
        </ServiceBox>
      </RowContainerServices>
    </ContainerServices>
  );
};

export default ContentServices;
