import { Reducer } from 'redux';

import { RepositoriesTypesPayment, IRepositoriesStatePayment } from './types';

const INITIAL_STATE: IRepositoriesStatePayment = {
  paymentVoucher: null,
  successNegotation: false,
  loading: false,
  loadingVoucher: false,
  error: null,
  errorVoucher: null,
};

const reducer: Reducer<IRepositoriesStatePayment, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesPayment.POST_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesPayment.POST_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentVoucher: { ...action.payload.data },
        loading: false,
      };
    case RepositoriesTypesPayment.POST_PAYMENT_METHOD_NEGOTIATION_SUCCESS:
      return {
        ...state,
        successNegotation: true,
        loading: false,
      };
    case RepositoriesTypesPayment.POST_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        paymentVoucher: null,
        loading: false,
        error: {
          status: action.payload.error.status,
          message: action.payload.error.message,
          state: true,
        },
      };
    case RepositoriesTypesPayment.POST_PAYMENT_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
        successNegotation: false,
      };

    case RepositoriesTypesPayment.GET_PAYMENT_VOUCHER_METHOD_REQUEST:
      return {
        ...state,
        loadingVoucher: true,
      };
    case RepositoriesTypesPayment.GET_PAYMENT_VOUCHER_METHOD_SUCCESS:
      return {
        ...state,
        paymentVoucher: { ...action.payload.data },
        loadingVoucher: false,
      };
    case RepositoriesTypesPayment.GET_PAYMENT_VOUCHER_METHOD_FAILURE:
      return {
        ...state,
        paymentVoucher: null,
        loadingVoucher: false,
        errorVoucher: {
          status: action.payload.error.status,
          message: action.payload.error.message,
          state: true,
        },
      };
    case RepositoriesTypesPayment.GET_PAYMENT_VOUCHER_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorVoucher: null,
      };

    default:
      return state;
  }
};

export default reducer;
