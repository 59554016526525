import React from 'react';
import { ContainerIndicators, Indicator } from './styles';

interface IProps {
  strengthLevel: number;
}

const PasswordStrengthIndicator: React.FC<IProps> = ({ strengthLevel }) => {
  const getBackgroundColorColor = () => {
    if (strengthLevel === 5) return '#37b482';

    if (strengthLevel >= 4) return '#fab419';

    if (strengthLevel > 0) return '#e6735f';

    return '';
  };

  return (
    <ContainerIndicators>
      <Indicator backgroundColor={getBackgroundColorColor()} />
      <Indicator
        backgroundColor={strengthLevel >= 4 ? getBackgroundColorColor() : ''}
      />
      <Indicator
        backgroundColor={strengthLevel === 5 ? getBackgroundColorColor() : ''}
      />
    </ContainerIndicators>
  );
};

export default PasswordStrengthIndicator;
