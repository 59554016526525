import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { ContainerBreadCrumb, Content, Divider, Main, Wrapper } from './styles';
import { Container, Input } from '~/common';
import { Button } from '~/components/ButtonV3';
import { BreadCrumb } from './BreadCrumb';
import MyCard from './MyCard';
import { IState } from '~/store/modules/types';
import {
  clearDischargeDebts,
  getDischargeDebtsRequest,
  getErrorCloseDischargeDebts,
  getOutsidesRegistrationUpdateRequest,
  postErrorCloseOutsidesRegistrationUpdate,
} from '~/store/modules/outsides/actions';
import { IDadosDebts } from '~/store/modules/outsides/types';
import formatter from '~/utils/formatter';
import { Breakpoints, Color } from '~/styles';
import { Margin } from '~/components/Margin';
import { CardSubtitle, CardTitle } from './MyCard/styles';
import { Link } from 'react-router-dom';
import { RouteName } from '~/routes/Routes.name';
import { UsefulLinks } from './UsefulLinks';
import LoadingComponent from '~/components/Loading';
import { checkCnp } from '~/utils/validation';
import Navigation from '~/utils/Navigation';
import { useMediaQuery } from 'react-responsive';

import { Recaptcha } from '~/components/Recaptcha';
import { showRecaptchaV2 } from '~/utils/showRecaptcha';

export interface Values {
  cnp: string;
  invoice: IDadosDebts | null;
  method: 'pixCode' | 'barCode';
  type: 'CPF' | 'CNPJ';
  customError: string;
  invoiceList: IDadosDebts[] | null;
  disabled: boolean;
}

const DischargeDebts: FC = () => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isTablet = useMediaQuery({ maxWidth: Breakpoints.laptop.max - 1 });

  const [cnp, setCnp] = useState<string>('');
  const [errorCnp, setErrorCnp] = useState<boolean>(false);
  const [errorCnpMessage, setErrorCnpMessage] = useState<string>('');

  const loading = useSelector((item: IState) => item.outsides.loadingDischargeDebts);
  const loadingInstallments = useSelector(
    (item: IState) => item?.outsides?.loadingInstallments,
  );
  const error = useSelector((item: IState) => item.outsides.errorDischargeDebts);
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState<string | null>(null);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const showRecaptcha = showRecaptchaV2(error || undefined);

  const recaptchaTokens = {
    recaptchaTokenV3: recaptchaTokenV3 || '',
    recaptchaTokenV2: recaptchaTokenV2 || '',
  };

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.LANDING),
    },
    {
      label: 'Negociação de dívidas',
      active: true,
    },
  ];

  useEffect(() => {
    dispatch(clearDischargeDebts());
    dispatch(getErrorCloseDischargeDebts());
    dispatch(postErrorCloseOutsidesRegistrationUpdate());
  }, []);

  useEffect(() => {
    const cnpIsvalid = checkCnp(cnp);

    if (cnp && !cnpIsvalid) {
      setErrorCnpMessage('CPF ou CNPJ inválido');
      setErrorCnp(true);
    } else {
      setErrorCnpMessage('');
      setErrorCnp(false);
    }
  }, [cnp]);

  useEffect(() => {
    if (error && error.status === 404) {
      setErrorEvent();
      setErrorCnp(true);
    }
  }, [error]);

  const setErrorEvent = () => {
    ReactGA.event({
      category: 'Negociação de dívidas',
      action:
        '[Negociação de dívidas][Acesso] CPF/CNPJ incorreto ou não está na base da iguá',
    });
  };

  const getInvoices = async () => {
    if (!cnp) {
      setErrorEvent();
      setErrorCnpMessage('Preenchimento obrigatório');
      setErrorCnp(true);
      return;
    }

    if (cnp) {
      const cnpIsvalid = checkCnp(cnp);

      if (!cnpIsvalid) {
        setErrorEvent();
        setErrorCnpMessage('CPF ou CNPJ inválido');
        setErrorCnp(true);
        return;
      }
    }

    ReactGA.event({
      category: 'Quitação de dívidas',
      action: '[QD][C4][WEB] - Consultar',
    });

    await dispatch(
      getDischargeDebtsRequest({
        ...recaptchaTokens,
        cnp,
        params: {
          page: 1,
          size: 1000,
        },
      }),
    );

    dispatch(getOutsidesRegistrationUpdateRequest(cnp));
  };

  return (
    <Main>
      <Container>
        <Recaptcha
          setRecaptchaTokenV3={setRecaptchaTokenV3}
          setRecaptchaTokenV2={setRecaptchaTokenV2}
          showRecaptchaV2={showRecaptcha}
          onClose={() => {
            dispatch(getErrorCloseDischargeDebts());
            dispatch(postErrorCloseOutsidesRegistrationUpdate());
          }}
        />
        <Wrapper>
          {!isMobile && !isTablet && (
            <ContainerBreadCrumb>
              <BreadCrumb links={links} />
            </ContainerBreadCrumb>
          )}
          <Content>
            {loadingInstallments && <LoadingComponent labelWait="Carregando..." />}
            <MyCard
              title="Negociação de dívidas"
              subtitle="Digite o CPF/CNPJ do titular para consultar seus débitos."
            >
              <Input
                name="cnp"
                label="CPF ou CNPJ*"
                value={cnp}
                onChange={(e) => setCnp(formatter.cpfCnpjMask(e.target.value))}
                error={errorCnp}
                errorText={errorCnpMessage}
                borderColor={Color.blueIntense}
                inputMode="numeric"
              />
              <Margin height={isMobile ? 12 : 24} />
              <Button
                type="button"
                loading={loading}
                backgroundColor={Color.green}
                onClick={getInvoices}
                style={{
                  height: '48px',
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '17px',
                }}
              >
                Consultar
              </Button>
              <Margin height={isMobile ? 0 : 60} />
            </MyCard>
            {!isMobile && (
              <>
                {!isTablet && <Divider />}
                {isTablet && <Margin height={81} />}
                <MyCard type="link">
                  <CardTitle style={{ textAlign: 'left' }}>
                    Serviços digi iguá
                  </CardTitle>
                  <CardSubtitle style={{ textAlign: 'left' }}>
                    Faça seu <Link to={RouteName.LOGIN}>login</Link> e tenha acesso a
                    muito mais!
                  </CardSubtitle>
                  <UsefulLinks />
                </MyCard>
              </>
            )}
          </Content>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default DischargeDebts;
