import styled from 'styled-components';
import { colors } from '~/design_system/styles/colors';

export const CheckboxContainer = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: ${colors.grey_50};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

interface IPropsStyledCheckbox {
  checked: boolean;
}

export const StyledCheckbox = styled.div<IPropsStyledCheckbox>`
  min-width: 18px;
  height: 18px;
  border-radius: 3px;
  background-color: ${({ checked }) => (checked ? colors.aqua_60 : 'transparent')};
  border: 1px solid ${colors.aqua_60};
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckmarkSVG = styled.svg<IPropsStyledCheckbox>`
  fill: none;
  stroke: ${colors.white};
  stroke-width: 1px;
  visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  display: ${({ checked }) => (checked ? 'inherit' : 'none')};
`;
