import styled, { css } from 'styled-components';
import { IButtonProps } from '.';
import { IEnumButtonVariant, IEnumButtonTypeButton } from './enum';
import { colors } from '~/design_system/styles/colors';

const variantStyles = {
  [IEnumButtonVariant.TEXT]: css`
    height: 40px;
    color: ${colors.aqua_60};
    font-size: 14px;
    background: none;
  `,
  [IEnumButtonVariant.CONTAINED]: css`
    height: 48px;
    color: ${colors.white};
    border-radius: 8px;
  `,
  [IEnumButtonVariant.OUTLINED]: css`
    height: 48px;
    border-radius: 8px;
  `,
};

const background = {
  [IEnumButtonTypeButton.DEFAULT]: colors.aqua_60,
  [IEnumButtonTypeButton.WARNING]: colors.yellow_50,
  [IEnumButtonTypeButton.SUCCESS]: colors.green_50,
  [IEnumButtonTypeButton.ERROR]: colors.red_50,
};

export const ButtonStyled = styled.button<IButtonProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  background: ${({ typeButton }) =>
    background[typeButton || IEnumButtonTypeButton.DEFAULT]};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  cursor: pointer;
  ${({ variant }) => variantStyles[variant || IEnumButtonVariant.CONTAINED]};

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${colors.grey_20};
      color: ${colors.grey_40};
      cursor: default;
    `};
`;

export const Loading = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid white;
  border-bottom: 2px solid transparent;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
