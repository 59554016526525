import React from 'react';

const StatusGroupWait = () => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="44" height="44" rx="8" fill="#37B4C8" />
      <path
        d="M22 12.5V16.7857M22 28.2143V32.5M12 22.5H16.2857M29.1429 15.3571L25.5714 18.9286M14.8571 29.6429L18.4286 26.0714M27.7143 22.5H32M14.8571 15.3571L18.4286 18.9286M29.1429 29.6429L25.5714 26.0714"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const StatusGroupPending = () => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="44" height="44" rx="8" fill="#004B55" />
      <path
        d="M22 19.1667V19.1678M22 22.5V26.9444M32 22.5C32 28.0228 27.5228 32.5 22 32.5C16.4772 32.5 12 28.0228 12 22.5C12 16.9772 16.4772 12.5 22 12.5C27.5228 12.5 32 16.9772 32 22.5Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const StatusGroupCongratulations = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#37B482" />
      <path
        d="M17.8 23.3L14 34L24.7 30.21M16 15H16.01M34 20H34.01M27 14H27.01M34 32H34.01M34 14L31.76 14.75C31.1224 14.9624 30.5783 15.3896 30.2208 15.9587C29.8633 16.5278 29.7146 17.2034 29.8 17.87C29.9 18.73 29.23 19.5 28.35 19.5H27.97C27.11 19.5 26.37 20.1 26.21 20.94L26 22M34 25L33.18 24.67C32.32 24.33 31.36 24.87 31.2 25.78C31.09 26.48 30.48 27 29.77 27H29M23 14L23.33 14.82C23.67 15.68 23.13 16.64 22.22 16.8C21.52 16.9 21 17.52 21 18.23V19"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0005 25C24.9305 26.93 25.8305 29.17 25.0005 30C24.1705 30.83 21.9305 29.93 20.0005 28C18.0705 26.07 17.1705 23.83 18.0005 23C18.8305 22.17 21.0705 23.07 23.0005 25Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const StatusGroupInstallment = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.783203"
        y="0.117188"
        width="44"
        height="43.2242"
        rx="8"
        fill="#872D2D"
      />
      <path
        d="M22.7832 17.5324V23.2861M23.3586 26.7384C23.3586 27.0562 23.101 27.3138 22.7832 27.3138C22.4654 27.3138 22.2078 27.0562 22.2078 26.7384C22.2078 26.4206 22.4654 26.163 22.7832 26.163C23.101 26.163 23.3586 26.4206 23.3586 26.7384ZM15.0878 31.3414H30.4786C32.2105 31.3414 33.3218 29.5006 32.5152 27.968L24.8198 13.3468C23.957 11.7073 21.6094 11.7073 20.7466 13.3468L13.0512 27.968C12.2446 29.5006 13.3559 31.3414 15.0878 31.3414Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const ArrowButtonIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1L13 6M13 6L8 11M13 6H1"
        stroke="#212529"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export {
  StatusGroupWait,
  StatusGroupPending,
  StatusGroupCongratulations,
  StatusGroupInstallment,
  ArrowButtonIcon,
};
