import React, { FC } from 'react';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import NegotiationDetail from '~/components/Negotation/NegotiationDetail';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

const DebtNegotiationDetail: FC = () => {
  const token = useSelector((item: IState) => item.enrollmentsAuth.token);

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(token ? RouteName.HOME : RouteName.LANDING),
    },
    {
      label: 'Negociação de dívidas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DISCHARGEDEBTS),
    },
    {
      label: 'Detalhes da negociação',
      active: true,
    },
  ];

  if (token) delete links[1];

  return <NegotiationDetail links={links} loading={false} />;
};

export default DebtNegotiationDetail;
