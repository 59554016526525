import {
  IRequestCreateUserClient,
  IRequestCreateUserNotClient,
  IRequestRecoveryPassword,
  IRequestSendRecovery,
  IRequestSendSms,
  IRequestUpdatePassword,
  IRequestUpdateUser,
  IRequestValidateRegister,
  IRequestValidateSms,
} from '~/store/modules/enrollmentsUsers/types';
import formatter from '~/utils/formatter';
import { api } from './api';

function getEnrollmentsUsersInfoService() {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsInfo: any = async () => {
        const response = await api.get('v3/users/me');

        return response.data;
      };

      resolve(getEnrollmentsInfo());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsManagerUsersInfoService() {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsInfo: any = async () => {
        const response = await api.get('v3/users/manager/me');

        return response.data;
      };

      resolve(getEnrollmentsInfo());
    } catch (error) {
      reject(error);
    }
  });
}

function putEnrollmentsUsersPasswordService(data: IRequestUpdatePassword) {
  return new Promise((resolve, reject) => {
    try {
      const putEnrollmentsUsersPassword: any = async () => {
        const response = await api.patch('v3/users/me/password', { ...data });
        return response.data;
      };

      resolve(putEnrollmentsUsersPassword());
    } catch (error) {
      reject(error);
    }
  });
}

function putEnrollmentsUsersService(data: IRequestUpdateUser) {
  return new Promise((resolve, reject) => {
    try {
      const putEnrollmentsUsers: any = async () => {
        const response = await api.patch('v3/users/me', { ...data });
        return response.data;
      };

      resolve(putEnrollmentsUsers());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsUsersSendSmsRecoveryService({
  cnp,
  phone,
  email,
}: IRequestSendSms) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsSendSmsRecovery: any = async () => {
        if (email) {
          const response = await api.post('v3/users/send/sms/recovery', {
            cnp: formatter.formatarNumber(cnp),
            email,
          });

          return response.data;
        }

        const response = await api.post('v3/users/send/sms/recovery', {
          cnp: formatter.formatarNumber(cnp),
          phone: `55${formatter.formatarNumber(phone)}`,
        });

        return response.data;
      };

      resolve(getEnrollmentsSendSmsRecovery());
    } catch (error) {
      reject(error);
    }
  });
}

function putEnrollmentsUsersPasswordRecoveryService({
  token,
  newPassword,
}: IRequestRecoveryPassword) {
  return new Promise((resolve, reject) => {
    try {
      const putEnrollmentsUsersPasswordRecovery: any = async () => {
        const response = await api.patch('v3/users/me/password/recovery', {
          token,
          newPassword,
        });
        return response.data;
      };

      resolve(putEnrollmentsUsersPasswordRecovery());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsUsersValidateService({ cnp }: IRequestValidateRegister) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsUsersValidate: any = async () => {
        const response = await api.get(
          `v3/users/v2/${formatter.formatarNumber(cnp)}/validate/register`,
        );

        return response.data;
      };

      resolve(getEnrollmentsUsersValidate());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsUsersSendSmsRegisterService({
  cnp,
  phone,
  isEntrepreneur,
  isManager,
  email,
}: IRequestSendSms) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsSendSmsRecovery: any = async () => {
        if (email) {
          const response = await api.post('v3/users/send/sms/register', {
            cnp: formatter.formatarNumber(cnp),
            email,
            isEntrepreneur,
            isManager,
          });

          return response.data;
        }
        const response = await api.post('v3/users/send/sms/register', {
          cnp: formatter.formatarNumber(cnp),
          phone: `55${formatter.formatarNumber(phone)}`,
          isEntrepreneur,
          isManager,
        });

        return response.data;
      };

      resolve(getEnrollmentsSendSmsRecovery());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsUsersValidateSmsService({
  cnp,
  token,
}: IRequestValidateSms) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsSendSmsRecovery: any = async () => {
        const response = await api.post('v3/users/validate/sms/', {
          cnp: formatter.formatarNumber(cnp),
          token,
        });

        return response.data;
      };

      resolve(getEnrollmentsSendSmsRecovery());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsUsersCreateRegisterService({
  token,
  cnp,
  name,
  concession_terms_agree,
  acceptance_terms_agree,
  password,
}: IRequestCreateUserClient) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsCreateRegister: any = async () => {
        const response = await api.post('v3/users/client', {
          name,
          token,
          cnp: formatter.formatarNumber(cnp),
          concession_terms_agree,
          acceptance_terms_agree,
          password,
        });

        return response.data;
      };

      resolve(getEnrollmentsCreateRegister());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsManagerUsersCreateRegisterService({
  cnpj,
  cpf,
  creci,
  email,
  name,
  phone,
  mobile_phone,
  concession_terms_agree,
  acceptance_terms_agree,
  password,
  token,
  category,
  company_name,
}: IRequestCreateUserClient) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsCreateRegisterManager: any = async () => {
        const response = await api.post('v3/users/manager', {
          category,
          cnpj: formatter.formatarNumber(cnpj),
          cpf: formatter.formatarNumber(cpf),
          creci,
          email,
          name,
          company_name,
          responsible_name: name,
          phone: formatter.formatarNumber(phone),
          mobile_phone: formatter.formatarNumber(mobile_phone),
          concession_terms_agree,
          acceptance_terms_agree,
          password,
          token,
        });

        return response.data;
      };

      resolve(getEnrollmentsCreateRegisterManager());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsUsersNotClientCreateRegisterService({
  clientType,
  documentType,
  name,
  documentNumber,
  birthDate,
  email,
  mobilePhone,
  phone,
  password,
  token,
  nameResponsible,
  documentNumberResponsible = undefined,
}: IRequestCreateUserNotClient) {
  return new Promise((resolve, reject) => {
    try {
      const postEnrollmentsUsersNotClientCreateRegister: any = async () => {
        const response = await api.post('v3/users/notclient', {
          clientType,
          documentType,
          name,
          documentNumber: formatter.formatarNumber(documentNumber),
          birthDate,
          email,
          mobilePhone,
          phone,
          password,
          documentNumberResponsible: formatter.formatarNumber(
            documentNumberResponsible,
          ),
          nameResponsible,
          token,
          concession_terms_agree: true,
          acceptance_terms_agree: true,
        });

        return response.data;
      };

      resolve(postEnrollmentsUsersNotClientCreateRegister());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsUsersObfuscatedService(cnp: string, hasTokenAuth?: boolean) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsUsersObfuscated: any = async () => {
        const route = hasTokenAuth
          ? `v3/users/me/obfuscated-email`
          : `v3/users/cnp/${formatter.formatarNumber(cnp)}/obfuscated-email`;

        const response = await api.get(route);

        return response.data;
      };
      resolve(getEnrollmentsUsersObfuscated());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsUsersSendRecoveryService(data: IRequestSendRecovery) {
  return new Promise((resolve, reject) => {
    try {
      const postEnrollmentsUsersSendRecovery: any = async () => {
        const response = await api.post('v3/users/send/token/recovery', {
          cnp: formatter.formatarNumber(data.cnp),
          shippingChannel: data.shippingChannel,
        });

        return response.data;
      };
      resolve(postEnrollmentsUsersSendRecovery());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getEnrollmentsUsersInfoService,
  putEnrollmentsUsersPasswordService,
  putEnrollmentsUsersService,
  postEnrollmentsUsersSendSmsRecoveryService,
  putEnrollmentsUsersPasswordRecoveryService,
  getEnrollmentsUsersValidateService,
  postEnrollmentsUsersSendSmsRegisterService,
  postEnrollmentsUsersValidateSmsService,
  postEnrollmentsUsersCreateRegisterService,
  postEnrollmentsManagerUsersCreateRegisterService,
  getEnrollmentsManagerUsersInfoService,
  postEnrollmentsUsersNotClientCreateRegisterService,
  getEnrollmentsUsersObfuscatedService,
  postEnrollmentsUsersSendRecoveryService,
};
