import { IEnumErrorResponse } from '~/enums/errorsEnum';
import {
  IPaymentCharges,
  IPaymentDebtNegotiation,
  IPaymentInvoices,
} from '../payment/types';
import { IResponseInvoicesDebts } from '../outsides/types';

export enum RepositoriesTypesNegotiation {
  DEBTS_NEGOTIATION_REQUEST = '@repositories/DEBTS_NEGOTIATION_REQUEST',
  DEBTS_NEGOTIATION_SUCCESS = '@repositories/DEBTS_NEGOTIATION_SUCCESS',
  DEBTS_NEGOTIATION_ENROLLMENT_CHECK_SUCCESS = '@repositories/DEBTS_NEGOTIATION_ENROLLMENT_CHECK_SUCCESS',
  DEBTS_NEGOTIATION_FAILURE = '@repositories/DEBTS_NEGOTIATION_FAILURE',
  DEBTS_NEGOTIATION_FAILURE_CLOSE = '@repositories/DEBTS_NEGOTIATION_FAILURE_CLOSE',

  DEBTS_NEGOTIATION_INSTALLMENTS_REQUEST = '@repositories/DEBTS_NEGOTIATION_INSTALLMENTS_REQUEST',
  DEBTS_NEGOTIATION_INSTALLMENTS_SUCCESS = '@repositories/DEBTS_NEGOTIATION_INSTALLMENTS_SUCCESS',
  DEBTS_NEGOTIATION_INSTALLMENTS_FAILURE = '@repositories/DEBTS_NEGOTIATION_INSTALLMENTS_FAILURE',
  DEBTS_NEGOTIATION_INSTALLMENTS_FAILURE_CLOSE = '@repositories/DEBTS_NEGOTIATION_INSTALLMENTS_FAILURE_CLOSE',

  GET_DEBTS_NEGOTIATION_TERMS_REQUEST = '@repositories/GET_DEBTS_NEGOTIATION_TERMS_REQUEST',
  GET_DEBTS_NEGOTIATION_TERMS_SUCCESS = '@repositories/GET_DEBTS_NEGOTIATION_TERMS_SUCCESS',
  GET_DEBTS_NEGOTIATION_TERMS_FAILURE = '@repositories/GET_DEBTS_NEGOTIATION_TERMS_FAILURE',
  GET_DEBTS_NEGOTIATION_TERMS_FAILURE_CLOSE = '@repositories/GET_DEBTS_NEGOTIATION_TERMS_FAILURE_CLOSE',

  POST_DEBTS_NEGOTIATION_REQUEST = '@repositories/POST_DEBTS_NEGOTIATION_REQUEST',
  POST_DEBTS_NEGOTIATION_SUCCESS = '@repositories/POST_DEBTS_NEGOTIATION_SUCCESS',
  POST_DEBTS_NEGOTIATION_FAILURE = '@repositories/POST_DEBTS_NEGOTIATION_FAILURE',
  POST_DEBTS_NEGOTIATION_FAILURE_CLOSE = '@repositories/POST_DEBTS_NEGOTIATION_FAILURE_CLOSE',

  POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_REQUEST = '@repositories/POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_REQUEST',
  POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_SUCCESS = '@repositories/POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_SUCCESS',
  POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_FAILURE = '@repositories/POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_FAILURE',
  POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_FAILURE_CLOSE = '@repositories/POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_FAILURE_CLOSE',

  SET_DATA_PAYMENT_DEBTS_NEGOTIATION = '@repositories/SET_DATA_PAYMENT_DEBTS_NEGOTIATION',
  CLEAR_DATA_PAYMENT_DEBTS_NEGOTIATION = '@repositories/CLEAR_DATA_PAYMENT_DEBTS_NEGOTIATION',

  GET_DEBTS_NEGOTIATION_AUTH_REQUEST = '@repositories/GET_DEBTS_NEGOTIATION_AUTH_REQUEST',
  GET_DEBTS_NEGOTIATION_AUTH_SUCCESS = '@repositories/GET_DEBTS_NEGOTIATION_AUTH_SUCCESS',
  GET_DEBTS_NEGOTIATION_AUTH_FAILURE = '@repositories/GET_DEBTS_NEGOTIATION_AUTH_FAILURE',
  GET_DEBTS_NEGOTIATION_AUTH_FAILURE_CLOSE = '@repositories/GET_DEBTS_NEGOTIATION_AUTH_FAILURE_CLOSE',

  GET_DEBTS_NEGOTIATION_LIST_REQUEST = '@repositories/GET_DEBTS_NEGOTIATION_LIST_REQUEST',
  GET_DEBTS_NEGOTIATION_LIST_SUCCESS = '@repositories/GET_DEBTS_NEGOTIATION_LIST_SUCCESS',
  GET_DEBTS_NEGOTIATION_LIST_FAILURE = '@repositories/GET_DEBTS_NEGOTIATION_LIST_FAILURE',
  GET_DEBTS_NEGOTIATION_LIST_FAILURE_CLOSE = '@repositories/GET_DEBTS_NEGOTIATION_LIST_FAILURE_CLOSE',

  GET_DEBTS_NEGOTIATION_LIST_DETAIL_REQUEST = '@repositories/GET_DEBTS_NEGOTIATION_LIST_DETAIL_REQUEST',
  GET_DEBTS_NEGOTIATION_LIST_DETAIL_SUCCESS = '@repositories/GET_DEBTS_NEGOTIATION_LIST_DETAIL_SUCCESS',
  GET_DEBTS_NEGOTIATION_LIST_DETAIL_FAILURE = '@repositories/GET_DEBTS_NEGOTIATION_LIST_DETAIL_FAILURE',
  GET_DEBTS_NEGOTIATION_LIST_DETAIL_FAILURE_CLOSE = '@repositories/GET_DEBTS_NEGOTIATION_LIST_DETAIL_FAILURE_CLOSE',

  SET_DATA_DETAIL_DEBTS_NEGOTIATION = '@repositories/SET_DATA_DETAIL_DEBTS_NEGOTIATION',
  CLEAR_DATA_DETAIL_DEBTS_NEGOTIATION = '@repositories/CLEAR_DATA_DETAIL_DEBTS_NEGOTIATION',
}

interface IAuthenticated {
  isAuthenticated?: boolean;
}
export interface IRequestDebtsNegotiationEnrollmentsFilter extends IAuthenticated {
  enrollment: string;
  operationId: number;
}
export interface IRequestDebtsNegotiationEnrollments extends IAuthenticated {
  cnp: string;
  filter?: IResponseDebtsEnrollments;
}

export interface IResponseDebtsEnrollments {
  enrollment: string;
  operationId: number;
  address: string;
}

export interface IEnrollmentCheck {
  enrollment: string;
  operationId: number;
  address: string;
}

export interface IRequestDebtsNegotiation extends IAuthenticated {
  cnp: string;
  filter?: IResponseDebtsEnrollments;
}

export interface IResponseDataDebtsNegotiationPaymentCondition {
  campaignPaymentConditionId: number;
  paymentConditionDescription: string;
  maximumDiscountAmount: number;
  maximumDiscountAmountPercent: number;
  minimumDownPayment: number;
}

export interface IResponseDataDebtsNegotiationInvoice {
  description: string;
  dueDate: string;
  reference: string;
  status?: {
    id: number;
    label: string;
  };
  totalInvoiceAmount: number;
  type: 'INVOICE' | 'CHARGE';
}
export interface IResponseDataDebtsNegotiation {
  amountTotal: number;
  campaignBillingId: number;
  campaignPaymentConditions: IResponseDataDebtsNegotiationPaymentCondition[];
  invoices: IResponseDataDebtsNegotiationInvoice[];
  maximumDiscountAmount: number;
  maximumDiscountAmountPercent: number;
  operation: string;
  unitRegistrationNumber: string;
}

export interface IRequestDebtsNegotiationInstallments extends IAuthenticated {
  operationId: number;
  enrollment: string;
  handleContinue?: () => void;
  params: {
    downPaymentAmount: number;
    campaignBillingConditionId: number;
  };
}

export interface IResponseDataDebtsNegotiationInstallmentsInstallment {
  id: string;
  installmentCount: number;
  installmentAmount: number;
  lastInstallmentAmount: number;
}

export interface IResponseDataDebtsNegotiationInstallments {
  installments: Array<IResponseDataDebtsNegotiationInstallmentsInstallment>;
}

export interface IRequestCreateDebtsNegotiationSummary extends IAuthenticated {
  operationId: number;
  enrollment: string;
  downPaymentAmount: number;
  campaignBillingConditionId: number;
  installmentsId: string;
}

export interface IResponseCreateDebtsNegotiationSummary {
  id: number;
  summary: {
    registration: string;
    installmentId: number;
    totalAmount: number;
    totalAmountWithDiscount: number;
    installmentCount: number;
    installmentAmount: number;
    lastInstallmentAmount: number;
    upfrontInstallmentAmount: number;
    negotiationDate: string;
    dueDate: string;
    downPaymentAmount: number;
    barcode: string;
    pixcode: string;
    chargeId: string;
  };
}

export interface IRequestDebtsNegotiationTerms extends IAuthenticated {
  operationId: number;
  enrollment: string;
  handleContinue?: () => void;
}

export interface IResponseDebtsNegotiationTermsAddress {
  street: string;
  city: string;
  zipCode: string;
  houseNumber: string;
  location: string;
}

export interface IResponseDebtsNegotiationTermsDebtAmount {
  invoiceTotal: string;
  debtTotal: string;
  discountAmount: string;
  discountValue: string;
  bonusValue: string;
}

export interface IResponseDebtsNegotiationTermsInstallmentPlan {
  downPaymentAmount: string;
  installmentCount: string;
  installmentInterestRate: string;
  installmentAmount: string;
  lastInstallmentAmount: string;
}

export interface IResponseDebtsNegotiationTerms {
  operationalDistrict: string;
  registrationNumber: string;
  termNumber: string;
  residentClientName: string;
  propertyOwnerName: string;
  address: IResponseDebtsNegotiationTermsAddress;
  residentPhoneNumber: string;
  requester: string;
  companyName: string;
  debtAmount: IResponseDebtsNegotiationTermsDebtAmount;
  locationAndDate: string;
}

export interface IRequestCreateDebtsNegotiation extends IAuthenticated {
  enrollment: string;
  operationId: number;
  type: 'INSTALLMENTS' | 'AT_SIGHT';
  campaignBillingId: number;
  downPaymentAmount: number;
  installmentsId: string;
}
export interface IDataPaymentDebtsNegotiation {
  invoices?: Array<IPaymentInvoices>;
  charges?: Array<IPaymentCharges>;
  debtNegotiation?: IPaymentDebtNegotiation;
  amount: number;
  paymentDebtsIds: Array<string>;
  possible_quantity_installments?: number;
  qr_code_key?: string;
  bar_code_number?: string;
  showDuplicateModal?: {
    totalInvoicesAmount: number;
    totalChargesAmount: number;
  };
  isNegotiation: boolean;
  routeInvoiceReceipt?: string;
}

export interface IRequestDebtsNegotiationListNegotiations {
  page: number;
  size: number;
}
export interface IResponseDebtsNegotiationListNegotiations {
  id: string;
  enrollment: string;
  operation: number;
  amount: number;
  installments: number;
  type: string;
  status: string;
}

export interface IResponseDebtsNegotiationListPagination {
  totalItens: number;
  maxPages: number;
  page: number;
}
export interface IResponseDebtsNegotiationList {
  negotiations: Array<IResponseDebtsNegotiationListNegotiations>;
  pagination: IResponseDebtsNegotiationListPagination;
}

export interface IRequestDebtsNegotiationListDetail {
  id: string;
  enrollment: string;
  operation: number;
}

export interface IResponseDebtsNegotiationListDetailInstallment {
  installment: string;
  amount: number;
  situation: string;
  billingDate: string;
  reference: string;
}
export interface IResponseDebtsNegotiationListDetail {
  id: string;
  paymentConditionDescription: string;
  status: string;
  registration: string;
  installmentId: string;
  totalAmount: string;
  totalAmountWithDiscount: string;
  installmentCount: string;
  installmentAmount: string;
  lastInstallmentAmount: string;
  upfrontInstallmentAmount: string;
  barcode: string;
  pixcode: string;
  chargeId: string;
  negotiationDate: string;
  dueDate: string;
  downPaymentAmount: number;
  situationDate: string;
  installments: Array<IResponseDebtsNegotiationListDetailInstallment>;
}

export interface IDebtNegotiationDetail {
  type: 'open_installments' | 'installment_details' | 'is_paid';
  dataOpenNegotiation?: IResponseCreateDebtsNegotiationSummary;
  dataDetailNegotiation?: IResponseDebtsNegotiationListDetail;
}

export interface IRepositoriesStateNegotiation {
  readonly dataDebtsEnrollments: Array<IResponseDebtsEnrollments> | [];
  readonly enrollmentCheck: IEnrollmentCheck | null;
  readonly dataDebts: IResponseInvoicesDebts | null;
  readonly dataDebtsNegotiation: IResponseDataDebtsNegotiation | null;
  readonly dataDebtsNegotiationInstallments:
    | Array<IResponseDataDebtsNegotiationInstallmentsInstallment>
    | [];
  readonly dataDebtsNegotiationTerms: IResponseDebtsNegotiationTerms | null;
  readonly dataDebtsNegotiationCreate: IResponseCreateDebtsNegotiationSummary | null;
  readonly dataDebtsNegotiationDetail: IDebtNegotiationDetail | null;
  readonly dataDebtsNegotiationPayment: IDataPaymentDebtsNegotiation | null;
  readonly dataDebtsNegotiationList: IResponseDebtsNegotiationList | null;
  readonly dataDebtsNegotiationListDetail: IResponseDebtsNegotiationListDetail | null;
  readonly loadingDebts: boolean;
  readonly loadingDebtsNegotiation: boolean;
  readonly loadingDebtsNegotiationCalculateInstallments: boolean;
  readonly loadingDebtsNegotiationInstallments: boolean;
  readonly loadingDebtsNegotiationTerms: boolean;
  readonly loadingDebtsNegotiationCreate: boolean;
  readonly loadingDebtsNegotiationList: boolean;
  readonly loadingDebtsNegotiationListDetail: boolean;
  readonly errorDebts: IEnumErrorResponse | null;
  readonly errorDebtsNegotiation: IEnumErrorResponse | null;
  readonly errorDebtsNegotiationInstallments: IEnumErrorResponse | null;
  readonly errorDebtsNegotiationTerms: IEnumErrorResponse | null;
  readonly errorDebtsNegotiationCreate: IEnumErrorResponse | null;
  readonly errorDebtsNegotiationList: IEnumErrorResponse | null;
  readonly errorDebtsNegotiationListDetail: IEnumErrorResponse | null;
}
