import styled, { css, keyframes } from 'styled-components';

import { Breakpoints } from '../../styles/breakpoints';
import { Color } from '../../styles/colors';

const fadeIn = keyframes`
  0% {
	opacity: 0;  
  }
  100% { 	
	opacity: 1;  
  }
`;

interface IPropsMain {
  open: boolean;
}

export const Main = styled.div<IPropsMain>`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2000;
  ${({ open }) =>
    open
      ? css`
          visibility: visible;
          -webkit-animation-name: ${fadeIn};
          -webkit-animation-duration: 1s;
          animation-name: ${fadeIn};
          animation-duration: 1s;
        `
      : css`
          visibility: hidden;
        `};
`;

export const Contain = styled.div<{
  color: string;
  v3?: boolean;
  newStyle?: boolean;
  maxWidth?: number;
  maxHeight?: number;
}>`
  width: 70%;
  max-width: calc(661px - 64px);
  padding: 28px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Color.white};
  border-radius: 17px;
  box-shadow: 0px 4px 26px
    ${({ color }) =>
      color === 'info'
        ? 'rgba(52, 187, 218, 0.3)'
        : color === 'error'
        ? 'rgba(228, 35, 19, 0.31)'
        : color === 'success'
        ? 'rgba(0, 204, 0, 0.31)'
        : 'rgba(52, 187, 218, 0.3))'};
  position: relative;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    max-width: var(--modal-max-width);
    max-height: 442px;
    padding: 36px 32px;
  }

  ${({ v3, maxWidth, maxHeight }) =>
    v3 &&
    css`
      @media screen and (min-width: ${Breakpoints.laptop.min}px) {
        width: 100%;
        max-width: ${maxWidth ? `calc(${maxWidth}px - 64px)` : 'calc(570px - 64px)'};
        max-height: ${maxHeight
          ? `calc(${maxHeight}px - 73px)`
          : 'calc(445px - 73px)'};
        padding: 43px 32px 30px 32px;
      }
    `};

  ${({ newStyle }) =>
    newStyle &&
    css`
      box-shadow: none;
      border: 1px solid ${Color.blueIntense};
    `};
`;

interface ITitle {
  type: 'success' | 'error' | 'warning' | 'info';
  v3?: boolean;
  newStyle?: boolean;
}

export const Title = styled.h1<ITitle>`
  font-weight: 350;
  font-size: 28px;
  line-height: 50px;
  text-align: center;
  color: ${(props) =>
    props.type === 'success'
      ? Color.green
      : props.type === 'warning'
      ? Color.yellow
      : props.type === 'error'
      ? Color.red
      : Color.blueSeconde};

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 42px;
  }

  ${({ v3 }) =>
    v3 &&
    css`
      font-weight: 500;
      line-height: 49px;

      @media screen and (min-width: ${Breakpoints.laptop.min}px) {
        font-size: 40px;
      }
    `};

  ${({ newStyle, type }) =>
    newStyle &&
    css`
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: ${type === 'success'
        ? Color.green
        : type === 'warning'
        ? Color.yellow
        : type === 'error'
        ? Color.red
        : Color.blueIntense};

      @media screen and (min-width: ${Breakpoints.laptop.min}px) {
        font-size: 24px;
      }
    `};
`;
