import { Dispatch } from 'redux';
import { RouteName } from '~/routes/Routes.name';
import { getEnrollmentsMeService } from '~/services/enrollmentsAuth';
import {
  getEnrollmentsInvoicesService,
  getEnrollmentsInvoicesDetailService,
  getEnrollmentsInvoicesDownloadService,
  getEnrollmentsInvoicesMaturityDaysService,
  getEnrollmentsInvoicesMaturityDaysAlternateService,
  putEnrollmentsInvoicesMaturityDaysAlternateService,
  getEnrollmentsTypesInvoicesService,
  putEnrollmentsTypesInvoicesService,
  postEnrollmentsInvoiceCreateGroupService,
  getEnrollmentsInvoiceInstallmentGroupService,
  postEnrollmentsInvoiceGroupPaymentService,
  getEnrollmentsInvoiceGroupPaymentVoucherService,
} from '~/services/enrollmentsInvoices';
import Navigation from '~/utils/Navigation';
import onShareFile from '~/utils/onShareFile';
import {
  IResponseEnrollmentsMe,
  RepositoriesTypesAuth,
} from '../enrollmentsAuth/types';
import { IState } from '../types';
import {
  IRequestInvoiceCreateGroup,
  IRequestInvoiceGroupPayment,
  IRequestQueryInvoices,
  IResponseInstallmentGroup,
  IResponseInvoiceCreateGroup,
  IResponseInvoiceGroupVoucher,
  IResponseInvoices,
  IResponseInvoicesDetail,
  IResponseInvoicesDownload,
  IResponseInvoicesMaturityDays,
  IResponseTypesInvoices,
  RepositoriesTypesInvoices,
} from './types';
import { RepositoriesTypesOutsides } from '../outsides/types';

const getEnrollmentsInvoicesRequest =
  (params?: IRequestQueryInvoices) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICES_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getEnrollmentsInvoicesService(
        registration,
        params,
      )) as IResponseInvoices;

      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICES_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getEnrollmentsInvoicesGroupRequest =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICES_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getEnrollmentsInvoicesService(
        registration,
      )) as IResponseInvoices;

      const invoicesGroup = response?.invoices.filter(
        (invoice) =>
          invoice.status.label === 'PENDENTE' || invoice.status.label === 'VENCIDA',
      );

      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICES_METHOD_SUCCESS,
      });

      return Navigation.navigate(RouteName.SINGLEPAYMENT, {
        invoices: invoicesGroup.reverse(),
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getEnrollmentsInvoicesDetailRequest =
  (invoiceId: number, paymentMethod?: string) =>
  async (dispatch: Dispatch, getState: any) => {
    console.log(paymentMethod);
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getEnrollmentsInvoicesDetailService(
        registration,
        invoiceId,
      )) as IResponseInvoicesDetail;

      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });

      return Navigation.navigate(RouteName.SIMPLIFIEDINVOICE);
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getEnrollmentsInvoicesDownloadRequest =
  (invoiceId: number) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getEnrollmentsInvoicesDownloadService(
        registration,
        invoiceId,
      )) as IResponseInvoicesDownload;

      onShareFile({
        buffer: response?.buffer,
      });

      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_SALESFORCE_SATISFACTION_ACTIVATE_MODAL,
      });

      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_FAILURE,
        payload: {
          errorDownload: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getErrorCloseEnrollmentsInvoicesDownload =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesInvoices.ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_FAILURE_CLOSE,
    });
  };

const getEnrollmentsInvoicesMaturityDaysRequest =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getEnrollmentsInvoicesMaturityDaysService(
        registration,
      )) as IResponseInvoicesMaturityDays;

      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_METHOD_FAILURE,
        payload: {
          errorDownload: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getEnrollmentsInvoicesMaturityDaysAlternateRequest =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getEnrollmentsInvoicesMaturityDaysAlternateService(
        registration,
      )) as Array<number>;

      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE,
        payload: {
          errorDownload: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const putEnrollmentsInvoicesMaturityDaysAlternateRequest =
  (day: number) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      await putEnrollmentsInvoicesMaturityDaysAlternateService(registration, day);

      const response = (await getEnrollmentsInvoicesMaturityDaysService(
        registration,
      )) as IResponseInvoicesMaturityDays;

      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });

      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE,
        payload: {
          errorDownload: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const putSuccessCloseMaturityDaysAlternate = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesInvoices.ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS_CLOSE,
  });
};

const putErrorCloseMaturityDaysAlternate = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesInvoices.ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE_CLOSE,
  });
};

const getEnrollmentsTypesInvoicesRequest =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_TYPES_INVOICES_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getEnrollmentsTypesInvoicesService(
        registration,
      )) as IResponseTypesInvoices;

      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_TYPES_INVOICES_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_TYPES_INVOICES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const putEnrollmentsTypesInvoicesRequest =
  (typeInvoiceId: string, typeInvoiceLabel: string) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      await putEnrollmentsTypesInvoicesService(registration, typeInvoiceId);

      const { registrationCheck } = enrollmentsAuth;

      dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_REGISTRATION_CHECK_REQUEST,
        payload: {
          data: {
            ...registrationCheck,
            type_invoice: { id: typeInvoiceId, label: typeInvoiceLabel },
          },
        },
      });

      const responseMe =
        (await getEnrollmentsMeService()) as IResponseEnrollmentsMe[];

      dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_GET_ME_METHOD_SUCCESS,
        payload: {
          data: responseMe,
        },
      });

      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const putSuccessCloseEnrollmentsTypesInvoices = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesInvoices.ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_SUCCESS_CLOSE,
  });
};

const putErrorCloseEnrollmentsTypesInvoices = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesInvoices.ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_FAILURE_CLOSE,
  });
};

const postEnrollmentsInvoiceCreateGroupRequest =
  (invoices: IRequestInvoiceCreateGroup) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await postEnrollmentsInvoiceCreateGroupService(
        invoices,
        registration,
      )) as IResponseInvoiceCreateGroup;

      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_SUCCESS,
        payload: {
          data: { ...response },
        },
      });

      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_REQUEST,
      });

      const responseInstallment =
        (await getEnrollmentsInvoiceInstallmentGroupService({
          invoice_group_id: response.invoice_group_id,
        })) as IResponseInstallmentGroup;

      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_SUCCESS,
        payload: {
          data: { ...responseInstallment },
        },
      });

      const paymentIds = invoices?.invoices?.map((invoice) => invoice.invoice_id);

      return Navigation.navigate(RouteName.SINGLEPAYMENTDATAPAYMENT, {
        paymentIds,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postErrorCloseInvoiceCreateGroup = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_FAILURE_CLOSE,
  });
};

const getEnrollmentsInvoiceInstallmentGroupRequest =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_REQUEST,
      });

      const { enrollmentsInvoices } = getState() as IState;

      const dataInvoiceGroupId = enrollmentsInvoices?.dataInvoiceGroupId;

      const response = (await getEnrollmentsInvoiceInstallmentGroupService({
        invoice_group_id: dataInvoiceGroupId?.invoice_group_id,
      })) as IResponseInstallmentGroup;

      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_SUCCESS,
        payload: {
          data: { ...response },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getErrorCloseInvoiceInstallmentGroup = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_FAILURE_CLOSE,
  });
};

const postEnrollmentsInvoiceGroupPaymentRequest =
  (data: IRequestInvoiceGroupPayment) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_REQUEST,
      });

      const { enrollmentsAuth, enrollmentsInvoices } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const dataInvoiceGroupId = enrollmentsInvoices?.dataInvoiceGroupId;

      await postEnrollmentsInvoiceGroupPaymentService(
        {
          ...data,
        },
        registration,
        { invoice_group_id: dataInvoiceGroupId?.invoice_group_id },
      );

      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_SUCCESS,
      });

      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_REQUEST,
      });

      const response = (await getEnrollmentsInvoiceGroupPaymentVoucherService(
        registration,
        {
          invoice_group_id: dataInvoiceGroupId?.invoice_group_id,
        },
      )) as IResponseInvoiceGroupVoucher;

      dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_SUCCESS,
        payload: {
          data: { ...response },
        },
      });

      return Navigation.navigate(RouteName.SINGLEPAYMENTDATAPAYMENTRECEIPT);
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postErrorCloseInvoiceGroupPayment = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_FAILURE_CLOSE,
  });
};

export {
  getEnrollmentsInvoicesRequest,
  getEnrollmentsInvoicesGroupRequest,
  getEnrollmentsInvoicesDetailRequest,
  getEnrollmentsInvoicesDownloadRequest,
  getErrorCloseEnrollmentsInvoicesDownload,
  getEnrollmentsInvoicesMaturityDaysRequest,
  getEnrollmentsInvoicesMaturityDaysAlternateRequest,
  putEnrollmentsInvoicesMaturityDaysAlternateRequest,
  putSuccessCloseMaturityDaysAlternate,
  putErrorCloseMaturityDaysAlternate,
  getEnrollmentsTypesInvoicesRequest,
  putEnrollmentsTypesInvoicesRequest,
  putSuccessCloseEnrollmentsTypesInvoices,
  postEnrollmentsInvoiceCreateGroupRequest,
  postErrorCloseInvoiceCreateGroup,
  getEnrollmentsInvoiceInstallmentGroupRequest,
  getErrorCloseInvoiceInstallmentGroup,
  postEnrollmentsInvoiceGroupPaymentRequest,
  postErrorCloseInvoiceGroupPayment,
  putErrorCloseEnrollmentsTypesInvoices,
};
