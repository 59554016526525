import styled, { css } from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Container = styled.div`
  display: flex;
  padding: 0 5px 20px 5px;
  margin: 0 auto;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 18px 18px 18px;
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 383px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

interface ICardBorder {
  color: string;
}

export const CardBorder = styled.div<ICardBorder>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 16px;
  background-color: ${({ color }) => color};
  border-radius: 10px 10px 0 0;
`;

export const ContainerCardHeader = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

export const CardDateText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
  text-transform: uppercase;
`;

export const CardStatusText = styled.p`
  color: ${({ color }) => color};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
`;

export const CardInvoHeader = styled.div`
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    height: 36px;
  }
`;

export const CardLine = styled.div`
  width: calc(100% - 32px);
  margin: 0 auto;
  border: 1px solid #e9e9e9;
`;

export const CardInvoiceBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

export const ContainerInvoiceDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px 16px;
`;

export const Separator = styled.div`
  height: 32px;
  margin: 0 29px;
  border: 1px solid ${Color.grayLight};
`;

export const ContainerAutomaticDebit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: ${Color.green};
  }
`;

export const CardIColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;

  & button.first {
    margin-top: 8px;
  }
`;

export const CardIText = styled.span`
  font-size: 11px;
  color: ${Color.gray};
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 12px;
  }
`;

export const CardIValueText = styled(CardIText)<any>`
  color: ${({ isLoading }) => (isLoading ? Color.gray : Color.yellow)};
  font-size: 24px;
  height: 47px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100%;

  &.value-empty {
    justify-content: right;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 26px;
  }
`;

export const PayText = styled(CardIText)`
  color: ${Color.blueIntense};
  font-size: 12px;
  font-weight: 300;
  margin-top: -8px;
  width: 115px;
  background-color: ${Color.white};

  @media (max-width: ${Breakpoints.mobile.max}px) {
    width: 60%;
  }
`;

export const CRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const CCol = styled(CardIColumn)`
  text-align: left;

  & span {
    font-size: 12px;
    color: ${Color.gray};
    font-weight: 500;
    line-height: 15px;
    width: 70%;
  }
  & span.value {
    color: ${Color.blueIntense};
    font-weight: 600;
    white-space: pre;
  }
`;

export const ContainerSeeInvoices = styled.div`
  margin: auto 16px 16px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SeeInvoicesButton = styled.button`
  width: 100px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.realBlack};
  cursor: pointer;
  border-radius: 8px;
  background: ${Color.green};
`;

const textButtonStyles = css`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  height: 36px;
  background: #e9ecef;
  border-radius: 8px;
  cursor: pointer;
`;

export const Button = styled.button`
  ${textButtonStyles};
  height: 36px;
  padding: 0 12px;
`;

export const DetailButton = styled.button`
  ${textButtonStyles};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  height: 48px;
  cursor: pointer;
  padding: 0 12px;
`;
