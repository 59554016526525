import { Reducer } from 'redux';
import {
  RepositoriesTypesNegotiation,
  IRepositoriesStateNegotiation,
} from './types';

const INITIAL_STATE: IRepositoriesStateNegotiation = {
  dataDebtsEnrollments: [],
  enrollmentCheck: null,
  dataDebts: null,
  dataDebtsNegotiation: null,
  dataDebtsNegotiationInstallments: [],
  dataDebtsNegotiationTerms: null,
  dataDebtsNegotiationCreate: null,
  dataDebtsNegotiationDetail: null,
  dataDebtsNegotiationPayment: null,
  dataDebtsNegotiationList: null,
  dataDebtsNegotiationListDetail: null,
  loadingDebts: false,
  loadingDebtsNegotiation: false,
  loadingDebtsNegotiationCalculateInstallments: false,
  loadingDebtsNegotiationInstallments: false,
  loadingDebtsNegotiationTerms: false,
  loadingDebtsNegotiationCreate: false,
  loadingDebtsNegotiationList: false,
  loadingDebtsNegotiationListDetail: false,
  errorDebts: null,
  errorDebtsNegotiation: null,
  errorDebtsNegotiationInstallments: null,
  errorDebtsNegotiationTerms: null,
  errorDebtsNegotiationCreate: null,
  errorDebtsNegotiationList: null,
  errorDebtsNegotiationListDetail: null,
};

const reducer: Reducer<IRepositoriesStateNegotiation, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_REQUEST:
      return {
        ...state,
        loadingDebtsNegotiation: true,
      };
    case RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_ENROLLMENT_CHECK_SUCCESS:
      return {
        ...state,
        enrollmentCheck: action.payload.enrollmentCheck,
      };

    case RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_SUCCESS:
      return {
        ...state,
        loadingDebtsNegotiation: false,
        dataDebtsEnrollments: action.payload.dataDebtsEnrollments,
        dataDebtsNegotiation: {
          ...action.payload.dataDebtsNegotiation,
        },
      };
    case RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_FAILURE:
      return {
        ...state,
        loadingDebtsNegotiation: false,
        dataDebtsNegotiation: null,
        errorDebtsNegotiation: {
          ...action.payload,
        },
      };
    case RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_FAILURE_CLOSE:
      return {
        ...state,
        errorDebtsNegotiation: null,
        dataDebtsEnrollments: [],
        dataDebtsNegotiation: null,
      };

    case RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_INSTALLMENTS_REQUEST:
      return {
        ...state,
        loadingDebtsNegotiationInstallments: true,
      };
    case RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_INSTALLMENTS_SUCCESS:
      return {
        ...state,
        loadingDebtsNegotiationInstallments: false,
        dataDebtsNegotiationInstallments: action.payload,
      };
    case RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_INSTALLMENTS_FAILURE:
      return {
        ...state,
        loadingDebtsNegotiationInstallments: false,
        dataDebtsNegotiationInstallments: [],
        errorDebtsNegotiationInstallments: {
          ...action.payload,
        },
      };
    case RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_INSTALLMENTS_FAILURE_CLOSE:
      return {
        ...state,
        errorDebtsNegotiationInstallments: null,
      };

    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_TERMS_REQUEST:
      return {
        ...state,
        loadingDebtsNegotiationTerms: true,
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_TERMS_SUCCESS:
      return {
        ...state,
        loadingDebtsNegotiationTerms: false,
        dataDebtsNegotiationTerms: {
          ...action.payload,
        },
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_TERMS_FAILURE:
      return {
        ...state,
        loadingDebtsNegotiationTerms: false,
        dataDebtsNegotiationTerms: null,
        errorDebtsNegotiationTerms: {
          ...action.payload,
        },
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_TERMS_FAILURE_CLOSE:
      return {
        ...state,
        errorDebtsNegotiationTerms: null,
      };

    case RepositoriesTypesNegotiation.POST_DEBTS_NEGOTIATION_REQUEST:
      return {
        ...state,
        loadingDebtsNegotiationCreate: true,
      };
    case RepositoriesTypesNegotiation.POST_DEBTS_NEGOTIATION_SUCCESS:
      return {
        ...state,
        loadingDebtsNegotiationCreate: false,
        dataDebtsNegotiationCreate: {
          ...action.payload,
        },
      };
    case RepositoriesTypesNegotiation.POST_DEBTS_NEGOTIATION_FAILURE:
      return {
        ...state,
        loadingDebtsNegotiationCreate: false,
        dataDebtsNegotiationCreate: null,
        errorDebtsNegotiationCreate: {
          ...action.payload,
        },
      };
    case RepositoriesTypesNegotiation.POST_DEBTS_NEGOTIATION_FAILURE_CLOSE:
      return {
        ...state,
        errorDebtsNegotiationCreate: null,
      };

    case RepositoriesTypesNegotiation.POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_REQUEST:
      return {
        ...state,
        loadingDebtsNegotiationCalculateInstallments: true,
      };
    case RepositoriesTypesNegotiation.POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_SUCCESS:
      return {
        ...state,
        loadingDebtsNegotiationCalculateInstallments: false,
      };
    case RepositoriesTypesNegotiation.POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_FAILURE:
      return {
        ...state,
        loadingDebtsNegotiationCalculateInstallments: false,
      };

    case RepositoriesTypesNegotiation.SET_DATA_PAYMENT_DEBTS_NEGOTIATION:
      return {
        ...state,
        dataDebtsNegotiationPayment: {
          ...action.payload,
        },
      };
    case RepositoriesTypesNegotiation.CLEAR_DATA_PAYMENT_DEBTS_NEGOTIATION:
      return {
        ...state,
        dataDebtsNegotiationPayment: null,
      };

    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_AUTH_REQUEST:
      return {
        ...state,
        loadingDebts: true,
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_AUTH_SUCCESS:
      return {
        ...state,
        loadingDebts: false,
        errorDebts: null,
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_AUTH_FAILURE:
      return {
        ...state,
        loadingDebts: false,
        errorDebts: {
          ...action.payload,
        },
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_AUTH_FAILURE_CLOSE:
      return {
        ...state,
        errorDebts: null,
      };

    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_REQUEST:
      return {
        ...state,
        loadingDebtsNegotiationList: true,
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_SUCCESS:
      return {
        ...state,
        loadingDebtsNegotiationList: false,
        dataDebtsNegotiationList: {
          ...action.payload.data,
        },
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_FAILURE:
      return {
        ...state,
        loadingDebtsNegotiationList: false,
        errorDebtsNegotiationList: {
          ...action.payload,
        },
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_FAILURE_CLOSE:
      return {
        ...state,
        errorDebtsNegotiationList: null,
      };

    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_DETAIL_REQUEST:
      return {
        ...state,
        loadingDebtsNegotiationListDetail: true,
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDebtsNegotiationListDetail: false,
        dataDebtsNegotiationListDetail: {
          ...action.payload.data,
        },
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_DETAIL_FAILURE:
      return {
        ...state,
        loadingDebtsNegotiationListDetail: false,
        errorDebtsNegotiationListDetail: {
          ...action.payload,
        },
      };
    case RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_DETAIL_FAILURE_CLOSE:
      return {
        ...state,
        errorDebtsNegotiationListDetail: null,
      };

    case RepositoriesTypesNegotiation.SET_DATA_DETAIL_DEBTS_NEGOTIATION:
      return {
        ...state,
        dataDebtsNegotiationDetail: {
          ...action.payload,
        },
      };
    case RepositoriesTypesNegotiation.CLEAR_DATA_DETAIL_DEBTS_NEGOTIATION:
      return {
        ...state,
        dataDebtsNegotiationDetail: null,
      };

    default:
      return state;
  }
};

export default reducer;
