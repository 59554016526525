export const colors = {
  black: '#000000',
  white: '#ffffff',
  grey_10: '#F8F9FA',
  grey_20: '#EBECED',
  grey_30: '#BCC3CA',
  grey_40: '#A1A7AC',
  grey_50: '#6A7279',
  grey_60: '#5A636B',
  grey_70: '#444B52',
  grey_80: '#32373D',
  grey_90: '#24272B',
  grey_100: '#202022',
  main_10: '#E6EDEE',
  main_20: '#CCDBDD',
  main_30: '#B3C9CC',
  main_40: '#99B7BB',
  main_50: '#80A5AA',
  main_60: '#669399',
  main_70: '#4D8188',
  main_80: '#336F77',
  main_90: '#1A5D66',
  main_100: '#004B55',
  aqua_10: '#EBF8FA',
  aqua_20: '#D7F0F4',
  aqua_30: '#9BDAE4',
  aqua_40: '#5FC3D3',
  aqua_50: '#37B4C8',
  aqua_60: '#32A2B4',
  aqua_70: '#2C90A0',
  aqua_80: '#277E8C',
  aqua_90: '#1C5A64',
  aqua_100: '#164850',
  green_10: '#EBF8F3',
  green_20: '#C3E9DA',
  green_30: '#9BDAC1',
  green_40: '#5FC39B',
  green_50: '#37B482',
  green_60: '#32A275',
  green_70: '#31936C',
  green_80: '#277E5B',
  green_90: '#1C5A41',
  green_100: '#164834',
  red_10: '#FDF1EF',
  red_20: '#F8D5CF',
  red_30: '#F0AB9F',
  red_40: '#E9816F',
  red_50: '#E6735F',
  red_60: '#CF6856',
  red_70: '#A15143',
  red_80: '#8A4539',
  red_90: '#733A30',
  red_100: '#45221C',
  yellow_10: '#FFF8E8',
  yellow_20: '#FEE9BA',
  yellow_30: '#FDDA8C',
  yellow_40: '#FBC347',
  yellow_50: '#FAB419',
  yellow_60: '#E1A217',
  yellow_70: '#C89014',
  yellow_80: '#AF7E12',
  yellow_90: '#7D5A0D',
  yellow_100: '#322405',
};
