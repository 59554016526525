import React, { useEffect } from 'react';
import { Container, Content, DescriptionText } from './styles';
import SummaryPendingIssues from '~/components/Negotation/NegotiationHome/MyPendingIssues/SummaryPendingIssues';
import PendingDetails from '~/components/Negotation/NegotiationHome/MyPendingIssues/PendingDetails';
// import { RouteName } from '~/routes/Routes.name';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import SkeletonCustom from '~/components/Skeleton';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import { getDebtsNegotiationAuthRequest } from '~/store/modules/negotiation/actions';
import { getErrorCloseDischargeDebts } from '~/store/modules/outsides/actions';
import WarningBox from '~/design_system/components/WarningBox';

const Negotiations = () => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const loadingDebtsNegotiation = useSelector(
    (item: IState) => item.negotiation.loadingDebts,
  );
  const errorDebts = useSelector((item: IState) => item.negotiation.errorDebts);

  // const dataDischargeDebts = useSelector(
  //   (item: IState) => item.outsides.dataDischargeDebts,
  // );

  // const chargesQuantity = dataDischargeDebts?.info?.charges?.totalItems || 0;
  // const invoicesQuantity = dataDischargeDebts?.info?.invoices?.totalItems || 0;
  // const hasOpenDebts = chargesQuantity > 0 || invoicesQuantity > 0;

  const getDebts = () => {
    dispatch(getDebtsNegotiationAuthRequest());
    dispatch(getErrorCloseDischargeDebts());
  };

  useEffect(() => {
    getDebts();
  }, []);

  return (
    <Container>
      <DescriptionText>
        Aqui você encontra um resumo das pendências vinculadas ao seu CPF/CNPJ.
      </DescriptionText>
      <Content>
        {loadingDebtsNegotiation ? (
          <>
            <SkeletonCustom width={isMobile ? undefined : 252} height={75} />
            <SkeletonCustom height={99} />
          </>
        ) : errorDebts ? (
          <WarningBox
            label="A página não pôde ser carregada. Tente novamente mais tarde!"
            textButton="Atualizar"
            onClickButton={getDebts}
          />
        ) : (
          <>
            <SummaryPendingIssues showButtonHome />
            <PendingDetails />
            {/* {hasOpenDebts && (
                  <LinkText to={RouteName.DEBTNEGOTIATION}>
                    Veja aqui opções de negociação
                  </LinkText>
                )} */}
          </>
        )}
      </Content>
    </Container>
  );
};

export default Negotiations;
