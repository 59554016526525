import styled from 'styled-components';

export const Main = styled.main`
  padding: 80px 20px;
  max-width: 560px;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  div {
    :first-of-type {
      min-height: inherit;
    }
  }
`;
