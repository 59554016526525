import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import {
  Main,
  MainCard,
  TitleText,
  DescriptionText,
  LinkRegistrationButton,
  ContainerTitle,
  ContainerInfo,
  InfoEnrollmentButton,
  ContainerMainBackgroundIcon,
  ContainerCards,
  ContentCards,
} from './styles';
import { Dialog } from '~/components/Dialog';
import {
  getErrorClosePartnersRequest,
  getPartnersRequest,
} from '~/store/modules/outsides/actions';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { MainBackgroundIcon } from './icons';
import InfoEnrollment from './InfoEnrollment';
import ContactUs from './ContactUs';
import { BannerApp } from '~/design_system/components/BannerApp';
import CardsBottom from './CardsBottom';
import { Breakpoints } from '~/styles';
import ContentServices from './ContentServices';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import ModalInfo from '~/design_system/components/ModalInfo';

const NotClient: FC = () => {
  const isDesktop = useMediaQuery({ minWidth: Breakpoints.desktopLarge.min });

  const dispatch = useDispatch();

  const loading = useSelector((item: IState) => item.outsides.loadingPartners);

  const [modalInfoEnrollment, setModalInfoEnrollment] = useState<boolean>(false);
  const [modalContactUs, setModalContactUs] = useState<boolean>(false);

  const handleChangeModalInfoEnrollment = () =>
    setModalInfoEnrollment(!modalInfoEnrollment);

  const handleChangeModalContactUs = () => setModalContactUs(!modalContactUs);

  useEffect(() => {
    dispatch(getErrorClosePartnersRequest());
    dispatch(getPartnersRequest());
  }, []);

  return (
    <Main>
      {loading && <LoadingComponent />}
      <Dialog open={modalInfoEnrollment} onClose={handleChangeModalInfoEnrollment}>
        <ModalInfo
          onClose={handleChangeModalInfoEnrollment}
          title="Encontre seu número de matrícula!"
        >
          <InfoEnrollment />
        </ModalInfo>
      </Dialog>
      <Dialog open={modalContactUs} onClose={handleChangeModalContactUs}>
        <ModalInfo
          onClose={handleChangeModalContactUs}
          title="Vazamentos e falta d’água"
        >
          <ContactUs />
        </ModalInfo>
      </Dialog>
      <ContentCards>
        <ContainerCards>
          <ContentCards>
            <MainCard>
              <ContainerTitle>
                <TitleText>Bem vindo!</TitleText>
              </ContainerTitle>
              <ContainerInfo>
                <ContainerMainBackgroundIcon>
                  <MainBackgroundIcon />
                </ContainerMainBackgroundIcon>
                <TitleText>Você tem um número de matrícula?</TitleText>
                <DescriptionText>
                  Se você já teve acesso a um número de matrícula que corresponde ao
                  seu imóvel ou hidrômetro prossiga para vincular o número ao seu
                  cadastro.
                </DescriptionText>
                <LinkRegistrationButton
                  type="button"
                  onClick={() => Navigation.navigate(RouteName.LINKENROLLMENT)}
                >
                  Vincular matrícula
                </LinkRegistrationButton>
                <InfoEnrollmentButton
                  type="button"
                  onClick={handleChangeModalInfoEnrollment}
                >
                  Onde encontrar meu número de matrícula?
                </InfoEnrollmentButton>
                <DescriptionText>
                  Vinculando a matrícula você terá acesso aos serviços abaixo:
                </DescriptionText>
                <ContentServices />
              </ContainerInfo>
            </MainCard>
            {isDesktop && (
              <CardsBottom handleChangeModalContactUs={handleChangeModalContactUs} />
            )}
          </ContentCards>
          <BannerApp />
        </ContainerCards>
        {!isDesktop && (
          <CardsBottom handleChangeModalContactUs={handleChangeModalContactUs} />
        )}
      </ContentCards>
    </Main>
  );
};

export default NotClient;
