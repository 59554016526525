import React, { FC, useState } from 'react';
import ReactGA from 'react-ga4';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  Title,
  Border,
  TextId,
  Content,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import ContinueButton from './ContinueButton';
import { Color } from '~/styles';
import { INegotiationDetailProps } from './types';
import LoadingComponent from '../../Loading';
import IsPaid from './IsPaid';
import OpenInstallments from './OpenInstallments';
import InstallmentDetails from './InstallmentDetails';
import { Dialog } from '../../Dialog';
import ModalSuccess from './ModalSuccess';
import BackButton from './BackButton';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { setRouteAuthRedirect } from '~/store/modules/enrollmentsAuth/actions';

const NegotiationDetail: FC<INegotiationDetailProps> = ({ links, loading }) => {
  const dispatch = useDispatch();

  const token = useSelector((item: IState) => item?.enrollmentsAuth?.token);
  const dataDebtsNegotiationDetail = useSelector(
    (item: IState) => item?.negotiation?.dataDebtsNegotiationDetail,
  );

  console.log({ dataDebtsNegotiationDetail });

  const [openSuccess, setOpenSuccess] = useState<boolean>(false);

  const handleOnClose = () => {
    setOpenSuccess(false);
  };

  const handleNavigateNegotiations = () => {
    if (token) {
      Navigation.navigate(RouteName.NEGOTIATIONS);
    } else {
      dispatch(setRouteAuthRedirect(RouteName.NEGOTIATIONS));
      Navigation.navigate(RouteName.LOGIN);
    }
  };

  const dataId =
    dataDebtsNegotiationDetail?.dataOpenNegotiation?.id ||
    dataDebtsNegotiationDetail?.dataDetailNegotiation?.id;

  return (
    <Main>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      {openSuccess && (
        <Dialog open onClose={handleOnClose} fullScreen>
          <ModalSuccess onClose={handleOnClose} />
        </Dialog>
      )}
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card>
        <Title>Detalhes da negociação</Title>
        <Border />
        {dataDebtsNegotiationDetail?.type !== 'installment_details' && (
          <TextId>ID {dataId}</TextId>
        )}
        {dataDebtsNegotiationDetail?.type === 'open_installments' && (
          <OpenInstallments />
        )}
        {dataDebtsNegotiationDetail?.type === 'installment_details' && (
          <>
            <Content>
              <InstallmentDetails />
            </Content>
            <ContinueButton
              label="Pagar entrada"
              backgroundColor={Color.green}
              onClick={() => {
                ReactGA.event({
                  category: 'Negociação de dívidas',
                  action:
                    '[Negociação de dívidas][Detalhes da negociação] Pagar entrada',
                });

                Navigation.navigate(
                  RouteName.DEBTNEGOTIATIONSINGLEPAYMENTDATAPAYMENT,
                );
              }}
            />
            <BackButton
              label="Minhas negociações"
              onClick={handleNavigateNegotiations}
            />
          </>
        )}
        {dataDebtsNegotiationDetail?.type === 'is_paid' && (
          <Content>
            <IsPaid />
          </Content>
        )}
      </Card>
    </Main>
  );
};

export default NegotiationDetail;
