import styled from 'styled-components';
import { Color } from '~/styles';

interface Props {
  isActive?: boolean;
  stepPosition?: number;
}

export const Wrapper = styled.div`
  width: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const StepNumber = styled.div<Props>`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  background: ${(p) =>
    p.isActive || (p.isActive && p.stepPosition !== 2)
      ? Color.blueIntense
      : Color.gray};
  border-radius: 36px;
`;

export const StepLabel = styled.div<Props>`
  position: absolute;
  top: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(p) =>
    p.isActive || (p.isActive && p.stepPosition !== 2)
      ? Color.blueIntense
      : Color.gray};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
`;

export const StepDivider = styled.div<Props>`
  margin-top: 16px;
  width: 100%;
  height: 3px;
  background: ${(p) => (p.isActive ? Color.blueIntense : Color.gray)};
`;
