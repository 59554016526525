import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
// import { getDebtsNegotiationRequest } from '~/store/modules/negotiation/actions';
// import { history } from '~/store';
import HomeNegotiation from '~/components/Negotation/NegotiationHome';

// interface IDebtNegotiationHistory {
//   cnp: string;
// }

const DebtNegotiation: FC = () => {
  // const data = history.location.state as IDebtNegotiationHistory;

  // const dispatch = useDispatch();

  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );
  const token = useSelector((item: IState) => item.enrollmentsAuth.token);

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(token ? RouteName.HOME : RouteName.LANDING),
    },
    {
      label: 'Negociação de dívidas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DISCHARGEDEBTS),
    },
    {
      label: 'Dívidas em aberto',
      active: true,
    },
  ];

  useEffect(() => {
    // dispatch(getDebtsNegotiationRequest({ cnp: data?.cnp }));
    //
    if (
      (dataDischargeDebts?.info?.total?.expiredQuantity || 0) < 1 &&
      (dataDischargeDebts?.info?.total?.pendingQuantity || 0) < 1
    ) {
      ReactGA.event({
        category: 'Negociação de dívidas',
        action: '[Negociação de dívidas][Tela de dívidas] Sem pendências',
      });
    }
  }, []);

  if (token) delete links[1];

  return <HomeNegotiation links={links} />;
};

export default DebtNegotiation;
