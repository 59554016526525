import React, { FC, ReactNode, useEffect, useState } from 'react';
import {
  Container,
  ContainerAutocomplete,
  DescriptionText,
  Divisor,
  Title,
} from './styles';
import Autocomplete from '~/common/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { getDebtsNegotiationRequest } from '~/store/modules/negotiation/actions';
import { history } from '~/store';

interface IEnrollments {
  value: string;
  label: ReactNode;
}

interface ICardTitleProps {
  label: string;
  showSelect?: boolean;
  enrollments?: Array<IEnrollments>;
  showDescription?: boolean;
}

const CardTitle: FC<ICardTitleProps> = ({
  label,
  showSelect,
  enrollments,
  showDescription,
}) => {
  const data = history.location.state as any;

  const dispatch = useDispatch();

  const token = useSelector((item: IState) => item.enrollmentsAuth.token);
  const enrollmentsUsersCnp = useSelector(
    (item: IState) => item.enrollmentsUsers?.data?.cnp,
  );
  const dataDebtsEnrollments = useSelector(
    (item: IState) => item.negotiation.dataDebtsEnrollments,
  );
  const cnp = token ? enrollmentsUsersCnp : data?.cnp;

  const enrollment =
    enrollments &&
    enrollments.find((item) => item.value === dataDebtsEnrollments[0]?.enrollment);

  const [enrollmentSelected, setEnrollmentSelected] = useState<IEnrollments>(
    enrollment || {
      value: '',
      label: '',
    },
  );

  useEffect(() => {
    enrollment && setEnrollmentSelected(enrollment);
  }, []);

  return (
    <>
      <Container>
        <Title>{label}</Title>
      </Container>
      {showDescription && (
        <DescriptionText>
          Aqui você encontra um resumo das pendências vinculadas ao seu CPF/CNPJ.
        </DescriptionText>
      )}
      {showSelect && enrollments && (
        <ContainerAutocomplete>
          <Autocomplete
            label="Selecionar matrícula"
            options={enrollments || []}
            name="registration"
            value={enrollmentSelected?.value}
            onSelectOption={(ev: any) => {
              const enrollmentInfo = enrollments.find(
                (item) => item.value === ev.value,
              );

              const debtEnrollment: any = dataDebtsEnrollments?.find(
                (item) => item.enrollment === ev.value,
              );

              setEnrollmentSelected(
                enrollmentInfo || {
                  value: '',
                  label: '',
                },
              );

              dispatch(
                getDebtsNegotiationRequest({
                  cnp,
                  filter: {
                    ...debtEnrollment,
                  },
                }),
              );
            }}
            disabledInput
          />
        </ContainerAutocomplete>
      )}
      <Divisor />
    </>
  );
};

export default CardTitle;
