export enum IEnumButtonTypeButton {
  DEFAULT = 'default',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum IEnumButtonVariant {
  TEXT = 'text',
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
}
