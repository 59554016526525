/* eslint-disable react/no-unescaped-entities */
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import ReactGA from 'react-ga4';

import { ContainerDescription, Description, DescriptionSmall } from '../styles';
import { Color } from '~/styles';
import ContinueButton from '../ContinueButton';
import { history } from '~/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDebtsNegotiationInstallmentsRequest,
  postDebtsNegotiationRequest,
} from '~/store/modules/negotiation/actions';
import { IDebtNegotiationSimulationHistory } from '..';
import { IState } from '~/store/modules/types';
import { AmountSuccessText } from './styles';
import SuccessBox from '~/design_system/components/SuccessBox';
import Slider from '~/design_system/components/Slider';

interface IStepOneProps {
  setStep: Dispatch<SetStateAction<number>>;
  setEntryValue: Dispatch<SetStateAction<number>>;
}

const StepOne: FC<IStepOneProps> = ({ setStep, setEntryValue }) => {
  const data = history.location.state as IDebtNegotiationSimulationHistory;

  const dispatch = useDispatch();

  const enrollmentCheck = useSelector(
    (item: IState) => item.negotiation.enrollmentCheck,
  );
  const minLimit = data?.campaignPaymentConditions?.minimumDownPayment;
  const maxLimit = data?.maximumDownPayment;

  const [value, setValue] = useState<number>(minLimit);
  const hasMaximumValue = value >= maxLimit;
  const backgroundColor = hasMaximumValue ? Color?.green : Color?.blueSeconde;

  const handleContinue = () => {
    setEntryValue(value);
    setStep(1);
  };

  const handleOnClick = () => {
    if (hasMaximumValue) {
      ReactGA.event({
        category: 'Negociação de dívidas',
        action:
          '[Negociação de dívidas][Simule uma negociação] Etapa 01 – Continuar com pagamento a vista.',
      });

      dispatch(
        postDebtsNegotiationRequest({
          enrollment: enrollmentCheck?.enrollment || '',
          operationId: enrollmentCheck?.operationId || 0,
          type: 'AT_SIGHT',
          campaignBillingId: data?.campaignPaymentConditionId || 0,
          downPaymentAmount: maxLimit,
          installmentsId: '0|0,00|0,00',
        }),
      );
    } else {
      ReactGA.event({
        category: 'Negociação de dívidas',
        action:
          '[Negociação de dívidas][Simule uma negociação] Etapa 01 – Continuar para escolher parcela.',
      });

      dispatch(
        getDebtsNegotiationInstallmentsRequest({
          enrollment: enrollmentCheck?.enrollment || '',
          operationId: enrollmentCheck?.operationId || 0,
          params: {
            downPaymentAmount: value,
            campaignBillingConditionId:
              data?.campaignPaymentConditions?.campaignPaymentConditionId,
          },
          handleContinue,
        }),
      );
    }
  };

  return (
    <>
      <ContainerDescription>
        <Description>
          Digite ou arraste o seletor para ajustar o valor da entrada.
        </Description>
        <DescriptionSmall>
          O restante será cobrado parcelado na fatura.
        </DescriptionSmall>
      </ContainerDescription>
      <Slider
        minLimit={minLimit}
        maxLimit={maxLimit}
        value={value}
        setValue={setValue}
      />
      {hasMaximumValue && (
        <SuccessBox title="Ganhe ainda mais desconto!" isCongratulationsIcon>
          Você selecionou o valor total da dívida e pode ganhar ainda mais desconto!
          <br />
          Clique em "continuar" para{' '}
          <AmountSuccessText>pagar à vista </AmountSuccessText> ou diminua o valor da
          entrada para continuar na simulação de parcelamento.
        </SuccessBox>
      )}
      <ContinueButton
        label="Continuar"
        backgroundColor={backgroundColor}
        onClick={handleOnClick}
      />
    </>
  );
};

export default StepOne;
