/* eslint-disable prefer-const */
import React, { FC } from 'react';
import ReactGA from 'react-ga4';

import {
  ButtonPayment,
  Container,
  LabelButtonPayment,
  NextLabelButtonPayment,
} from './styles';
import BoxDetail from './BoxDetail';
import { ArrowIcon } from '../../Icons';
import BarChart from './BarChart';
import { Breakpoints } from '~/styles';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { IDados, IDadosDebts } from '~/store/modules/outsides/types';
import { history } from '~/store';

interface ISummaryPendingIssuesProps {
  showButtonHome?: boolean;
}

const SummaryPendingIssues: FC<ISummaryPendingIssuesProps> = ({
  showButtonHome,
}) => {
  const dataHistory = history.location.state as any;

  const isLaptop = useMediaQuery({ minWidth: Breakpoints.laptop.min });

  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );

  const expiredQuantity = dataDischargeDebts?.info?.total?.expiredQuantity || 0;
  const pendingQuantity = dataDischargeDebts?.info?.total?.pendingQuantity || 0;
  const hasPendingIssues = expiredQuantity > 0 || pendingQuantity > 0;

  const chargesQuantity = dataDischargeDebts?.info?.charges?.totalItems || 0;
  const invoicesQuantity = dataDischargeDebts?.info?.invoices?.totalItems || 0;
  const hasOpenDebts = chargesQuantity > 0 || invoicesQuantity > 0;

  const handleNavigation = () => {
    ReactGA.event({
      category: 'Negociação de dívidas',
      action: '[Negociação de dívidas][Tela de dívidas] Pagar',
    });

    let data: Array<any> = [];

    dataDischargeDebts?.dados?.forEach((item: IDados) => {
      const updatedDebts = item?.debts?.map((debt: IDadosDebts) => ({
        ...debt,
        active: true,
      }));

      data?.push({
        ...item,
        debts: updatedDebts,
        activeAll: true,
      });
    });

    Navigation.navigate(RouteName.DEBTNEGOTIATIONSINGLEPAYMENT, {
      cnp: dataHistory?.cnp || '',
      dados: data,
    });
  };

  return (
    <Container>
      {isLaptop && hasPendingIssues && <BarChart />}
      <BoxDetail />
      {hasOpenDebts &&
        (showButtonHome ? (
          <ButtonPayment type="button" onClick={handleNavigation} showButtonHome>
            <NextLabelButtonPayment showButtonHome>
              <span>Parcelar meus débitos</span>
              <div>
                <ArrowIcon color="#FFF" />
              </div>
            </NextLabelButtonPayment>
            <LabelButtonPayment showButtonHome>
              Até 12x sem juros no cartão
            </LabelButtonPayment>
          </ButtonPayment>
        ) : (
          <ButtonPayment type="button" onClick={handleNavigation}>
            <LabelButtonPayment>Até 12x sem juros no cartão</LabelButtonPayment>
            <NextLabelButtonPayment>
              <span>Pagar</span>
              <div>
                <ArrowIcon />
              </div>
            </NextLabelButtonPayment>
          </ButtonPayment>
        ))}
    </Container>
  );
};

export default SummaryPendingIssues;
