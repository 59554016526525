import styled from 'styled-components';
import { Color } from '~/styles';

export const Button = styled.button`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: none;
  border-radius: 8px;
  cursor: pointer;
`;

export const TextButton = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  color: ${Color.blueSeconde};
  text-decoration-line: underline;
`;
