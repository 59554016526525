import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';
import { Color } from '~/styles/colors';

export const Main = styled.main`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 12px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 56px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 480px;
  align-items: center;
  justify-content: space-between;
  margin: 0px 60px;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    width: 350px;
  }
`;

export const RowTextError = styled(Row)`
  justify-content: flex-end;
  height: 20px;
`;

export const TextError = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${Color.red};
  text-align: right;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    font-size: 10px;
    font-weight: 400;
  }
`;

export const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  button {
    margin: 0 auto;
  }
`;
