import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Navigation from '~/utils/Navigation';

import { IState } from '~/store/modules/types';
import Payment from '~/components/Payment';
import { INegotationProps } from '../types';

const NegotiationPayment: FC<INegotationProps> = ({ links }) => {
  const loading = useSelector((item: IState) => item?.enrollmentsPayment?.loading);
  const token = useSelector((item: IState) => item?.enrollmentsAuth.token);
  const data = useSelector(
    (item: IState) => item?.negotiation?.dataDebtsNegotiationPayment,
  );
  const dataRegistrationUpdate = useSelector(
    (item: IState) => item?.outsides?.dataRegistrationUpdate,
  );
  const invoices = data?.invoices || undefined;
  const charges = data?.charges || undefined;
  const debtNegotiation = data?.debtNegotiation || undefined;

  const loadingLabel = useMemo(() => {
    if (loading) return 'Processando...';
    return '';
  }, [loading]);

  const backNavigation = () => Navigation.goBack();

  console.log({ invoices, charges });

  return (
    <Payment
      links={links}
      loading={loading}
      loadingLabel={loadingLabel}
      paymentUnavailable={{
        pix: !data?.qr_code_key,
        billet: !data?.bar_code_number,
      }}
      typePayment="single_payment"
      paymentData={{
        invoices,
        charges,
        debtNegotiation,
        amount: data?.amount || 0,
        possible_quantity_installments: data?.possible_quantity_installments || 1,
        qr_code_key: data?.qr_code_key,
        bar_code_number: data?.bar_code_number,
        routeInvoiceReceipt: data?.routeInvoiceReceipt || '',
      }}
      operation={{
        company_name: dataRegistrationUpdate?.company_name || '',
        cnp: dataRegistrationUpdate?.cnp_alert || '',
      }}
      handleBackNavigation={backNavigation}
      buttonBilletDisabled
      showDuplicateModal={data?.showDuplicateModal || undefined}
      isNegotiation={data?.isNegotiation}
      isAuthenticated={!!token}
    />
  );
};

export default NegotiationPayment;
