import React, { FC } from 'react';
import { ButtonDetail, ContentRow, Main, Text, TextHeader } from './styles';
import { Breakpoints, Color } from '~/styles';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import { useMediaQuery } from 'react-responsive';

const OpenInstallments: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dataDebtsNegotiationDetail = useSelector(
    (item: IState) => item?.negotiation?.dataDebtsNegotiationDetail,
  );

  const getBackgroundColor = (index: number) => {
    return index % 2 === 0 ? '#F8F9FA' : Color.white;
  };

  return (
    <Main>
      <ContentRow backgroundColor="#e9ecef">
        <TextHeader>Parcela</TextHeader>
        <TextHeader>Valor</TextHeader>
        <TextHeader>Situação</TextHeader>
        {!isMobile && <TextHeader>Ref. faturamento</TextHeader>}
      </ContentRow>
      {dataDebtsNegotiationDetail?.dataDetailNegotiation?.installments?.map(
        (installments, index) => (
          <ContentRow
            key={index && index.toString()}
            backgroundColor={
              installments?.situation?.toUpperCase() === 'QUITADA'
                ? Color.greenLight
                : getBackgroundColor(index)
            }
          >
            <Text>{installments?.installment}</Text>
            <Text>{formatter.formatCurrency(installments?.amount)}</Text>
            {installments?.situation?.toUpperCase() === 'FATURADA' ? (
              <ButtonDetail type="button">{installments?.situation}</ButtonDetail>
            ) : (
              <Text>{installments?.situation}</Text>
            )}
            {!isMobile && <Text>{installments?.reference || '-'}</Text>}
          </ContentRow>
        ),
      )}
    </Main>
  );
};

export default OpenInstallments;
