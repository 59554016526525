import React, { FC } from 'react';
import { TitleStyled } from './styles';

interface IProps {
  label: string;
}

export const DSTitle: FC<IProps> = ({ label }) => {
  return <TitleStyled>{label}</TitleStyled>;
};
