import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '~/design_system/styles/colors';

import { Breakpoints } from '~/styles/breakpoints';

export const Main = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 12px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 56px;
  }
`;

export const ContainerDivisor = styled.div`
  width: 100%;
  margin: 16px 0;
`;

export const TextRegisterStyled = styled.p`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: ${colors.grey_50};
  text-align: center;

  a {
    font-weight: 600;
    color: ${colors.aqua_60};
    text-decoration: none;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 16px;
  }
`;

export const ContainerForgotPassword = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 2px 0;

  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: ${colors.aqua_60};
    text-decoration: none;
  }
`;

export const TextLinkRegister = styled(Link)`
  color: ${colors.aqua_60};
  text-decoration: none;
`;
