import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

import HomeClient from '../HomeClient';
import HomeManager from '../HomeManager';
import HomeNotClient from '../HomeNotClient';
import { checkToggle } from '~/utils/toggles';
import HomeNotClientNew from '../HomeNotClientNew';
import HomeClientNew from '../HomeClientNew';
import NotClient from '../NotClient';

const ResolveHome: FC = () => {
  const isManager = useSelector((state: IState) => state.enrollmentsAuth.isManager);
  const isClient = useSelector((state: IState) => state.enrollmentsAuth.isClient);
  const isEntrepreneur = useSelector(
    (state: IState) => state.enrollmentsAuth.isEntrepreneur,
  );
  const togglesEnrollment = useSelector(
    (item: IState) => item.togglesEnrollment.data,
  );
  const togglesMe = useSelector((item: IState) => item.togglesMe.data);
  const [reRegistration, setReRegistration] = useState<boolean>(true);
  const [homeV3, setHomeV3] = useState<boolean>(true);

  const verifyTogglesMe = async () => {
    setReRegistration(await checkToggle(togglesMe, 'RE_REGISTRATION_2'));
  };

  const verifyTogglesEnrollment = async () => {
    setHomeV3(await checkToggle(togglesEnrollment, 'HOME_V3'));
  };

  useEffect(() => {
    if (togglesMe && togglesMe?.length > 0) verifyTogglesMe();
  }, [togglesMe]);

  useEffect(() => {
    if (togglesEnrollment && togglesEnrollment?.length > 0)
      verifyTogglesEnrollment();
  }, [togglesEnrollment]);

  if (isManager || isEntrepreneur) return <HomeManager />;

  if (!isClient) {
    return reRegistration ? <NotClient /> : <HomeNotClient />;
  }

  return homeV3 ? <HomeClientNew /> : <HomeClient />;
};

export default ResolveHome;
