import React from 'react';

const SuccessIcon = () => {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.45801 11L0.708008 6.25L1.89551 5.0625L5.45801 8.625L13.1038 0.979168L14.2913 2.16667L5.45801 11Z"
        fill="#37B482"
      />
    </svg>
  );
};

export { SuccessIcon };
