import styled from 'styled-components';

export const ContainerQRCode = styled.div`
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 19px 16px;
  margin-top: 24px;
`;

export const BarCodeImg = styled.img`
  width: 100%;
  height: 72px;
`;
