import styled from 'styled-components';

export const ContainerChangeAmount = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ContainerInstallments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #f8f9fa;
  border-radius: 4px;
  padding: 16px;
`;

export const ContainerMoreInstallments = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
