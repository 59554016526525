import styled from 'styled-components';
import { colors } from '~/design_system/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Option = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const Radio = styled.input`
  display: none;
`;

export const CustomRadio = styled.div<{ isActive: boolean }>`
  width: calc(20px - 1.25px);
  height: calc(20px - 1.25px);
  border: 1px solid ${({ isActive }) => (isActive ? colors.aqua_50 : '#ADB5BD')};
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? colors.aqua_50 : colors.white)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ isActive }) => (isActive ? colors.white : 'transparent')};
    transition: all 0.3s ease;
  }
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: ${colors.grey_100};
  cursor: pointer;
`;
