import styled from 'styled-components';
import { Color } from '~/styles';

export const Button = styled.button<{ background: string }>`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: ${({ background }) => background};
  border-radius: 8px;
  cursor: pointer;
  margin-top: 16px;
`;

export const TextButton = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.blackSeconde};
`;
