import React, { FC } from 'react';
import { Container, Option, Radio, CustomRadio, Label } from './styles';
import { IRadioCustomProps } from './types';

export const RadioCustom: FC<IRadioCustomProps> = ({
  options,
  selected,
  setSelected,
}) => {
  return (
    <Container>
      {options.map((option) => (
        <Option key={option.value} onClick={() => setSelected(option.value)}>
          <Radio
            type="radio"
            id={option.value}
            name={`${option.value}-option`}
            value={option.value}
            checked={selected === option.value}
            onChange={() => setSelected(option.value)}
          />
          <CustomRadio isActive={selected === option.value} />
          <Label htmlFor={option.value}>{option.label}</Label>
        </Option>
      ))}
    </Container>
  );
};
