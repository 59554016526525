import React, { FC, ButtonHTMLAttributes } from 'react';
import { ButtonStyled, Loading } from './styles';
import { IEnumButtonTypeButton, IEnumButtonVariant } from './enum';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: any;
  variant?:
    | IEnumButtonVariant.TEXT
    | IEnumButtonVariant.CONTAINED
    | IEnumButtonVariant.OUTLINED;
  typeButton?:
    | IEnumButtonTypeButton.DEFAULT
    | IEnumButtonTypeButton.WARNING
    | IEnumButtonTypeButton.SUCCESS
    | IEnumButtonTypeButton.ERROR;
  loading?: boolean;
}

const Button: FC<IButtonProps> = ({
  children,
  variant = IEnumButtonVariant.CONTAINED,
  typeButton = IEnumButtonTypeButton.DEFAULT,
  loading,
  onClick,
  ...props
}) => {
  return (
    <ButtonStyled
      {...props}
      variant={variant}
      typeButton={typeButton}
      onClick={(ev) => {
        if (loading) return;

        onClick && onClick(ev);
      }}
    >
      {loading ? <Loading /> : children}
    </ButtonStyled>
  );
};

export { Button };
