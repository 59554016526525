/* eslint-disable prettier/prettier */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useMediaQuery } from 'react-responsive';

import { Margin } from '~/components/Margin';

import { Main, ButtonService, TextService, Card, RowServices } from './styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import DocumentSVG from '~/assets/Icons/DocumentIcon';
import NegativeSVG from '~/assets/Icons/CertNegative';
import ChartSVG from '~/assets/Icons/ChartIcon';
import { Breakpoints } from '~/styles';
import { DpaDpeIcon, RepairCenterIcon, ReportLackIcon } from './icons';
import EasyInvoice from './EasyInvoice';
import ConsumptionHistorySession from './ConsumptionHistory';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { checkToggle } from '~/utils/toggles';

import configsAppJsonImport from '~/configs/app.config';
import { BannerApp } from '~/components/BannerApp';
import {
  getRenovationSocialTariffRequest,
  viewedBannerSocialTariffRequest,
} from '~/store/modules/socialTariff/actions';
import ModalBanner from './ModalBanner';
import { Dialog } from '~/components/Dialog';

import Modal from '~/components/Modal';
import ModalAccountDebt from '../ModalAccountDebt';
import {
  getOrderServicesInputsLackWaterRequest,
  getOrderServicesValidateLackWaterRequest,
  postErrorCloseServiceCreateLackWaterRequest,
} from '~/store/modules/orderServices/actions';
import { DSTitle } from '~/design_system/components/Title';
import Negotiations from './Negotiations';

const configsAppJson = configsAppJsonImport as any;

const HomeClientNew: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const isDesktopLarge = useMediaQuery({ minWidth: Breakpoints.desktopLarge.max });
  const isVisibleBanner = useMediaQuery({ maxWidth: 1340 });
  const dispatch = useDispatch();

  const eletronic_exists = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.eletronic_exists,
  );
  const dataSubscribe = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.data,
  );
  const enrollment = useSelector(
    (item: IState) => item.enrollmentsAuth?.registrationCheck?.enrollment,
  );
  const reportLackWaterRoute = useSelector(
    (item: IState) => item.outsides.reportLackWaterRoute,
  );

  const [existCreateAlert, setExistCreateAlert] = useState<boolean>(false);
  const [existIotView, setExistIotView] = useState<boolean>(false);
  const toggles = useSelector((item: IState) => item.toggles.data);
  const togglesEnrollment = useSelector(
    (item: IState) => item.togglesEnrollment.data,
  );
  const togglesMe = useSelector((item: IState) => item.togglesMe.data);
  const isIotClient = eletronic_exists && dataSubscribe && dataSubscribe[0];
  // const [socialTariffView, setSocialTariffView] = useState<boolean>(false);
  const [supportCenter, setSupportCenter] = useState<boolean>(false);
  const [openAccountDebt, setOpenAccountDebt] = useState<boolean>(false);
  const [reportLackWater, setReportLackWater] = useState<boolean>(false);

  const checkModal = useSelector((item: IState) => item.automaticDebits.checkModal);

  const verifyTogglesMe = async () => {
    setSupportCenter(await checkToggle(togglesMe, 'SUPPORT_CENTER_NEW'));
  };
  const needRenovationSocialTariff = useSelector(
    (item: IState) => item.socialTariff.needRenovation,
  );
  const operation = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.operation.name,
  );
  const viewedBanner = useSelector((item: IState) => item.socialTariff.viewedBanner);

  const verifyToggles = async () => {
    setReportLackWater(false);
    setReportLackWater(await checkToggle(toggles, 'REPORT_LACK_WATER'));
  };

  useEffect(() => {
    if (toggles && toggles?.length > 0) verifyToggles();
  }, [toggles]);

  useEffect(() => {
    if (togglesMe && togglesMe.length > 0) verifyTogglesMe();
  }, [togglesMe]);

  const verifyTogglesEnrollment = async () => {
    setExistCreateAlert(await checkToggle(togglesEnrollment, 'MVP_CREATE_ALERT'));
    setExistIotView(await checkToggle(togglesEnrollment, 'MVP_MESSAGE_SMART_METER'));
    // setSocialTariffView(await checkToggle(toggles, 'TARIFA_SOCIAL'));
  };

  useEffect(() => {
    if (togglesEnrollment && togglesEnrollment.length > 0) verifyTogglesEnrollment();
  }, [togglesEnrollment]);

  useEffect(() => {
    if (enrollment) dispatch(getRenovationSocialTariffRequest(enrollment));
  }, [enrollment]);

  useEffect(() => {
    dispatch(postErrorCloseServiceCreateLackWaterRequest());
    dispatch(getOrderServicesInputsLackWaterRequest());
    if (reportLackWaterRoute) dispatch(getOrderServicesValidateLackWaterRequest());
  }, []);

  useEffect(() => {
    if (checkModal) {
      setOpenAccountDebt(true);
    } else {
      setOpenAccountDebt(false);
    }
  }, [checkModal]);

  return (
    <Main>
      <Dialog
        open={
          !viewedBanner &&
          needRenovationSocialTariff &&
          isVisibleBanner &&
          operation?.toLocaleUpperCase() === 'RIO DE JANEIRO'
        }
        onClose={() => {
          dispatch(viewedBannerSocialTariffRequest());
        }}
      >
        <ModalBanner
          onClose={() => {
            dispatch(viewedBannerSocialTariffRequest());
          }}
        />
      </Dialog>
      <Modal open={openAccountDebt} type="info" title="Atenção!">
        <ModalAccountDebt />
      </Modal>
      <BannerApp />
      <Card isBorder>
        <h1>Fatura fácil</h1>
        <Margin height={24} />
        <EasyInvoice />
      </Card>
      <Margin height={16} />
      <Card>
        <DSTitle label='Minhas pendências' />
        <Negotiations />
      </Card>
      <Margin height={16} />
      <Card
        height={
          (isIotClient && existCreateAlert) ||
          (eletronic_exists && existIotView) ||
          configsAppJson?.NODE_ENV !== 'PRD'
            ? !isDesktopLarge
              ? 700
              : 563
            : 392
        }
        hiddenPadding
      >
        <h1>Histórico detalhado</h1>
        <Margin height={35} />
        <ConsumptionHistorySession />
      </Card>
      <Margin height={16} />
      <Card>
        <h1>Nossos serviços</h1>
        <Margin height={isMobile ? 38 : 32} />
        <RowServices>
          <ButtonService
            bgColor="green"
            withMargin
            onClick={() => Navigation.navigate(RouteName.INVOICES)}
          >
            <DocumentSVG />
            <Margin height={15} />
            <TextService>Faturas</TextService>
          </ButtonService>
          <ButtonService
            bgColor="yellow"
            withMargin
            onClick={() => Navigation.navigate(RouteName.NEGATIVECERTIFICATE)}
          >
            <NegativeSVG />
            <Margin height={8} />
            <TextService>
              Certidão
              <br />
              negativa
            </TextService>
          </ButtonService>
          <ButtonService
            bgColor="blue"
            withMargin
            onClick={() => Navigation.navigate(RouteName.DETAILEDHISTORY)}
          >
            <ChartSVG />
            <Margin height={8} />
            <TextService>
              Histórico
              <br />
              detalhado
            </TextService>
          </ButtonService>
          {supportCenter && (
            <ButtonService
              bgColor="red"
              withMargin
              onClick={() => {
                ReactGA.event({
                  category: 'Minhas solicitações',
                  action: 'Minhas solicitações - Chamada serviço',
                });
                Navigation.navigate(RouteName.SUPPORTCENTER);
              }}
            >
              <RepairCenterIcon />
              <Margin height={8} />
              <TextService>
                Minhas
                <br />
                solicitações
              </TextService>
            </ButtonService>
          )}
          <ButtonService
            bgColor="blueIntense"
            withMargin
            onClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
          >
            <DpaDpeIcon />
            <Margin height={8} />
            <TextService>DPA/DPE</TextService>
          </ButtonService>
          {/* {socialTariffView && (
            <ButtonService
              bgColor="green"
              withMargin
              onClick={() => {
                ReactGA.event({
                  category: 'Tarifa Social',
                  action: 'Tarifa Social - Chamada serviço',
                });
                needRenovationSocialTariff
                  ? Navigation.navigate(RouteName.SOCIALTARIFFSOLICITATION)
                  : Navigation.navigate(RouteName.SOCIALTARIFF);
              }}
            >
              <SocialTariffIcon />
              <Margin height={8} />
              <TextService>Tarifa social</TextService>
            </ButtonService>
          )} */}
          {reportLackWater && (
            <ButtonService
              bgColor="redIntense"
              withMargin
              onClick={() => dispatch(
                getOrderServicesValidateLackWaterRequest(
                  RouteName.REPORTLACKWATER,
                ),
              )}
            >
              <ReportLackIcon />
              <Margin height={8} />
              <TextService> Reportar falta d’água</TextService>
            </ButtonService>
          )}
        </RowServices>
      </Card>
    </Main>
  );
};

export default HomeClientNew;
