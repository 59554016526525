import { Dispatch } from 'redux';

import {
  getDebtsNegotiationInstallmentsService,
  postDebtsNegotiationService,
  getDebtsNegotiationTermsService,
  getDebtsNegotiationAuthService,
  getDebtsNegotiationEnrollmentsService,
  getDebtsNegotiationListService,
  getDebtsNegotiationListDetailService,
} from '~/services/negotiation';
import {
  RepositoriesTypesNegotiation,
  IResponseDataDebtsNegotiation,
  IRequestDebtsNegotiation,
  IRequestDebtsNegotiationInstallments,
  IResponseDataDebtsNegotiationInstallments,
  IRequestCreateDebtsNegotiation,
  IRequestDebtsNegotiationTerms,
  IResponseDebtsNegotiationTerms,
  IResponseCreateDebtsNegotiationSummary,
  IResponseDebtsEnrollments,
  IRequestDebtsNegotiationListDetail,
  IResponseDebtsNegotiationListDetail,
  IRequestDebtsNegotiationListNegotiations,
} from './types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import {
  IResponseInvoicesDebts,
  RepositoriesTypesOutsides,
} from '../outsides/types';
import { IState } from '../types';
import { getOutsidesInvoicesInstallmentsService } from '~/services/outsides';

const getDebtsNegotiationRequest =
  ({ cnp, filter }: IRequestDebtsNegotiation) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_REQUEST,
      });

      const { enrollmentsAuth, negotiation } = getState() as IState;

      const isAuthenticated = !!enrollmentsAuth?.token;
      const dataDebtsEnrollments = negotiation?.dataDebtsEnrollments;

      const enrollments = !filter
        ? ((await getDebtsNegotiationEnrollmentsService({
            cnp,
            isAuthenticated,
          })) as Array<IResponseDebtsEnrollments>)
        : dataDebtsEnrollments;

      const enrollmentCheck = filter || enrollments[0];

      dispatch({
        type: RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_ENROLLMENT_CHECK_SUCCESS,
        payload: {
          enrollmentCheck,
        },
      });

      if (enrollments?.length > 0) {
        const response = (await getDebtsNegotiationEnrollmentsService({
          cnp,
          filter: {
            enrollment: enrollmentCheck?.enrollment,
            operationId: enrollmentCheck?.operationId,
            address: enrollmentCheck?.address,
          },
          isAuthenticated,
        })) as IResponseDataDebtsNegotiation;

        return dispatch({
          type: RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_SUCCESS,
          payload: {
            dataDebtsEnrollments: enrollments,
            dataDebtsNegotiation: { ...response },
          },
        });
      }

      return dispatch({
        type: RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_SUCCESS,
        payload: {
          dataDebtsEnrollments: enrollments,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_FAILURE,
        payload: {
          type: error?.response?.data.type,
        },
      });
    }
  };

const getErrorCloseDebtsNegotiation = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_FAILURE_CLOSE,
  });
};

const getDebtsNegotiationInstallmentsRequest =
  ({
    enrollment,
    operationId,
    params,
    handleContinue,
  }: IRequestDebtsNegotiationInstallments) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_INSTALLMENTS_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;
      const isAuthenticated = !!enrollmentsAuth?.token;

      const response = (await getDebtsNegotiationInstallmentsService({
        enrollment,
        operationId,
        params,
        isAuthenticated,
      })) as IResponseDataDebtsNegotiationInstallments;

      dispatch({
        type: RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_INSTALLMENTS_SUCCESS,
        payload: response,
      });

      return handleContinue && handleContinue();
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_INSTALLMENTS_FAILURE,
        payload: {
          type: error?.response?.data.type,
        },
      });
    }
  };

const getErrorCloseDebtsInstallmentsNegotiation =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesNegotiation.DEBTS_NEGOTIATION_INSTALLMENTS_FAILURE_CLOSE,
    });
  };

const getDebtsNegotiationTermsRequest =
  ({ enrollment, operationId, handleContinue }: IRequestDebtsNegotiationTerms) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_TERMS_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;
      const isAuthenticated = !!enrollmentsAuth?.token;

      const response = (await getDebtsNegotiationTermsService({
        isAuthenticated,
        enrollment,
        operationId,
      })) as IResponseDebtsNegotiationTerms;

      handleContinue && handleContinue();

      return dispatch({
        type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_TERMS_SUCCESS,
        payload: {
          ...response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_TERMS_FAILURE,
        payload: {
          type: error?.response?.data.type,
        },
      });
    }
  };

const getErrorCloseDebtsNegotiationTerms = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_TERMS_FAILURE_CLOSE,
  });
};

const postDebtsNegotiationRequest =
  (data: IRequestCreateDebtsNegotiation) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesNegotiation.POST_DEBTS_NEGOTIATION_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;
      const isAuthenticated = !!enrollmentsAuth?.token;

      const response = (await postDebtsNegotiationService({
        ...data,
        isAuthenticated,
      })) as IResponseCreateDebtsNegotiationSummary;

      dispatch({
        type: RepositoriesTypesNegotiation.SET_DATA_PAYMENT_DEBTS_NEGOTIATION,
        payload: {
          debtNegotiation: {
            operation: data?.operationId?.toString(),
            id: response.id,
          },
          amount: response?.summary?.totalAmount,
          possible_quantity_installments: response?.summary?.installmentCount,
          qr_code_key: response?.summary?.pixcode,
          bar_code_number: response?.summary?.barcode,
          isNegotiation: true,
        },
      });

      dispatch({
        type: RepositoriesTypesNegotiation.SET_DATA_DETAIL_DEBTS_NEGOTIATION,
        payload: {
          type: 'installment_details',
          dataOpenNegotiation: {
            ...response,
          },
        },
      });

      const route =
        data?.type === 'INSTALLMENTS'
          ? RouteName.DEBTNEGOTIATIONDETAIL
          : RouteName.DEBTNEGOTIATIONSINGLEPAYMENTDATAPAYMENT;

      Navigation.navigate(route);

      return dispatch({
        type: RepositoriesTypesNegotiation.POST_DEBTS_NEGOTIATION_SUCCESS,
        payload: {
          ...response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesNegotiation.POST_DEBTS_NEGOTIATION_FAILURE,
        payload: {
          type: error?.response?.data.type,
        },
      });
    }
  };

const postErrorCloseDebtsNegotiation = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesNegotiation.POST_DEBTS_NEGOTIATION_FAILURE_CLOSE,
  });
};

const setDataPaymentDebtsNegotiation =
  (data: any, cnp?: string) => async (dispatch: Dispatch) => {
    if (cnp) {
      dispatch({
        type: RepositoriesTypesNegotiation.POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_REQUEST,
      });

      const response = (await getOutsidesInvoicesInstallmentsService(
        cnp,
        data?.amount,
      )) as any;

      dispatch({
        type: RepositoriesTypesNegotiation.POST_NEGOTIATION_CALCULATE_INSTALLMENTS_METHOD_SUCCESS,
      });

      dispatch({
        type: RepositoriesTypesNegotiation.SET_DATA_PAYMENT_DEBTS_NEGOTIATION,
        payload: {
          ...data,
          possible_quantity_installments: response?.length || 1,
        },
      });

      return Navigation.navigate(RouteName.DEBTNEGOTIATIONSINGLEPAYMENTDATAPAYMENT);
    }

    return dispatch({
      type: RepositoriesTypesNegotiation.SET_DATA_PAYMENT_DEBTS_NEGOTIATION,
      payload: {
        ...data,
      },
    });
  };

const clearDataPaymentDebtsNegotiation = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesNegotiation.CLEAR_DATA_PAYMENT_DEBTS_NEGOTIATION,
  });
};

const getDebtsNegotiationAuthRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_AUTH_REQUEST,
    });

    const response =
      (await getDebtsNegotiationAuthService()) as IResponseInvoicesDebts;

    dispatch({
      type: RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });

    return dispatch({
      type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_AUTH_SUCCESS,
    });
  } catch (error: any) {
    dispatch({
      type: RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_FAILURE_CLOSE,
    });

    return dispatch({
      type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_AUTH_FAILURE,
      payload: {
        type: error?.response?.data.type,
      },
    });
  }
};

const getErrorCloseDebtsNegotiationAuth = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_AUTH_FAILURE_CLOSE,
  });
};

const getDebtsNegotiationListRequest =
  (data: IRequestDebtsNegotiationListNegotiations) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_REQUEST,
      });

      const response = (await getDebtsNegotiationListService(data)) as any;

      return dispatch({
        type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_SUCCESS,
        payload: {
          data: {
            ...response,
          },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_FAILURE,
        payload: {
          type: error?.response?.data.type,
        },
      });
    }
  };

const getErrorCloseDebtsListNegotiation = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_FAILURE_CLOSE,
  });
};

const getDebtsNegotiationListDetailRequest =
  (data: IRequestDebtsNegotiationListDetail) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_DETAIL_REQUEST,
      });

      const response = (await getDebtsNegotiationListDetailService(
        data,
      )) as IResponseDebtsNegotiationListDetail;

      Navigation.navigate(RouteName.DEBTNEGOTIATIONDETAIL);

      const isSightCampaignPaymentCondition =
        response?.paymentConditionDescription?.toUpperCase() === 'A VISTA';

      const type =
        isSightCampaignPaymentCondition &&
        response?.status?.toUpperCase() === 'PENDENTE'
          ? 'installment_details'
          : isSightCampaignPaymentCondition &&
            response?.status?.toUpperCase() === 'QUITADA'
          ? 'is_paid'
          : 'open_installments';

      dispatch({
        type: RepositoriesTypesNegotiation.SET_DATA_DETAIL_DEBTS_NEGOTIATION,
        payload: {
          type,
          dataDetailNegotiation: {
            ...response,
          },
        },
      });

      dispatch({
        type: RepositoriesTypesNegotiation.SET_DATA_PAYMENT_DEBTS_NEGOTIATION,
        payload: {
          debtNegotiation: {
            operation: data?.operation?.toString(),
            id: response.id,
          },
          amount: response?.totalAmount,
          possible_quantity_installments: response?.installmentCount,
          qr_code_key: response?.pixcode,
          bar_code_number: response?.barcode,
          isNegotiation: true,
        },
      });

      return dispatch({
        type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_DETAIL_SUCCESS,
        payload: {
          ...response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_DETAIL_FAILURE,
        payload: {
          type: error?.response?.data.type,
        },
      });
    }
  };

const getErrorCloseDebtsListListDetailNegotiation =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesNegotiation.GET_DEBTS_NEGOTIATION_LIST_DETAIL_FAILURE_CLOSE,
    });
  };

export {
  getDebtsNegotiationRequest,
  getErrorCloseDebtsNegotiation,
  getDebtsNegotiationInstallmentsRequest,
  getErrorCloseDebtsInstallmentsNegotiation,
  getDebtsNegotiationTermsRequest,
  getErrorCloseDebtsNegotiationTerms,
  postDebtsNegotiationRequest,
  postErrorCloseDebtsNegotiation,
  setDataPaymentDebtsNegotiation,
  clearDataPaymentDebtsNegotiation,
  getDebtsNegotiationAuthRequest,
  getErrorCloseDebtsNegotiationAuth,
  getDebtsNegotiationListRequest,
  getErrorCloseDebtsListNegotiation,
  getDebtsNegotiationListDetailRequest,
  getErrorCloseDebtsListListDetailNegotiation,
};
