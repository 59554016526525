import React, { useEffect, useState } from 'react';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import { Container, ContainerRules, ListRules, RuleText } from './styles';
import { SuccessIcon } from './icons';

interface IProps {
  password: string;
  isValid: (check: boolean) => void;
}

const PasswordRequirements: React.FC<IProps> = ({ password, isValid }) => {
  const [strengthLevel, setStrengthLevel] = useState<number>(0);

  const checkRequirements = () => {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#()$%¨&*]/;
    const minLengthRegex = /.{8,}/;

    let strengthLevelCount = 0;

    if (minLengthRegex.test(password)) strengthLevelCount++;
    if (uppercaseRegex.test(password)) strengthLevelCount++;
    if (lowercaseRegex.test(password)) strengthLevelCount++;
    if (numberRegex.test(password)) strengthLevelCount++;
    if (specialCharRegex.test(password)) strengthLevelCount++;

    setStrengthLevel(strengthLevelCount);
  };

  useEffect(() => {
    checkRequirements();
  }, [password]);

  useEffect(() => {
    if (strengthLevel === 5) {
      isValid(true);
    } else {
      isValid(false);
    }
  }, [strengthLevel]);

  return (
    <Container>
      <PasswordStrengthIndicator strengthLevel={strengthLevel} />
      <ContainerRules>
        {password && strengthLevel === 5 && (
          <RuleText>
            Senha forte
            {strengthLevel === 5 && <SuccessIcon />}
          </RuleText>
        )}
        {!(strengthLevel > 4) && (
          <ListRules>
            <RuleText>Aumente a segurança da sua senha utilizando:</RuleText>
            <RuleText>8-16 caracteres</RuleText>
            <RuleText>Letra maiúscula</RuleText>
            <RuleText>Letra minúscula</RuleText>
            <RuleText>Numeral</RuleText>
            <RuleText>Símbolo (!@#$%¨&*())</RuleText>
          </ListRules>
        )}
      </ContainerRules>
    </Container>
  );
};

export default PasswordRequirements;
