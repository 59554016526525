import React from 'react';

const MainBackgroundIcon = () => {
  return (
    <svg
      width="355"
      height="312"
      viewBox="0 0 355 312"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M216.033 215.664L240.638 240.266C257.825 222.978 267.527 201.086 269.013 179.125C269.746 168.102 268.393 157.032 264.764 146.586C261.142 136.159 255.194 126.357 246.987 118.174L207.587 78.7604L180.276 51.4489L151.043 22.2176C142.84 14.0126 133.045 8.06193 122.621 4.44382C106.946 -1.00945 89.9035 -1.32907 73.6262 2.8556C57.3525 7.03181 41.7201 15.768 28.7392 28.7369C11.441 46.0563 1.68387 68.026 0.197965 90.0768C-0.549222 101.089 0.808982 112.17 4.43767 122.606C8.06707 133.042 14.0199 142.834 22.2227 151.028L118.18 246.983C126.375 255.176 136.166 261.128 146.602 264.747C162.278 270.198 179.307 270.52 195.584 266.346C201.058 264.937 206.456 262.971 211.716 260.551L184.405 233.24C181.86 233.772 179.32 234.115 176.813 234.282C170.16 234.726 163.776 233.893 158.037 231.895C152.298 229.885 147.179 226.754 142.793 222.381L46.827 126.423C42.4412 122.027 39.3071 116.92 37.3005 111.17C34.3026 102.566 33.9272 92.4715 36.5442 82.2811C39.166 72.1119 44.7385 61.9532 53.3555 53.3489C64.8124 41.8688 79.0788 35.7862 92.4012 34.9063C99.063 34.4618 105.434 35.3078 111.173 37.3059C116.924 39.3154 122.031 42.4474 126.438 46.8197L151.341 71.733L175.954 96.3352L222.383 142.776C226.772 147.161 229.889 152.28 231.901 158.029C234.899 166.625 235.274 176.715 232.656 186.908C230.056 197.011 224.54 207.093 216.033 215.664Z"
        fill="#D7F0F4"
      />
      <path
        d="M175.954 96.3359L151.342 71.7337C134.167 89.0213 124.462 110.914 122.975 132.875C122.241 143.898 123.599 154.969 127.228 165.414C130.849 175.841 136.797 185.643 145 193.836L184.404 233.24L211.716 260.551L240.949 289.782C249.138 297.987 258.934 303.927 269.37 307.556C285.046 313.009 302.075 313.329 318.352 309.145C334.639 304.96 350.258 296.232 363.24 283.255C380.538 265.945 390.295 243.961 391.781 221.924C392.516 210.901 391.162 199.844 387.532 189.386C383.911 178.958 377.959 169.167 369.756 160.972L273.812 65.0274C265.605 56.8246 255.826 50.8612 245.39 47.2424C229.71 41.8018 212.681 41.4695 196.403 45.6436C190.933 47.0547 185.523 49.0295 180.275 51.4489L207.587 78.7604C210.127 78.2284 212.672 77.8721 215.178 77.7056C221.827 77.2611 228.202 78.107 233.954 80.1052C239.693 82.1146 244.808 85.2339 249.207 89.6189L345.152 185.576C349.538 189.973 352.672 195.08 354.679 200.829C357.677 209.425 358.053 219.527 355.422 229.708C352.813 239.888 347.241 250.046 338.636 258.65C327.167 270.12 312.901 276.205 299.591 277.08C292.93 277.524 286.546 276.692 280.815 274.693C275.068 272.684 269.947 269.565 265.55 265.18L240.638 240.266L216.034 215.664L169.605 169.222C165.219 164.837 162.098 159.719 160.091 153.969C157.081 145.374 156.718 135.283 159.335 125.091C161.923 114.987 167.44 104.897 175.954 96.3359Z"
        fill="white"
      />
    </svg>
  );
};

const LeaksLackWaterBackgroundIcon = () => {
  return (
    <svg
      width="216"
      height="178"
      viewBox="0 0 216 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M146.353 14.8333V20.3958L190.259 14.8333C198.354 14.8333 204.894 21.462 204.894 29.6667C204.894 37.8714 198.354 44.5 190.259 44.5L146.353 38.9375L132.175 37.1297L131.718 37.0833L131.26 37.1297L117.083 38.9375L73.1766 44.5C65.0814 44.5 58.5412 37.8714 58.5412 29.6667C58.5412 21.462 65.0814 14.8333 73.1766 14.8333L117.083 20.3958V14.8333C117.083 6.62864 123.623 0 131.718 0C139.813 0 146.353 6.62864 146.353 14.8333ZM219.53 89C227.625 89 234.165 95.6286 234.165 103.833V133.5C234.165 141.705 227.625 148.333 219.53 148.333H173.749C164.51 161.776 149.097 170.583 131.718 170.583C114.338 170.583 98.9256 161.776 89.687 148.333H73.1766C65.0814 148.333 58.5412 154.962 58.5412 163.167C58.5412 171.371 52.0011 178 43.9059 178H14.6353C6.54014 178 -1.52588e-05 171.371 -1.52588e-05 163.167C-1.52588e-05 122.19 32.7465 89 73.1766 89H87.8119L98.1481 78.524C100.892 75.7427 104.597 74.1667 108.484 74.1667H117.083V53.8635L131.718 52.0094L146.353 53.8635V74.1667H154.906C158.793 74.1667 162.498 75.7427 165.242 78.524L175.624 89H219.53Z"
        fill="#D7F0F4"
      />
    </svg>
  );
};

const NewVentureBackgroundIcon = () => {
  return (
    <svg
      width="148"
      height="178"
      viewBox="0 0 148 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78 121.789C34.905 121.789 0 138.559 0 159.263V178H156V159.263C156 138.559 121.095 121.789 78 121.789ZM39 65.5789C39 75.5176 43.1089 85.0492 50.4228 92.0769C57.7368 99.1045 67.6566 103.053 78 103.053C88.3434 103.053 98.2632 99.1045 105.577 92.0769C112.891 85.0492 117 75.5176 117 65.5789M73.125 0C70.2 0 68.25 1.96737 68.25 4.68421V32.7895H58.5V9.36842C58.5 9.36842 36.5625 17.4253 36.5625 44.5C36.5625 44.5 29.25 45.8116 29.25 56.2105H126.75C126.262 45.8116 119.438 44.5 119.438 44.5C119.438 17.4253 97.5 9.36842 97.5 9.36842V32.7895H87.75V4.68421C87.75 1.96737 85.8975 0 82.875 0H73.125Z"
        fill="#D7F0F4"
      />
    </svg>
  );
};

export {
  MainBackgroundIcon,
  LeaksLackWaterBackgroundIcon,
  NewVentureBackgroundIcon,
};
