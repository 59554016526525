import React, { FC } from 'react';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import NegotiationSinglePayment from '~/components/Negotation/NegotiationSinglePayment';
import { IState } from '~/store/modules/types';
import { useSelector } from 'react-redux';

const DebtNegotiationSinglePayment: FC = () => {
  const token = useSelector((item: IState) => item.enrollmentsAuth.token);

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(token ? RouteName.HOME : RouteName.LANDING),
    },
    {
      label: 'Negociação de dívidas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DISCHARGEDEBTS),
    },
    {
      label: 'Dívidas em aberto',
      active: false,
      onClick: () => Navigation.goBack(),
    },
    {
      label: 'Pagamento único',
      active: true,
    },
  ];

  if (token) delete links[1];

  return <NegotiationSinglePayment links={links} />;
};

export default DebtNegotiationSinglePayment;
