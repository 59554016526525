import React, { FC } from 'react';

import {
  ContactUsItem,
  ContactUsRow,
  ContactUsTable,
  ContactUsTableHeader,
  DescriptionText,
} from './styles';
import { contactMock } from './contact-mock';
import { ContactUsWhatsAppIcon } from './icons';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

const ContactUs: FC = () => {
  const data =
    useSelector((item: IState) => item.outsides.dataPartners) || contactMock;

  const buildWhatsappUrl = (
    urlMask: string,
    domain: string,
    phone: string,
    message: string,
  ): string => {
    return urlMask
      .replace('{domain}', domain)
      .replace('{phone}', phone)
      .replace('{message}', encodeURIComponent(message));
  };

  return (
    <>
      <DescriptionText>
        Entre em contato pelo <span>Fale conosco</span>, ou usando o whats app de
        cada operação conforme listagem abaixo:
      </DescriptionText>
      <ContactUsTable>
        <ContactUsTableHeader>
          <ContactUsWhatsAppIcon />
          Números What’s App por operação
        </ContactUsTableHeader>
        {data?.operations?.map((operation) => {
          const whatsappUrl = buildWhatsappUrl(
            data?.urlMaskWhatsapp,
            operation.domain,
            operation.phone,
            operation.message,
          );

          return (
            <ContactUsRow
              key={operation.name}
              href={whatsappUrl}
              target="_blank"
              rel="noreferrer"
            >
              <ContactUsItem>{operation.name}</ContactUsItem>
              <ContactUsItem>{operation.phone}</ContactUsItem>
            </ContactUsRow>
          );
        })}
      </ContactUsTable>
    </>
  );
};

export default ContactUs;
