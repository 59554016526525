import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  HTMLAttributes,
  KeyboardEvent,
  useMemo,
  useState,
} from 'react';
import { RE_DIGIT } from '~/utils/constants';
import {
  Main,
  MyInputToken,
  Wrapper,
  InputWrap,
  HelperMessage,
  Button,
} from './styles';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  value: string;
  valueLength: number;
  handleChange: (value: string) => void;
  error?: boolean;
  helperMessage?: string;
  onClick: () => void;
  disabled: boolean;
}

const InputToken: FC<IProps> = ({
  valueLength,
  value,
  handleChange,
  error,
  helperMessage,
  onClick,
  disabled,
  ...props
}) => {
  const valueItems = useMemo(() => {
    const valueArr = value.split('');
    const items: Array<string> = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArr[i];
      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [value, valueLength]);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const isFilled = valueItems.includes('');

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { target } = e;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, index) + targetValue + value.substring(index + 1);

      handleChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      handleChange(targetValue);

      target.blur();
    }
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;

    if (key === 'ArrowRight' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      return null;
    }

    return focusToPrevInput(target);
  };

  const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);

    const { target } = e;

    const prevInputEl = target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    return target.setSelectionRange(0, target.value.length);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <Main>
      <Wrapper>
        <InputWrap isFilled={!isFilled} isFocused={isFocused} isError={!!error}>
          {[1, 2, 3, 4, 5, 6].map((item, index) => (
            <MyInputToken
              key={item}
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              pattern="\d{1}"
              maxLength={valueLength}
              value={valueItems[index]}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={handleInputKeyDown}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              autoFocus={index === 0}
              disabled={disabled}
              {...props}
            />
          ))}
        </InputWrap>
        <HelperMessage error={error}>{helperMessage}</HelperMessage>
      </Wrapper>
      <Button type="button" onClick={onClick}>
        Reenviar código
      </Button>
    </Main>
  );
};

export { InputToken };
