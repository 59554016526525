import React, { FC, ReactNode } from 'react';

import {
  ButtonClose,
  ContainerInfo,
  ContainerTitle,
  Main,
  TitleText,
} from './styles';
import { CloseModalIcon } from './icons';

interface IModalInfoProps {
  onClose: () => void;
  title: string;
  children: ReactNode;
}

const ModalInfo: FC<IModalInfoProps> = ({ onClose, title, children }) => {
  return (
    <Main>
      <ContainerTitle>
        <TitleText>{title}</TitleText>
        <ButtonClose type="button" onClick={onClose}>
          <CloseModalIcon />
        </ButtonClose>
      </ContainerTitle>
      <ContainerInfo>{children}</ContainerInfo>
    </Main>
  );
};

export default ModalInfo;
