import { Color } from '~/styles';

export const statusPropertyRegistration = (statusLabel: string | null) => {
  const cases: { [key: string]: { color: string; label: string } } = {
    'EM ANÁLISE': { color: Color.yellow, label: 'Em Análise' },
    CANCELADO: { color: Color.gray, label: 'Cancelado' },
    'AIO EMITIDA': { color: Color.green, label: 'AIO Emitida' },
    'LIGAÇÃO APROVADA': { color: Color.green, label: 'Ligação Aprovada' },
    'PROJETO APROVADO': { color: Color.green, label: 'Projeto Aprovado' },
    'REVISAR JUSTIFICATIVA': { color: Color.red, label: 'Revisar Justificativa' },
    'REVISAR PROJETO': { color: Color.red, label: 'Revisar Projeto' },
    'REVISAR FOTOGRAFIA': { color: Color.red, label: 'Revisar Fotografia' },
    'REVISAR INFORMAÇÕES': { color: Color.red, label: 'Revisar Informações' },
    'REVISAR DOCUMENTOS': { color: Color.red, label: 'Revisar Documentos' },
    'REVISAR DOCUMENTO E PROJETO': {
      color: Color.red,
      label: 'Revisar Documento e Projeto',
    },
    'REVISAR DATA SELECIONADA': {
      color: Color.red,
      label: 'Revisar Data Selecionada',
    },
    'HABITE-SE APROVADO': {
      color: Color.green,
      label: 'Habite-se',
    },
  };
  if (statusLabel === null) {
    return cases['EM ANÁLISE'];
  }
  return cases[statusLabel?.toUpperCase()] || cases['EM ANÁLISE'];
};
