import React from 'react';

interface IDisabledMethodProps {
  disabled?: boolean;
}

const ForgotPasswordMethodSmsIcon = ({ disabled }: IDisabledMethodProps) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H48C52.4183 0 56 3.58172 56 8V48C56 52.4183 52.4183 56 48 56H8C3.58172 56 0 52.4183 0 48V8Z"
        fill={disabled ? '#F8F9FA' : '#F5FCFD'}
      />
      <g clipPath="url(#clip0_6019_3294)">
        <path
          opacity="0.3"
          d="M19.5 20C19.5 17.7909 21.2909 16 23.5 16H32.5C34.7091 16 36.5 17.7909 36.5 20V36C36.5 38.2091 34.7091 40 32.5 40H23.5C21.2909 40 19.5 38.2091 19.5 36V20Z"
          fill={disabled ? '#F8F9FA' : '#D7F0F4'}
        />
        <path
          d="M36.4481 12.3335H19.8247C17.9961 12.3335 16.5 13.6426 16.5 15.2426V41.4244C16.5 43.0244 17.9961 44.3335 19.8247 44.3335H36.4481C38.2766 44.3335 39.7727 43.0244 39.7727 41.4244V15.2426C39.7727 13.6426 38.2766 12.3335 36.4481 12.3335ZM36.4481 39.9699H19.8247V16.6971H36.4481V39.9699ZM29.1338 22.0789L27.4714 23.5335C30.4636 26.1517 30.4636 30.2244 27.4714 32.988L29.1338 34.4426C33.2896 31.0971 33.2896 25.5699 29.1338 22.0789ZM23.9805 26.588C24.8117 27.6062 24.8117 28.9153 23.9805 29.9335L25.6429 31.388C27.6377 29.6426 27.6377 27.0244 25.6429 25.1335L23.9805 26.588Z"
          fill={disabled ? '#EBECED' : '#37B4C8'}
        />
      </g>
      <defs>
        <clipPath id="clip0_6019_3294">
          <rect width="25" height="32" fill="white" transform="translate(15.5 12)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ForgotPasswordMethodEmailIcon = ({ disabled }: IDisabledMethodProps) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H48C52.4183 0 56 3.58172 56 8V48C56 52.4183 52.4183 56 48 56H8C3.58172 56 0 52.4183 0 48V8Z"
        fill={disabled ? '#F8F9FA' : '#F5FCFD'}
      />
      <path
        opacity="0.3"
        d="M41 21.3333L28 28.6667L14 20.3333V38H41V21.3333ZM42 18H14L28 27.3167L42 18Z"
        fill={disabled ? '#F8F9FA' : '#D7F0F4'}
      />
      <path
        d="M14.8665 40.9335H40.4665C42.2265 40.9335 43.6665 39.4935 43.6665 37.7335V18.5335C43.6665 16.7735 42.2265 15.3335 40.4665 15.3335H14.8665C13.1065 15.3335 11.6665 16.7735 11.6665 18.5335V37.7335C11.6665 39.4935 13.1065 40.9335 14.8665 40.9335ZM40.4665 18.5335L27.6665 26.5175L14.8665 18.5335H40.4665ZM14.8665 21.7335L27.6665 29.7335L40.4665 21.7335V37.7335H14.8665V21.7335Z"
        fill={disabled ? '#EBECED' : '#37B4C8'}
      />
    </svg>
  );
};

export { ForgotPasswordMethodSmsIcon, ForgotPasswordMethodEmailIcon };
