import React, {
  InputHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';

import {
  ContainerInputStyled,
  InputStyled,
  LabelStyled,
  ContainerIconStyled,
  LabelRow,
  LabelError,
  ContentInput,
} from './styles';

import InvisiblePassword from '~/assets/Icons/InvisiblePassword';
import VisiblePasswordSVG from '~/assets/Icons/VisiblePassword';

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  iconPassword?: boolean;
  error?: boolean;
  errorText?: string;
  passwordVisible?: boolean;
  setPasswordVisible?: (statusVisible: boolean) => void;
}

export const Input: ForwardRefRenderFunction<HTMLInputElement, IInputProps> = (
  {
    label,
    iconPassword,
    error,
    errorText,
    passwordVisible,
    setPasswordVisible,
    ...props
  },
  ref,
) => {
  return (
    <ContainerInputStyled>
      <LabelRow>{label && <LabelStyled>{label}</LabelStyled>}</LabelRow>
      <ContentInput>
        <InputStyled ref={ref} error={error} autoComplete="off" {...props} />
        {iconPassword && (
          <ContainerIconStyled
            error={!!(error && errorText)}
            onClick={() => {
              if (setPasswordVisible) {
                setPasswordVisible(!passwordVisible);
              }
            }}
          >
            {passwordVisible ? <VisiblePasswordSVG /> : <InvisiblePassword />}
          </ContainerIconStyled>
        )}
      </ContentInput>
      {error && errorText && <LabelError>{errorText}</LabelError>}
    </ContainerInputStyled>
  );
};

export default forwardRef(Input);
