import styled from 'styled-components';

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ValueTotalContainer = styled.div`
  width: 100%;
  padding: 8px 0px;
  height: calc(48px - 16px);
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const ValueTotalText = styled.input`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #202022;

  &:active,
  &:focus,
  &:focus-visible {
    border: none;
    outline: none;
  }
`;

export const ValuesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ValueText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #202022;
`;

export const ContainerSliderTrack = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 8px 0;
`;

export const SliderTrack = styled.div`
  width: 100%;
  height: 8px;
  background: #e0f0f8;
  border-radius: 8px;
  cursor: pointer;
`;

export const SliderProgress = styled.div`
  position: absolute;
  height: 8px;
  background: #37b4c8;
  border-radius: 12px;
  cursor: pointer;
`;

export const ThumbContainer = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;

  &:enabled,
  &:active,
  &:focus,
  &:focus-visible {
    cursor: grabbing;
    border: 1px solid #32a2b4;
  }

  span {
    font-size: 10px;
    color: #6ec1e4;
    pointer-events: none;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LegendText = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: #6a7279;
`;
