/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-const */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

import { Margin } from '~/components/Margin';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerAmount,
  ContainerHeader,
  ContainerInvoice,
} from './styles';
import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Breakpoints, Color } from '~/styles';
import { RouteName } from '~/routes/Routes.name';
import { history } from '~/store';
import { IEasyInvoices } from '~/store/modules/outsides/types';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import { statusColor } from '~/utils/invoiceStatus';
import { getOutsidesInvoicesInstallmentsRequest } from '~/store/modules/outsides/actions';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';

interface IInvoiceGroup extends IEasyInvoices {
  active: boolean;
}

const DischargeDebtsSinglePayment: FC = () => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.laptop.min });

  const invoices = history.location.state as any;

  const [invoicesGroup, setInvoicesGroup] = useState<Array<IInvoiceGroup>>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const loadingInstallments = useSelector(
    (item: IState) => item?.outsides?.loadingInstallments,
  );

  useEffect(() => {
    let data: Array<IInvoiceGroup> = [];

    invoices?.invoices?.reverse().map((invoice: any) => {
      return data.push({
        ...invoice,
        active: true,
      });
    });

    setInvoicesGroup(data);
  }, []);

  const getActiveInvoices = () => {
    let data: Array<any> = [];

    invoicesGroup?.map((invoice) => {
      if (invoice.active)
        return data.push({
          ...invoice,
        });

      return invoice;
    });

    return data;
  };

  const addInvoices = () => {
    const amountInvoices = getActiveInvoices()?.reduce(
      (total: any, number: any) => total + number.amount,
      0,
    );

    setTotalAmount(amountInvoices);
  };

  useEffect(() => {
    if (invoicesGroup) {
      addInvoices();
      const invoiceInactive = invoicesGroup?.find((invoice) => !invoice.active);
      if (!invoiceInactive) setCheckedAll(true);
      else setCheckedAll(false);
    }
  }, [invoicesGroup]);

  const handleCheck = () => {
    let data: Array<IInvoiceGroup> = [];
    invoices?.invoices?.map((invoice: any) => {
      return data.push({
        ...invoice,
        active: !checkedAll,
      });
    });
    setCheckedAll(!checkedAll);
    setInvoicesGroup(data);
  };

  const verifyActiveInvoice = () => {
    return invoicesGroup?.filter((invoice) => invoice.active).length > 1;
  };

  return (
    <Main>
      {loadingInstallments && <LoadingComponent labelWait="Carregando..." />}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.LANDING),
            },
            {
              label: 'Pagamento único',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <h1>Pagamento único</h1>
        <Margin height={34} />
        <ContainerBorder>
          <Border />
        </ContainerBorder>
        <h2>Selecione as faturas e pague de forma agrupada:</h2>
        <ContainerHeader>
          <div>
            <label className="container">
              <input
                type="checkbox"
                name="checkAll"
                onChange={() => handleCheck()}
                checked={checkedAll}
              />
              <span className="checkmark" />
            </label>
          </div>
          <p>Mês</p>
          {!isMobile && <p>Vencimento</p>}
          <p>Valor</p>
        </ContainerHeader>
        {invoicesGroup &&
          invoicesGroup?.map((invoice, index) => (
            <ContainerInvoice
              key={invoice.invoice_id}
              color={statusColor(invoice?.status?.label, true)}
            >
              <div>
                <span />
                <label className="container">
                  <input
                    type="checkbox"
                    name={`check${invoice.invoice_id}`}
                    onChange={() => {
                      let data: Array<IInvoiceGroup> = [...invoicesGroup];
                      data[index].active = !invoice?.active;
                      setCheckedAll(invoice?.active ? checkedAll : false);
                      setInvoicesGroup(data);
                    }}
                    checked={invoice.active}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <p className="status">
                {invoice?.status?.label} <br /> <span>{invoice?.reference}</span>
              </p>
              {!isMobile && (
                <p>{moment(invoice?.maturity_date).format('DD/MM/YYYY')}</p>
              )}
              <p>{formatter.formatCurrency(invoice?.amount)}</p>
            </ContainerInvoice>
          ))}
        <ContainerAmount>
          <p>Valor total: {formatter.formatCurrency(totalAmount)}</p>
        </ContainerAmount>
        <ContainerButtons>
          <ButtonText
            text="Voltar"
            typeBtn="content"
            onClick={() =>
              Navigation.navigate(RouteName.DISCHARGEDEBTS, {
                typeView: 'group',
                enrollment: invoices?.enrollment,
                cnp: invoices?.cnp,
              })
            }
          />
          <ButtonText
            typeBtn="content"
            backgroundColor={verifyActiveInvoice() ? Color.blueSeconde : Color.gray}
            onClick={() => {
              if (!verifyActiveInvoice()) return;

              ReactGA.event({
                category: 'Pagamento Único',
                action: '[QD][C4][WEB] – Ir para pagamento',
              });

              let data: Array<any> = [];

              getActiveInvoices()?.map((invoice) => {
                return data.push({
                  ...invoice,
                });
              });

              dispatch(
                getOutsidesInvoicesInstallmentsRequest(
                  invoices?.cnp || '',
                  totalAmount || 0,
                  {
                    invoices: invoices?.invoices,
                    invoicesGroup: data,
                    totalAmount,
                    enrollment: invoices?.enrollment,
                    cnp: invoices?.cnp,
                  },
                  RouteName.DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENT,
                ),
              );
            }}
            nextIcon
          >
            Ir para pagamento
          </ButtonText>
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default DischargeDebtsSinglePayment;
