import styled from 'styled-components';

export const AmountSuccessText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 122.5%;
  letter-spacing: -0.025em;
  text-decoration-line: underline;
  color: #37b4c8;
`;
