import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

import { Main } from './styles';

import {
  getEnrollmentsUsersRequestClear,
  putCloseErrorUsersUpdatePassword,
  putCloseSuccessUsersUpdatePassword,
  putEnrollmentsUsersUpdatePasswordRequest,
} from '~/store/modules/enrollmentsUsers/actions';
import { history } from '~/store';

import { Recaptcha } from '~/components/Recaptcha';
import { showRecaptchaV2 } from '~/utils/showRecaptcha';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';
import { saveLoginCredentials } from '~/store/modules/enrollmentsLoginCredentials/actions';
import { CardForm } from '~/design_system/components/CardForm';
import { Button } from '~/design_system/components/Button';
import Input from '~/design_system/components/Input';
import PasswordRequirements from '~/design_system/components/PasswordRequirements';
import { IEnumModalType } from '~/design_system/components/Modal/enum';
import Modal from '~/design_system/components/Modal';
import ModalDefaultError from '~/design_system/components/ModalDefaultError';
import {
  getEnrollmentsAuthCloseRequest,
  getEnrollmentsAuthRequest,
} from '~/store/modules/enrollmentsAuth/actions';

const ForgotPasswordNewPassword: React.FC = () => {
  const dispatch = useDispatch();

  const dataHistory = history.location.state as any;

  console.log({ dataHistory });

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingPutPasswordRecovery,
  );
  const success = useSelector(
    (item: IState) => item.enrollmentsUsers.successUpdatePasswordRecovery,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorPutPasswordRecovery,
  );
  const errorLogin = useSelector((item: IState) => item.enrollmentsAuth.error);

  const enrollmentsAuthLoading = useSelector(
    (item: IState) => item.enrollmentsAuth.loading,
  );

  const [password, setPassword] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState<string | null>(null);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const showRecaptcha = showRecaptchaV2(error || errorLogin || null);
  const loginValues = useSelector(
    (item: IState) => item.enrollmentsLoginCredentials.data,
  );

  const recaptchaTokens = {
    recaptchaTokenV3: recaptchaTokenV3 || '',
    recaptchaTokenV2: recaptchaTokenV2 || '',
  };

  const clearError = () => {
    dispatch(getEnrollmentsAuthCloseRequest());
    dispatch(putCloseSuccessUsersUpdatePassword());
    dispatch(putCloseErrorUsersUpdatePassword());
  };

  useEffect(() => {
    clearError();
  }, []);

  useEffect(() => {
    if (error && !showRecaptcha) {
      if (error.type === EnumResponseCode.RECENTLY_USED_PASSWORD) {
        setOpenInfo(true);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(false);
    }
  }, [errorPassword, error]);

  useEffect(() => {
    if (
      password &&
      confirmPassword &&
      password === confirmPassword &&
      !errorPassword &&
      isValidPassword
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password, confirmPassword, errorPassword, isValidPassword]);

  useEffect(() => {
    if (password && confirmPassword && password !== confirmPassword) {
      setErrorPassword('Senhas não coincidem.');
    } else {
      setErrorPassword('');
    }
  }, [password, confirmPassword]);

  const handleSubmit = () => {
    if (disabled || loading) return;
    dispatch(
      putEnrollmentsUsersUpdatePasswordRequest({
        ...recaptchaTokens,
        token: dataHistory?.token,
        newPassword: password,
      }),
    );
  };

  const handleSuccess = () => {
    dispatch(getEnrollmentsUsersRequestClear());

    dispatch(
      getEnrollmentsAuthRequest({
        cnp: dataHistory?.cnp,
        password,
        recaptchaTokenV3: recaptchaTokenV3 || '',
        recaptchaTokenV2: recaptchaTokenV2 || '',
      }),
    );

    if (loginValues?.remember && loginValues?.cnp)
      dispatch(
        saveLoginCredentials({
          remember: !!loginValues?.remember,
          cnp: loginValues?.cnp,
          password,
        }),
      );
  };

  return (
    <Main>
      <Recaptcha
        setRecaptchaTokenV3={setRecaptchaTokenV3}
        setRecaptchaTokenV2={setRecaptchaTokenV2}
        showRecaptchaV2={showRecaptcha}
        onClose={clearError}
      />
      {success && (
        <Modal
          type={IEnumModalType.SUCCESS}
          title="Senha alterada!"
          textButton="Entrar"
          loading={enrollmentsAuthLoading}
          onClick={handleSuccess}
        />
      )}
      {openInfo && (
        <Modal
          type={IEnumModalType.WARNING}
          description="A nova senha não pode ser igual à senhas anteriores"
          textButton="Ok"
          onClick={() => {
            dispatch(putCloseErrorUsersUpdatePassword());
            setOpenInfo(false);
          }}
        />
      )}
      {open && (
        <ModalDefaultError
          onClick={() => dispatch(putCloseErrorUsersUpdatePassword())}
        />
      )}
      <CardForm
        title="Crie a nova senha"
        description="Agora só falta criar sua senha. Para uma senha forte, siga os requisitos apresentados:"
        maxWidth={411}
        doubleGap
      >
        <Input
          value={password}
          onChange={(ev) => {
            setPassword(ev.target.value);
            setErrorPassword('');
          }}
          label="Nova senha"
          placeholder="Digite a senha"
          type={!passwordVisible ? 'password' : 'text'}
          iconPassword
          passwordVisible={passwordVisible}
          setPasswordVisible={setPasswordVisible}
          maxLength={16}
        />
        <Input
          value={confirmPassword}
          onChange={(ev) => {
            setConfirmPassword(ev.target.value);
            setErrorPassword('');
          }}
          label="Confirmação de senha"
          placeholder="Digite a senha"
          type={!confirmPasswordVisible ? 'password' : 'text'}
          iconPassword
          passwordVisible={confirmPasswordVisible}
          setPasswordVisible={setConfirmPasswordVisible}
          maxLength={16}
          error={!!errorPassword}
          errorText={errorPassword}
        />
        <PasswordRequirements password={password} isValid={setIsValidPassword} />
        <Button onClick={handleSubmit} loading={loading} disabled={disabled}>
          Continuar
        </Button>
      </CardForm>
    </Main>
  );
};

export default ForgotPasswordNewPassword;
