import React, { FC } from 'react';
import Modal from '../Modal';
import { IEnumModalType } from '../Modal/enum';

interface IModalDefaultErrorProps {
  onClick: () => void;
}

const ModalDefaultError: FC<IModalDefaultErrorProps> = ({ onClick }) => {
  return (
    <Modal
      type={IEnumModalType.ERROR}
      description="Não foi possivel processar suas solicitação. Por favor tente mais tarde!"
      textButton="Entendi"
      onClick={onClick}
    />
  );
};

export default ModalDefaultError;
