import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';

export const Main = styled.main`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 12px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 56px;
  }
`;

export const ContactUsText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  font-weight: 600;
  color: #32a2b4;
`;
