import React, { FC } from 'react';
import {
  ContainerMethods,
  ContainerMethodTexts,
  MethodButton,
  MethodTypeText,
  MethodUserInfoText,
} from './styles';
import { ForgotPasswordMethodEmailIcon, ForgotPasswordMethodSmsIcon } from './icons';

export interface IMethodSendCodeProps {
  onClickSms: () => void;
  onClickEmail: () => void;
  textPhone?: string;
  textEmail?: string;
  disabledSms?: boolean;
  disabledEmail?: boolean;
}

const MethodSendCode: FC<IMethodSendCodeProps> = ({
  onClickSms,
  onClickEmail,
  textPhone,
  textEmail,
  disabledSms = false,
  disabledEmail = false,
}) => {
  return (
    <ContainerMethods>
      <MethodButton onClick={() => !disabledSms && onClickSms()} empty={disabledSms}>
        <div>
          <ForgotPasswordMethodSmsIcon disabled={disabledSms} />
        </div>
        <ContainerMethodTexts>
          <MethodTypeText disabled={disabledSms}>Receber por SMS</MethodTypeText>
          <MethodUserInfoText>{textPhone}</MethodUserInfoText>
        </ContainerMethodTexts>
      </MethodButton>
      <MethodButton
        onClick={() => !disabledEmail && onClickEmail()}
        empty={disabledEmail}
      >
        <div>
          <ForgotPasswordMethodEmailIcon disabled={disabledEmail} />
        </div>
        <ContainerMethodTexts>
          <MethodTypeText disabled={disabledEmail}>
            Receber por e-mail
          </MethodTypeText>
          <MethodUserInfoText>{textEmail}</MethodUserInfoText>
        </ContainerMethodTexts>
      </MethodButton>
    </ContainerMethods>
  );
};

export { MethodSendCode };
